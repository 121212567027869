import React from 'react';
import { List } from 'office-ui-fabric-react/lib/components/List';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import  styles from './index.module.scss';
import { FocusZone, FocusZoneDirection, PrimaryButton } from 'office-ui-fabric-react';
import { runWithCancel } from '../utils';

type IVerificatorState = {
  hasVerificated: boolean;
  errors: any[];
  errorFormulas: any[];
  logs: string[];
  showLogs: boolean;
};

function getInitVerifactorState(): IVerificatorState {
  const errors: any[] = [];
  const errorFormulas: any[] = [];
  const logs: string[] = [];
  return {
    hasVerificated: false,
    errors,
    errorFormulas,
    logs,
    showLogs: false,
  };
}

const nrGlobal = 1048576;
const ncGlobal = 16384;

export type IErrorItem = {
  error: any;
  formula: any;
};

class Verificator extends React.Component<{}, IVerificatorState> {
  readonly state = getInitVerifactorState();

  appendLogs = (log: string) => {
    const logs: string[] = [];
    logs.push(...this.state.logs, log);
    this.setState({ logs });
  };

  clearLogs = () => {
    const logs: string[] = [];
    this.setState({ logs });
  };

  clearResult = () => {
    this.setState({ errors: [], errorFormulas: [] });
  };

  getSheetNames = () =>
    Excel.run(async function(ctx) {
      const sheetNames : any[] = [];
      var worksheets = ctx.workbook.worksheets;
      worksheets.load('name');
      await ctx.sync();
      for (var i = 0; i < worksheets.items.length; i++) {
        sheetNames.push(worksheets.items[i].name);
      }
      return sheetNames;
    });

  unprotectSheets = (sheetNames: string[]) => {
    return Excel.run(async ctx => {
      let sheetName: string;
      for (let i = 0; i < sheetNames.length; i++) {
        const sheet = ctx.workbook.worksheets.getItem(sheetNames[i]);
        sheetName = sheetNames[i];
        sheet.protection.unprotect();
      }
      const f = async () => {
        try {
          return await ctx.sync();
        } catch (error) {
          this.appendLogs(
            'You need to manually unprotect "' + sheetName + '" with a password to continue',
          );
          throw new Error('unprotectSheets');
        }
      };
      await f();
    });
  };

  prepareErrorFormulas = async (errors: any[]) => {
    return Excel.run(async ctx => {
      var ranges: any[] = [];
      var worksheets = ctx.workbook.worksheets;
      for (var i = 0; i < errors.length; i++) {
        ranges[i] = worksheets.getItem(errors[i].sh).getRange(errors[i].ad);
        ranges[i].load(['formulas', 'formulasR1C1', 'cellCount', 'rowCount', 'columnCount']);
      }
      await ctx.sync();
      const errorFormulas: any[] = [];
      for (var i = 0; i < errors.length; i++) {
        if (ranges[i].cellCount === 1) {
          errorFormulas[i] = ranges[i].formulas[0][0];
        } else if (ranges[i].rowCount > 1) {
          if (ranges[i].formulas[0][0] === ranges[i].formulas[1][0])
            errorFormulas[i] = ranges[i].formulas[0][0];
          else errorFormulas[i] = ranges[i].formulasR1C1[0][0];
        } else if (ranges[i].columnCount > 1) {
          if (ranges[i].formulas[0][0] === ranges[i].formulas[0][1])
            errorFormulas[i] = ranges[i].formulas[0][0];
          else errorFormulas[i] = ranges[i].formulasR1C1[0][0];
        }
      }
      return errorFormulas;
    });
  };

  prepareConcrete = async (sheetNames: string[]) => {
    return Excel.run(async ctx => {
      const worksheetsOCaml : any[] = [];
      const usedRangeAsOCaml : any[] = [];
      var worksheets = ctx.workbook.worksheets;
      var usedRangeAs : any[] = [];
      for (var i = 0; i < sheetNames.length; i++) {
        var sheet = worksheets.getItem(sheetNames[i]);
        var uR = sheet.getUsedRange();
        usedRangeAs[i] = uR.getBoundingRect('A1'); // larger usedRange
        usedRangeAs[i].load([
          'address',
          'rowIndex',
          'rowCount',
          'columnIndex',
          'columnCount',
          'values',
          'formulasR1C1',
          'formulas',
          'numberFormat',
          'valueTypes',
        ]);
      }
      await ctx.sync();
      for (var i = 0; i < sheetNames.length; i++) {
        worksheetsOCaml.push({ nameCode: sheetNames[i] });
        usedRangeAsOCaml.push({
          sheet: sheetNames[i],
          address: usedRangeAs[i].address,
          rowIndex: usedRangeAs[i].rowIndex,
          rowCount: usedRangeAs[i].rowCount,
          columnIndex: usedRangeAs[i].columnIndex,
          columnCount: usedRangeAs[i].columnCount,
          values: usedRangeAs[i].values,
          formulasR1C1: usedRangeAs[i].formulasR1C1,
          formulas: usedRangeAs[i].formulas,
          numberFormat: usedRangeAs[i].numberFormat,
          valueTypes: usedRangeAs[i].valueTypes,
        });
      }
      return { worksheetsOCaml, usedRangeAsOCaml };
    });
  };

  unhideSheets = (sheetNames: string[], nr: number, nc: number) => {
    return Excel.run(function(ctx) {
      for (let i = 0; i < sheetNames.length; i++) {
        const sheet = ctx.workbook.worksheets.getItem(sheetNames[i]);
        sheet.visibility = 'Visible';
        sheet.visibility = 'Visible';
        const r = sheet.getCell(nr - 2, nc - 2).getBoundingRect('A1');
        r.rowHidden = false;
        r.columnHidden = false;
      }
      return ctx.sync();
    });
  };

  get hasVerificated(): boolean {
    return this.state.hasVerificated;
  }

  set hasVerificated(v: boolean) {
    this.setState({ hasVerificated: v });
  }

  setShowLogs = (show: boolean) => {
    this.setState({ showLogs: show });
  };

  toggleShowLogs = () => {
    this.setState({ showLogs: !this.state.showLogs });
  };

  verify = (item: any) => {
    const { usedRangeAsOCaml } = item;
    const bef = performance.now();
    const errors = verifyOCaml(usedRangeAsOCaml);
    const af = performance.now();
    // this.appendLogs(" : " + round((af - bef) / 1000) + "seconds");
    return errors;
  };

  cancel: (() => void) | undefined = undefined;

  *doVerify() {
    this.setShowLogs(true);
    this.hasVerificated = false;
    this.clearLogs();
    this.clearResult();
    this.appendLogs('getting sheet names');
    const sheetNames = yield this.getSheetNames();
    this.appendLogs('unprotecting sheets');
    yield this.unprotectSheets(sheetNames);
    this.appendLogs('unhiding sheets');
    yield this.unhideSheets(sheetNames, nrGlobal, ncGlobal);
    this.appendLogs('preparing workbook state');
    const om = yield this.prepareConcrete(sheetNames);
    this.appendLogs('verifying');
    const errors = this.verify(om);
    this.appendLogs('preparing results');
    const errorFormulas = yield this.prepareErrorFormulas(errors);
    this.appendLogs('show results');
    this.setState({ errors, errorFormulas }, () => {
      this.setShowLogs(false);
      this.hasVerificated = true;
    });
    return true;
  }

  select(sheetName: string, rangeAddress: string) {
    Excel.run(function(ctx) {
      const range = ctx.workbook.worksheets.getItem(sheetName).getRange(rangeAddress);
      range.select();
      return ctx.sync();
    });
  }

  verifyWorkbook = () => {
    if (this.cancel) {
      this.cancel();
    }
    var promise = new OfficeExtension.Promise(function(resolve, reject) {
      resolve(null);
    });
    promise
      .then(() => {
        const { promise, cancel } = runWithCancel(this.doVerify.bind(this));
        this.cancel = cancel;
        return promise.catch(err => {
          if (err.reason === 'cancelled') {
            // tslint:disable-next-line: no-console
            console.log('canceled last verification');
          } else {
            throw err;
          }
        });
      })
      .catch(error => {
        if (error.message !== 'unprotectSheets') {
          this.appendLogs('Error: ' + error);
          if (error instanceof OfficeExtension.Error) {
            this.appendLogs('Debug info: ' + JSON.stringify(error.debugInfo));
          }
        }
      });
  };

  _onRenderCell = (item: IErrorItem | undefined, index: number | undefined): JSX.Element => {
    if (!item) {
      return <></>;
    }
    const onClick = () => this.select(item.error.sh, item.error.ad);
    return (
      <div className={styles.errorCell}>
        <span className={styles.errorTitle} onClick={onClick}>
          {item.error.sh + '!' + item.error.ad}
          <br />
        </span>
        <span>
          {item.formula}
          <br />
        </span>
        <span>{'Typing Error: '}</span>
        <span className={styles.errorMsg}>
          {item.error.mEasy}
          <br />
        </span>
        <br />
      </div>
    );
  };

  renderResult = (): JSX.Element => {
    const items: IErrorItem[] = this.state.errors.map((e, i) => {
      return { error: e, formula: this.state.errorFormulas[i] };
    });
    if (items.length > 0) {
      return (
        <div className={styles.result}>
          <span>Click on the range addresses to select:</span>
          <br />
          <br />
          <FocusZone direction={FocusZoneDirection.vertical}>
            <List items={items} onRenderCell={this._onRenderCell} />
          </FocusZone>
        </div>
      );
    } else {
      if (this.state.hasVerificated) {
        return (
          <div className={styles.result}>
            <span>The workbook does not have any typing error with regard to </span>
            {/* <a className={styles.a} onClick={this.onClickOpenRules} href="#"> */}
            <a className={styles.a} onClick={this.onClickOpenRules}>
              our typing rules
            </a>
          </div>
        );
      } else {
        return <div className={styles.result} />;
      }
    }
  };

  renderLogs = (): JSX.Element => {
    return (
      <>
        {this.state.logs.map((l, i) => (
          <Text block={true} key={'log_' + i}>
            {l}
          </Text>
        ))}
      </>
    );
  };

  onClickSupport = () => {
    window.open('https://www.matrixlead.com/verificator-1.0/pages/support.html');
  };

  onClickOpenRules = () => {
    // window.open('/help.html#rules');
    window.open("https://www.10studio.tech/docs/spreadsheetVerificator")
  };

  render() {
    const iconName = this.state.showLogs ? 'CaretSolidRight' : 'CaretSolidDown';
    return (
      <div className={styles.main}>
        <div className={styles.padding}>
          <br />
          <div className={styles.wrapping}>
            <PrimaryButton
              style={{ width: '240px' }}  
              onClick={this.verifyWorkbook}>
              Verify the Workbook by Types
            </PrimaryButton>
          </div>
          <br />
          {/* <p className={styles.support} onClick={this.onClickSupport}>Support</p> */}
          <hr className={styles.hr} />
          {this.renderResult()}
        </div>
        <div className={styles.footer}>
          <div className={styles.logIcon} onClick={this.toggleShowLogs}>
            <Icon iconName={iconName} />
          </div>
          {// tslint:disable-next-line: jsx-no-multiline-js
          this.state.showLogs && <div className={styles.logContent}>{this.renderLogs()}</div>}
        </div>
      </div>
    );
  }
}

export default Verificator;
