import React from 'react';

import { connect } from 'dva';
import { push } from 'react-router-redux';
import { Dispatch } from 'redux';

import { State as ReduxState } from '../../../store/reducer';
import selectors from '../../../selectors';
import { Plan, Subscription } from '../../../services/subscription';
import { StripeProvider, Elements } from 'react-stripe-elements';
import { Card } from '@uifabric/react-cards';
import {
  PrimaryButton,
  mergeStyleSets,
  getTheme,
  FontWeights,
  FontSizes,
  Spinner,
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  Stack,
  Text,
  ChoiceGroup,
  IChoiceGroupOption,
  Checkbox,
  IDetailsListCheckboxProps,
  CheckboxVisibility,
  ThemeSettingName,
} from 'office-ui-fabric-react';
import NoAuthLoginRedirect from '../../../components/NoAuthLoginRedirect';
import StripeCheckoutForm from '../StripeCheckoutForm';
import { ContentCard, MainText, TitleText } from '../common';

import {
  getPlanIndex,
  getPlanProductItems,
  getPlanDescription,
  getPlanProductName,
  getMoneyText,
  getPlanPrice,
  ProductItem,
  ALL_APPS_PRODUCT_NAME,
  SINGLE_APPS_PRODUCT_NAME,
  getAppProducts,
} from '../utils';
import _ from 'lodash';
import { AppName } from '../../../models/app';

enum SubscribeStep {
  Selection = 1,
  Pay = 2,
  Completed = 3,
}

export interface PlansSubscriptionProps {
  dispatch: Dispatch<any>;
  plans?: Plan[];
  subscription?: Subscription;
  email?: string;
  isSubscribing: boolean;
  isLoggedin: boolean;
  subscriptionError?: any;
  app?: AppName;
}

interface PlansSubscriptionState {
  step: SubscribeStep;
  selection: Selection;
  selectedKey?: string;
  selections: any[];
}

const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  header: [
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      color: theme.palette.neutralPrimary,
      display: 'flex',
      fontSize: FontSizes.xLarge,
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
    },
  ],
  body: {
    flex: '4 4 auto',
    overflowY: 'hidden',
    selectors: {
      p: {
        margin: '14px 0',
      },
      'p:first-child': {
        marginTop: 0,
      },
      'p:last-child': {
        marginBottom: 0,
      },
    },
  },
});

interface PlanItem {
  key: string;
  name: string;
  items: ProductItem[];
  description: string;
  price: string;
}

class PlansSubscription extends React.Component<PlansSubscriptionProps, PlansSubscriptionState> {
  private _hasMounted: boolean;
  _selection: Selection;
  constructor(props: PlansSubscriptionProps) {
    super(props);
    this.props.dispatch({ type: 'subscription/fetchPlans', payload: {} });
    this._hasMounted = false;
    this._selection = new Selection({
      canSelectItem: this._canSelectItem,
      onSelectionChanged: this._onSelectionChanged,

    });
    this.state = {
      step: SubscribeStep.Selection,
      selectedKey: SINGLE_APPS_PRODUCT_NAME,
      selection: this._selection,
      selections: []
    };




  }

  componentDidMount(): void {
    this._hasMounted = true;

  }


  private _onSelectionChanged = () => {

    if (this._hasMounted) {
      this.forceUpdate();
    }
  };

  private _canSelectItem = (): boolean => {
    const { selectedKey } = this.state;
    return selectedKey !== ALL_APPS_PRODUCT_NAME;
  };

  private _columns = [
    {
      key: 'column1',
      name: 'Name',
      fieldName: 'name',
      minWidth: 210,
      maxWidth: 210,
      onRender: (item: PlanItem) => {
        return (
          <Stack styles={{ root: { minHeight: 54 } }}>
            <Stack.Item>
              <Stack
                horizontal
                verticalAlign="center"
                horizontalAlign="space-between"
                tokens={{ childrenGap: 10 }}
              >
                {item.items.map(item => (
                  <Stack.Item key={item.name}>
                    <img src={item.iconName} />
                  </Stack.Item>
                ))}
                <Stack horizontalAlign="end">
                  <Text style={{ color: 'black' }}>{item.name}</Text>
                  <Text style={{ color: 'black' }}>{item.price}</Text>
                </Stack>
              </Stack>
            </Stack.Item>
            <Stack.Item>
              <Text block style={{ maxWidth: 230, whiteSpace: 'pre-wrap' }}>
                {item.description}
              </Text>
            </Stack.Item>
          </Stack>
        );
      },
    },
  ];


  filterPlans(plans: Plan[] = [], app: AppName) {
    // get app plans 
    return plans.filter(plan => getAppProducts(app).find(appPlan => appPlan == getPlanProductName(plan)));
  }
  getPlanListItems(plans: Plan[] = []): PlanItem[] {
    plans = plans.sort(
      (a, b) => getPlanIndex(getPlanProductName(a)) - getPlanIndex(getPlanProductName(b)),
    );

    // If no app has been provided use 10studio (for compatibility reason)
    plans = this.filterPlans(plans, this.props.app ? this.props.app : AppName.Studio);

    return plans.map(plan => {
      const product = getPlanProductName(plan);
      return {
        key: plan.id,
        name: product,
        items: getPlanProductItems(product),
        description: getPlanDescription(product),
        price: getPlanPrice(plan),
      };
    });
  }

  onToken = async (plans: Plan[], token: stripe.Token) => {
    this.props.dispatch({
      type: 'subscription/subscribe',
      payload: {
        plans,
        source: token.id,
      },
    });
    this._nextStep();
  };

  private _nextStep = () => {
    const step = this.state.step;
    this.setState({
      step: Math.min(step + 1, SubscribeStep.Completed),
    });
  };

  private _goStep = (step: SubscribeStep) => {
    this.setState({ step });
  };

  renderSubLastPlanItems = (subItems: any[]) => {
    const items = _.uniqBy(
      _.concat(
        [],
        ...subItems.map(item => getPlanProductItems(getPlanProductName(item.plan), true)),
      ),
      'name',
    );
    return (
      <Stack
        horizontal
        wrap
        horizontalAlign="baseline"
        tokens={{ childrenGap: 5 }}
        styles={{ root: { padding: 10, borderTop: '1px solid rgb(234, 234, 234)' } }}
      >
        {items.map(item => (
          <Stack.Item key={item.name}>
            <Stack horizontalAlign="center" styles={{ root: { width: 85 } }}>
              <div
                style={{ cursor: 'pointer' }}

              >
                <img src={item.iconName} />
                <MainText variant="tiny">{item.name}</MainText>
              </div>
            </Stack>
          </Stack.Item>
        ))}
      </Stack>
    );
  };

  onRenderCheckbox = (props?: IDetailsListCheckboxProps) => {
    if (!props) {
      return <></>;
    }
    return (
      <div style={{ pointerEvents: 'none' }}>
        <Checkbox checked={props.checked} />
      </div>
    );
  };

  getPlanOptions = (plans: PlanItem[]) => {
    const { selection, selectedKey } = this.state;
    const planAllApps = plans.filter(x => x.name === ALL_APPS_PRODUCT_NAME)[0];
    const planSingleApps = plans.filter(x => x.name !== ALL_APPS_PRODUCT_NAME);

    // if we have all apps option then select between single apps or all app 
    let options: IChoiceGroupOption[] = [
      {
        key: SINGLE_APPS_PRODUCT_NAME,
        text: SINGLE_APPS_PRODUCT_NAME,
        onRenderField: (props, render) => {
          return (
            <Stack styles={{ root: { width: 290, minHeight: 50 } }}>
              <Stack.Item>{render && render(props)}</Stack.Item>
              <Stack.Item>
                <DetailsList
                  items={planSingleApps}
                  columns={this._columns}
                  selection={selection}
                  selectionMode={SelectionMode.multiple}
                  selectionPreservedOnEmptyClick={true}
                  setKey={selectedKey}
                  layoutMode={DetailsListLayoutMode.justified}
                  checkboxVisibility={CheckboxVisibility.always}
                  onRenderCheckbox={this.onRenderCheckbox}
                  isHeaderVisible={false}
                />
              </Stack.Item>
            </Stack>
          );
        },
      },
    ];
    if (planAllApps) {
      options = [{
        key: planAllApps.name,
        text: planAllApps.name,
        onRenderField: (props, render) => {
          return (
            <Stack
              styles={{ root: { width: 290, minHeight: 50 } }}
              horizontal
              verticalAlign="center"
              horizontalAlign="space-between"
            >
              <Stack.Item>{render && render(props)}</Stack.Item>
              <Stack.Item>
                <Text>{planAllApps.price}</Text>
              </Stack.Item>
            </Stack>
          );
        },
      }, ...options];
    }


    return (
      <ChoiceGroup
        selectedKey={selectedKey}
        options={options}
        onChange={this._onChoiceGroupChange}
      ></ChoiceGroup>
    );



  };

  private _onChoiceGroupChange = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
    option?: IChoiceGroupOption | undefined,
  ) => {
    this.setState(
      {
        selectedKey: option && option.key,
      },
      () => {
        if (this._hasMounted) {
          this.forceUpdate();
        }
      },
    );
  };

  render() {
    const { plans, subscription, isSubscribing, subscriptionError, isLoggedin } = this.props;
    const { selection, step, selectedKey, selections } = this.state;
    if (!isLoggedin) {
      return (
        <ContentCard>
          <Card.Item>
            <Spinner />
          </Card.Item>
          <Card.Item>
            <NoAuthLoginRedirect />
          </Card.Item>
        </ContentCard>
      );
    }
    if (plans === undefined) {
      return (
        <ContentCard>
          <Card.Item>
            <Spinner />
          </Card.Item>
        </ContentCard>
      );
    }
    let selectedPlans: Plan[];
    if (selectedKey === ALL_APPS_PRODUCT_NAME) {
      selectedPlans = plans.filter(x => getPlanProductName(x) === ALL_APPS_PRODUCT_NAME);
    } else {
      const selectedKeys = selection.getSelection().map(x => x.key);
      selectedPlans = plans.filter(x => selectedKeys.some(y => x.id === y));
    }
    const items = this.getPlanListItems(plans);
    const totalAmount = selectedPlans.reduce((x, y) => x + y.amount, 0);
    const currency = selectedPlans.length ? selectedPlans[0].currency : '';
    if (isSubscribing) {
      return (
        <ContentCard>
          <Card.Item>
            <Spinner />
          </Card.Item>
        </ContentCard>
      );
    }
    switch (step) {
      case SubscribeStep.Selection:
        return (
          <ContentCard>
            <Card.Item>{this.getPlanOptions(items)}</Card.Item>

            {selectedPlans.length ? (
              <Card.Section>
                <PrimaryButton style={{ width: '100%' }} onClick={this._nextStep}>
                  {`Pay ${getMoneyText(totalAmount, currency)}`}
                </PrimaryButton>
              </Card.Section>
            ) : (
                <Card.Section>
                  <PrimaryButton style={{ width: '100%' }} disabled>
                    Select plans
                </PrimaryButton>
                </Card.Section>
              )}

          </ContentCard>

        );
      case SubscribeStep.Pay:
        if (!selectedPlans.length) {
          return <div>Go Back</div>; //TODO: error handle component
        }
        const confirmMessage = `Pay ${getMoneyText(totalAmount, currency)}`;

      
        return (
          <StripeProvider apiKey={STRIPE_API_KEY}>
            <ContentCard>
              <Card.Item styles={{ root: { margin: 'auto', paddingTop: 10, paddingBottom: 10 } }}>
                <Elements>

                  <StripeCheckoutForm
                    elements={null} 
                    stripe={null}
                    confirmMessage={confirmMessage}
                    handleToken={(token: any) => this.onToken(selectedPlans, token)}
                  />
                </Elements>
              </Card.Item>
            </ContentCard>
          </StripeProvider>
        );
      case SubscribeStep.Completed:
        if (!subscriptionError && subscription) {
          const lastSubscribedCreated = subscription.items.data.reduce(
            (x: any, y: any) => (x ? (x.created > y.created ? x.created : y.created) : y.created),
            undefined,
          );
          const lastSubscribedItems = subscription.items.data.filter(
            (x: any) => x.created === lastSubscribedCreated,
          );
          return (
            <ContentCard>
              <div className={contentStyles.header}>
                <span>Congratulation</span>
              </div>
              <Stack className={contentStyles.body} tokens={{ childrenGap: 20 }}>
                <div>
                  <TitleText>You just subscribed the following plan:</TitleText>
                  <div
                    style={{
                      paddingLeft: 10,
                      paddingTop: 10,
                      borderTop: '1px solid rgb(234, 234, 234)',
                    }}
                  >
                    {lastSubscribedItems.map((x: any) => (
                      <div>
                        <span style={{ marginLeft: 10, marginRight: 10 }}>•</span>
                        <span key={x.plan.id}>{getPlanProductName(x.plan)}</span>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <TitleText>Which includes the following features:</TitleText>
                  <div>{this.renderSubLastPlanItems(lastSubscribedItems)}</div>
                </div>
                <div>
                  <div style={{ paddingTop: 10, borderTop: '1px solid rgb(234, 234, 234)' }}>
                    <PrimaryButton
                      style={{ width: '100%' }}
                      onClick={() => {
                        this.props.dispatch(push('/my-subscription'));
                      }}
                    >
                      Go to My Subscription
                    </PrimaryButton>
                  </div>
                </div>
              </Stack>
            </ContentCard>
          );
        } else {
          return (
            <ContentCard>
              <div className={contentStyles.header}>
                <span>Error</span>
              </div>
              <div className={contentStyles.body}>
                {subscription ? (
                  <>
                    <p>Subscription failed: </p>
                    <p>{subscriptionError.message}</p>
                    <p>{subscriptionError.response.data.message}</p>
                  </>
                ) : (
                    <p>Subscription not Found</p>
                  )}
              </div>
              <PrimaryButton
                onClick={() => {
                  this._goStep(SubscribeStep.Selection);
                }}
              >
                Go Back
              </PrimaryButton>
            </ContentCard>
          );
        }
    }
  }
}

export default connect((state: ReduxState) => ({
  plans: selectors.subscription.getPlans(state),
  isLoggedin: selectors.auth.getIsLoggedIn(state),
  subscription: selectors.subscription.getSubscription(state),
  isSubscribing: selectors.subscription.getIsSubscribing(state),
  subscriptionError: selectors.subscription.getSubscribingError(state),
  email: selectors.auth.getUserEmail(state),
}))(PlansSubscription);
