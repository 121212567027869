import React from 'react';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { Form, Field, ErrorMessage, FormikProps } from 'formik';
import '../LoginForm/index.css';
import { login, verifyResetPassword } from '../../services/auth';
import { Redirect } from 'dva/router';

interface ResetValidationValues {
  email: string;
  code: string;
  password: string;
}

interface ResetValidationProps {
  email: string
}
class ResetValidation extends React.Component<FormikProps<ResetValidationValues> & ResetValidationProps, {}> {

  render() {
    const { status, ...formik } = this.props;

    return <div className="container">
      <div className="login-form reset-form">
        <h3 className="border-label"> Reset Password </h3>
        <div className="row">
          <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12">
            <div className="login-form-wrapper sign-form-wrapper">
              <div className="formContainer">
                <h5 className="description">
                  Please check your email address and use the code we sent you to reset your password.
                </h5>
                <Form className="form">
                  <Field className="input" type="text" name="email" disabled={true} value={this.props.email}  placeholder="you@example.com" />
                  <Field className="input" autoComplete="off" style={{ width: "100%" }} type="password" name="password" placeholder="Your new password" />                
                  <Field
                    className="input"
                    type="text"
                    name="code"
                    placeholder="Verification Code"
                    style={{ width: "100%" }}
                  />
                  <button className="button" type="submit">
                    Submit
                  </button>
                </Form>
              </div>
              {status && status.redirect && <Redirect to='/' push />

              }
              <div className="messages-wrapper">
                {formik.errors.email && <div className="alert alert-danger email-error">
                  <ErrorMessage name="email" />
                </div>
                }
                {formik.errors.code &&

                  <div className="alert alert-danger code-error">
                    <ErrorMessage name="code" />
                  </div>
                }
                {formik.errors.password && formik.touched.password &&

                  <div className="alert alert-danger password-error">
                    <ErrorMessage name="password" />
                  </div>
                }

                {status && status.msg &&
                  <div className="alert alert-success msg-status">{status.msg}</div>
                }


                {status && status.error &&
                  <div className="alert alert-danger error-status">{status.error}</div>
                }

              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  }


}


const ResetFormValidation = yup.object().shape({
  email: yup
    .string()
    .email()
    .required(),
  password: yup
    .string()
    .min(6)
    .max(16)
    .required(),
  code: yup
    .string()
    .required()

});

const ResetValidationForm = withFormik<ResetValidationProps, ResetValidationValues>({
  // Handles our submission
  handleSubmit: async (values, { setSubmitting, setStatus, props }) => {
    // This is where you could send the submitted values to the backend
    try {
      setSubmitting(true);
      const res = await verifyResetPassword(values);
      setStatus({ reset: true, msg: "Password has been reset successfully" });
      setTimeout(function () {
        setStatus({ redirect: true });
      }, 5000);

    } catch (e) {
      // tslint:disable-next-line: no-console
      if (e.response.data.message && e.response.status == 200) {
        setStatus({ msg: e.response.data.message });
      } else if (e.response.data.message) {
        setStatus({ error: e.response.data.message });
      } else {
        setStatus(JSON.stringify(e.response.data));
      }
    }
  },
  validationSchema: ResetFormValidation,
})(ResetValidation);

export default ResetValidationForm;
