import * as auth from './auth';
import * as header from './header';
import * as subscription from './subscription';
import * as app from './app';
export { auth };
export { header };
export { subscription };

export default {
  auth,
  header,
  subscription,
  app,
};
