import React from 'react';
import { withTheme } from 'styled-components';

import merge from 'lodash/merge';
import omit from 'lodash/omit';

import { Customizer } from 'office-ui-fabric-react/lib/Utilities';
import { CommandBar, ICommandBarProps } from 'office-ui-fabric-react/lib/CommandBar';

import { getCommandBarFabricTheme } from '../../theme';
import { getPlatform, PlatformType } from '../../platform';
import { ITheme } from 'office-ui-fabric-react';
import "./index.scss"; 
export interface IProps extends ICommandBarProps { }

interface IPrivateProps extends IProps {
  theme: ITheme | any; // from withTheme
}

const Header = (props: IPrivateProps) => {
  const items = props.items
    .filter(({ hidden }) => !hidden)
    .map(item => ({ ...item, 'data-testid': item.key }));
  const farItems = props.farItems ? props.farItems.filter(({ hidden }) => !hidden) : [];

  return (
    <Customizer settings={{ theme: getCommandBarFabricTheme(props.theme) }}>
      <CommandBar
        {...merge(omit({ ...props, items, farItems }, ['theme']), {
          styles: {
            root: {
              paddingLeft: 0,
              paddingRight: {
                // [PlatformType.PC]: '20px',
                // [PlatformType.Mac]: '40px',
                // [PlatformType.OfficeOnline]: '0px',
                // [PlatformType.iOS]: '20px',
                // [PlatformType.Android]: '20px',
                // [PlatformType.Universal]: '20px',
                [PlatformType.PC]: '0px',
                [PlatformType.Mac]: '0px',
                [PlatformType.OfficeOnline]: '0px',
                [PlatformType.iOS]: '0px',
                [PlatformType.Android]: '0px',
                [PlatformType.Universal]: '0px',
              }[getPlatform()],
            },
          },
        })}
      />
    </Customizer>
  );
};

export default withTheme(Header);
