import { AbstractSpreadsheetCommunicator } from "./abstract-spreadsheet-communicator";
declare var google: any;

export class GoogleSpreadsheetCommunicators extends AbstractSpreadsheetCommunicator {
    currentValue = "";
    isPooling = false; // used for google sheet on selection pool  
    intervalHandler = 0;

    read_formula(formulaStyle: string) {
        console.log("@reading value"); 
        return Promise.resolve(this.currentValue);
    }

    read_numberDecimalSeparator () {
        return Promise.resolve("None")
    }

    read_displayLanguage() {
        return Promise.resolve("None")
    }

    read_contentLanguage() {
        return Promise.resolve("None")
    }

    write_formula(value: string, formulaStyle: string) {
        return new Promise((res, rej) => {
            google.script.run.withSuccessHandler(res).withFailureHandler(rej).setCurrentCell(value);
        });
    }

    addOnSelectionChangeHandler(handler: any) {
        this.intervalHandler = setInterval(async () => {
            if (this.isPooling) return;
            try {
                this.isPooling = true;
                let range = await this.getSelectionGoogle();
                if (range && (range.column != this.lastSelection.column || range.row != this.lastSelection.row)) {
                    this.lastSelection = range;
                    this.currentValue = range.value;
                    console.log("@raaaaaange " , range); 
                    handler();
                }
            } catch (ex) {

            } finally {
                this.isPooling = false;
            }
        }, 100);
    }


    /**
     * 
     * @returns currently selected range 
     */

    private getSelectionGoogle(): Promise<any> {

        return new Promise((res, rej) => {

            google.script.run
                .withSuccessHandler(res)
                .withFailureHandler(rej)
                .getCurrentSelection();
        });
    }

}