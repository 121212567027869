import React from 'react';
import { StatelessComponent } from 'react';
import { Card } from '@uifabric/react-cards';
import { Text, ITextProps } from 'office-ui-fabric-react';

const cardStyle = {
  paddingLeft: 10,
  paddingRight: 10,
  paddingTop: 10,
  paddingBottom: 10,
  minWidth: 310,
};

export const ContentCard: StatelessComponent = props => {
  return (
    <Card style={cardStyle} tokens={{ childrenGap: 8 }}>
      {props.children}
    </Card>
  );
};

export const MainText: StatelessComponent<ITextProps> = props => {
  const { children, ...extraProps } = props;
  return (
    <Text
      block
      variant="medium"
      style={{ whiteSpace: 'pre-wrap', ...extraProps.style }}
      {...extraProps}
    >
      {children}
    </Text>
  );
};

export const TitleText: StatelessComponent<ITextProps> = props => {
  const { children, ...extraProps } = props;

  return (
    <Text
      block
      variant="large"
      style={{ whiteSpace: 'pre-wrap', ...extraProps.style }}
      {...extraProps}
    >
      {children}
    </Text>
  );
};
