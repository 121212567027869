import { AppName } from '../models/app';


const appSubscripePath: any = {
    [AppName.PrettyFormula]: 'subscribe-pretty-formula',
    [AppName.Studio]: 'subscribe'
}
export function getAppSubscribePage(app: string) {

    return appSubscripePath[app] || 'subscribe'
}