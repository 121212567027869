import { Model } from 'dva';
import { DvaModelBuilder, actionCreatorFactory, removeActionNamespace } from 'dva-model-creator';
import queryString from 'query-string';
export interface State {

  loaded: boolean;
  error?: Error;
  name?: AppName;
  pathname?: string;

}
export enum AppName {
  PrettyFormula = 'pretty-formula',
  Studio = '10studio',
  FormulaEditor = 'formula-editor', 
  GoogleSheetFormulaEditor = 'google-sheet-formula-editor',   
}

//namespace
const namespace = 'app';
const actionCreator = actionCreatorFactory(undefined);

//reducers
const saveApp = actionCreator<string>('saveApp');
const savePathname = actionCreator<String>('savePathname');
export const updateApp = actionCreator<any>('updateApp');
//effects

const model = new DvaModelBuilder<State>(
  {
    loaded: false
  },
  namespace
)
  .immer(saveApp, (state, payload) => {
    return { ...state, name: payload, loaded: true };
  })
  .immer(savePathname, (state, payload) => {
    return { ...state, pathname: payload }
  })
  .takeEvery(updateApp, function* ( history , { call, put, select  }) {
    let search = history.location.search ; 
    const query = queryString.parse(search);
    let app = query.app ? query.app as string : '';
    if (app) {
      localStorage.setItem('app', app);
    } else {
      app = localStorage.getItem('app') ? localStorage.getItem('app') as string : '';
    }
    yield put.resolve(saveApp(app));

  })
  .subscript(({ dispatch, history }, done) => {
    return history.listen(function (historyState) {
      // set app name in the sate 
      dispatch(removeActionNamespace(updateApp(history)));
      dispatch(removeActionNamespace(savePathname(historyState.pathname)));
      
    });
  })
  .build();


//tslint:disable-next-line:no-default-export
export default model as Model;
