import React from 'react';
import Slider, { SliderProps, Mark } from '@material-ui/core/Slider';
import { isUndefined } from 'util';

export interface DiscreteSliderProps extends SliderProps {
    values?: number[],
}

class DiscreteSlider extends React.PureComponent<DiscreteSliderProps> {
    constructor(props: DiscreteSliderProps) {
        super(props);
    }

    render() {
        const { values, ...props } = this.props;
        let finalProps: SliderProps;

        if (values) {
            let newProps: Partial<SliderProps> = {
                step: 1,
                min: 0,
                max: values.length - 1,
            }

            if (!isUndefined(props.marks)) {
                if (typeof props.marks === "boolean") {
                    newProps.marks = props.marks
                } else {
                    newProps.marks = props.marks.map(
                        markup => {
                            let { value, label } = markup;
                            const i = values.indexOf(value);
                            if (i >= 0)
                                return { value: i, label }
                            else {
                                return undefined
                            }
                        }
                    ).filter(v => !isUndefined(v)) as Mark[]
                }
            }


            if (!isUndefined(props.max)) {
                let i = values.indexOf(props.max);
                if (i >= 0) {
                    newProps.max = i
                }
            };

            if (!isUndefined(props.min)) {
                let i = values.indexOf(props.min);
                if (i >= 0) {
                    newProps.min = i
                }
            };

            if (!isUndefined(props.defaultValue) && typeof props.defaultValue === "number") {
                let i = values.indexOf(props.defaultValue)
                if (i >= 0) {
                    newProps.defaultValue = i;
                }
            };

            if (!isUndefined(props.value) && typeof props.value === "number") {
                let i = values.indexOf(props.value)
                if (i >= 0) {
                    newProps.value = i;
                }
            };

            const getAriaValueText = props.getAriaValueText;
            if (!isUndefined(getAriaValueText)) {
                newProps.getAriaValueText = (i, index) => {
                    return getAriaValueText(values[i], index);
                }
            };

            const onChange = props.onChange;
            if (!isUndefined(onChange)) {
                // @ts-ignore
                newProps.onChange = (_ev: any, i: any) => {
                    if (typeof i === "number") {
                        onChange(_ev, values[i]);
                    }
                }
            };

            const valueLabelFormat = props.valueLabelFormat
            newProps.valueLabelFormat = (i: number, index: number) => {
                if (isUndefined(valueLabelFormat)) {
                    return values[i].toString();
                } if (typeof valueLabelFormat === "string") {
                    return valueLabelFormat;
                } else {
                    return valueLabelFormat(values[i], index);
                }
            }

            finalProps = Object.assign(props, newProps) as SliderProps;
        } else {
            finalProps = props;
        };

        return (
            <Slider {...finalProps} />
        )
    }
}

export default DiscreteSlider;
