import * as authService from '../services/auth';
import * as kpiServices from '../services/kpi';
import { LOCATION_CHANGE } from 'react-router-redux';
import queryString from 'query-string';
export interface User {
  email: string;
  exp: number;
  iat: number;
  facebookID: string;
  githubID: string;
  googleID: string;
  linkedinID: string;
  microsoftID: string;
  twitterID: string;
  name: string;
  type: string;
  _id: string;
}

export interface State {
  user?: User;
  IsLoggingInOrOut: boolean;
  loginErrorMsg?: string;
  registerErrorMsg?: string;
  next?: string;
  hasKpiSent: boolean;
  preferences?: Object;
}

//tslint:disable-next-line:no-default-export
export default {
  namespace: 'auth',
  state: {
    user: undefined,
    IsLoggingInOrOut: false,
    hasKpiSent: false,
  },
  reducers: {
    //tslint:disable-next-line:no-any
    setIsLoggingInOrOut(state: State, { payload: { IsLoggingInOrOut } }: any) {
      return { ...state, IsLoggingInOrOut };
    },
    //tslint:disable-next-line:no-any
    save(state: State, { payload: { user, loginErrorMsg, registerErrorMsg } }: any) {
      return { ...state, user, loginErrorMsg, registerErrorMsg };
    },
    setPreferences(state: State, { payload: { preferences } }) {
      return { ...state, preferences };
    },
    //tslint:disable-next-line:no-any
    update(state: State, { payload: { user, loginErrorMsg, registerErrorMsg } }: any) {
      return {
        ...state,
        user: user || state.user,
        loginErrorMsg: loginErrorMsg || state.loginErrorMsg,
        registerErrorMsg: registerErrorMsg || state.registerErrorMsg,
      };
    },
    //tslint:disable-next-line:no-any
    kpi(state: State, { }) {
      return { ...state, hasKpiSent: true };
    },
    //tslint:disable-next-line:no-any
    redirect(state: State, { payload: { next } }: any) {
      return { ...state, next };
    },
    clearError(state: State, { }) {
      return { ...state, loginErrorMsg: undefined, registerErrorMsg: undefined };
    },
  },
  effects: {
    //tslint:disable-next-line:no-any
    *fetch({ }, { call, put, take, select, all }: any) {
      yield put({
        type: 'setIsLoggingInOrOut',
        payload: { IsLoggingInOrOut: false },
      });
      try {
        const routing = yield select(({ routing }: any) => routing); //tslint:disable-line:no-any
        let user;
        if (routing === undefined) {
          const [u, _] = yield all([call(authService.fetch), take(LOCATION_CHANGE)]);
          user = u;
        } else {
          user = yield call(authService.fetch);
        }



        yield put({
          type: 'save',
          payload: { user },
        });
        if (user.type == "anonym") {
          yield put({
            type: 'setPreferences',
            payload: { preferences: { } },
          });
        } else {

          let preferences = yield call(authService.getUserPreferences);
          yield put({
            type: 'setPreferences',
            payload: { preferences },
          });
        }



        const url = yield select(
          ({ routing: { location } }: any) => location.pathname + location.search + location.hash, //tslint:disable-line:no-any
        );
        const hasKpiSent = yield select(({ auth: { hasKpiSent } }: any) => hasKpiSent); //tslint:disable-line:no-any
        if (!hasKpiSent) {
          yield put({ type: 'kpi' });
          const app = authService.getFirstApp();
          kpiServices.post({ uid: user._id, app, url, action: { theType: 'VisitPage' } });
        }
        yield put({ type: 'clearError' });
      } catch {

      } finally {
        yield put({
          type: 'setIsLoggingInOrOut',
          payload: { IsLoggingInOrOut: false },
        });

        let preferences = yield select(({ auth: { preferences } }: any) => preferences)
        if (typeof preferences == "undefined")
          yield put({
            type: 'setPreferences',
            payload: { preferences: { } },
          });


      }
    },
    *login(
      { payload: { email, password } }: { payload: authService.LoginPayload },
      { call, put }: any, //tslint:disable-line:no-any
    ) {
      yield put({
        type: 'setIsLoggingInOrOut',
        payload: { IsLoggingInOrOut: true },
      });
      try {
        const user = yield call(authService.login, { email, password });
        yield put({
          type: 'save',
          payload: { user, loginErrorMsg: undefined },
        });
      } catch (e) {
        let msg: string;
        // tslint:disable-next-line: no-console
        console.log('login Error: ', e.response.data);
        if (e.response.data.message) {
          msg = e.response.data.message;
        } else {
          msg = JSON.stringify(e.response.data);
        }
        yield put({
          type: 'update',
          payload: {
            loginErrorMsg: msg,
          },
        });
      }
      yield put({
        type: 'setIsLoggingInOrOut',
        payload: { IsLoggingInOrOut: false },
      });
    },
    //tslint:disable-next-line:no-any
    *logout({ }, { call, put }: any) {
      yield put({
        type: 'setIsLoggingInOrOut',
        payload: { IsLoggingInOrOut: true },
      });
      try {
        const user = yield call(authService.logout);
        yield put({
          type: 'save',
          payload: { user },
        });
        yield put({ type: 'subscription/clearSubscriptions' });
        yield put({ type: 'clearError' });
      } finally {
        yield put({
          type: 'setIsLoggingInOrOut',
          payload: { IsLoggingInOrOut: false },
        });
      }
    },
    *loginSSO({ }, { call, put }: any) {
      const user = yield call(authService.loginSSO);
      yield put({
        type: 'save',
        payload: { user },
      });
      yield put({ type: 'clearError' });
    },
    //tslint:disable-next-line:no-any
    *loginOauth({ payload }: { payload: authService.LoginOauthPayload }, { call, put }: any) {
      const user = yield call(authService.loginOauth, payload);
      yield put({
        type: 'save',
        payload: { user },
      });
      yield put({ type: 'clearError' });
    },
  },
  subscriptions: {
    //tslint:disable-next-line:no-any
    setup({ dispatch, history }: any) {
      //tslint:disable-next-line:no-any
      return history.listen(({ pathname, search }: any) => {
        console.log("Auth setup");
        const query = queryString.parse(search);

        let next: string | undefined | null;
        if (query) {
          if (query.next instanceof Array) {
            next = query.next[0];
          } else {
            next = query.next;
          }
        }
        if (pathname === '/sign') {
          dispatch({ type: 'redirect', payload: { next } });
        } else {
          dispatch({ type: 'redirect', payload: { next: undefined } });
        }
        dispatch({ type: 'fetch' });
      });
    },
  },
};
