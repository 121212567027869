import { push } from 'react-router-redux';

import { ICommandBarItemProps } from 'office-ui-fabric-react';

import { State } from '../store/reducer';
import { DEFAULT_PATH } from '../constants';
import { getIsLoggedIn, getNext, getUserName, getisLoggingInOrOut } from './auth';

export interface IHeaderItem extends ICommandBarItemProps {
  actionCreator?: () => { type: string; payload?: any };
}

export const getItems = (state: State): IHeaderItem[] => {
  return [];
};

export const getNextPath = (state: State): string => {
  let path: string = DEFAULT_PATH;
  if (state.routing.location) {
    path = state.routing.location.pathname;
    if (path === '/sign') {
      path = getNext(state);
    }
  }
  return path;
};

export const getFarItems = (state: State): IHeaderItem[] => {
  const isLoggedIn: boolean = getIsLoggedIn(state);
  const isLoggingInOrOut: boolean = getisLoggingInOrOut(state);
  const path = getNextPath(state);
  const logout = () => ({
    type: 'auth/logout',
  });
  const login = () => {
    return push(`/sign?next=${path}`);
  };
  const toMySubscription = () => {
    return push(`/my-subscription`);
  };
  const username = getUserName(state);
  return [
    {
      key: 'account',
      text: isLoggedIn ? username : 'Sign In',
      ariaLabel: isLoggedIn ? 'Sign Out' : 'Sign In',
      // buttonStyles: {
      //     flexContainer: {
      //         flexDirection: isLoggedIn ? 'row' : 'row-reverse',
      //     }
      // },
      subMenuProps: isLoggedIn
        ? {
            items: [
              {
                key: 'sysubscription',
                text: 'My Subscription',
                actionCreator: toMySubscription,
              },
              {
                key: 'signout',
                text: 'Sign Out',
                actionCreator: logout,
              },
            ],
          }
        : undefined,
      iconOnly: false,
      actionCreator: isLoggingInOrOut ? undefined : !isLoggedIn ? login : undefined,
    },
  ];
};
