import axios from 'axios';
import { saveSignToken } from './auth-utils';
import { fetch } from './auth';
declare var OfficeRuntime;

async function verifyToken(token) {
    let response = await axios.get(`${AUTH_BACKEND_URL}/auth/sso`, {
        params : {
            auth: token
        }
    });
    return response;
}
export async function loginSSO() {

    let bootstrapToken = await OfficeRuntime.auth.getAccessToken({ allowSignInPrompt: true, allowConsentPrompt: true, forMSGraphAccess: true });
    let userToken = (await verifyToken(bootstrapToken)).data.token;

    await saveSignToken(userToken);
    return fetch();

}
