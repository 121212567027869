import { getSignTokenLocal, payloadFromToken } from './auth-utils';
import { getOfficeHost, OfficeHost } from '../utils';
import { User } from '../models/auth';
export type LoginFrom = 'addin' | 'web';

export interface LoginOauthPayload {
  social: string;
}

export async function loginOauth({ social }: LoginOauthPayload): Promise<User | undefined> {
  if (getOfficeHost() === OfficeHost.Excel) {
    // @ts-ignore
    return new Office.Promise<User>(
      (
        resolve: (value?: User | PromiseLike<User> | undefined) => void,
        reject: (reason?: any) => void, //tslint:disable-line:no-any
      ) => {
        Office.context.ui.displayDialogAsync(
          // `${AUTH_BACKEND_URL}/auth/${social}`,
          `${AUTH_BACKEND_URL}/10studio/auth/${social}`,
          { height: 90, width: 60 },
          asyncResult => {
            const dialog = asyncResult.value;
            dialog.addEventHandler(
              Office.EventType.DialogMessageReceived,
              //tslint:disable-next-line:no-any
              async (arg: any) => {
                console.log('dialog received: ' + arg.message);
                if (arg.message === 'getUser') {
                  const token = await getSignTokenLocal();
                  dialog.close();
                  resolve(payloadFromToken(token));
                }
              },
            );
          },
        );
      },
    );
  } else {
    // @ts-ignore
    return new Office.Promise<User>(
      (
        resolve: (value?: User | PromiseLike<User> | undefined) => void,
        reject: (reason?: any) => void, //tslint:disable-line:no-any
      ) => {
        const x = window.open(`${AUTH_BACKEND_URL}/auth/${social}`); // should not have httpOnly if it really appears in the address bar of a browser
        window.addEventListener(
          'message',
          async event => {
            if (event.origin === window.origin) {
              if (event.data === 'getUser') {
                const token = await getSignTokenLocal();
                if (x) {
                  x.close();
                }
                resolve(payloadFromToken(token));
              }
            }
          },
          false,
        );
      },
    );
  }
}