import * as React from 'react';
import * as ReactDom from 'react-dom';
import * as Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import axios from 'axios';
import * as kpiService from '../../services/kpi';
import { HotTable } from '@handsontable/react';

const chartOptions = {
    chartType: 'stock', chart: { type: 'line' }, rangeSelector: { enabled: false }, navigator: { enabled: false },
    yAxis: { visible: false }, legend: { enabled: true }, scrollbar: { enabled: false  },
    xAxis: { type: 'datetime', ordinal: false },
    plotOptions: {
        line: { marker: { enabled: true } },
        series: { 
            dataLabels: { enabled: true, allowOverlap: true }
        }
    },
    time: { useUTC: true }
}

const s = new Date(Date.UTC(2021, 2, 17)); // better to start from a Monday
const e = new Date(Date.UTC(2021, 3, 22));
const dateValues = kpiService.enumerateBetweenDates(s, e, "hour", 0);

const aux = function (s) {
    if (s === "10studio") 
        return '10Studio'
    else 
        return s
}

class Kpi extends React.Component {
    state = {
        logs: [],
        posts: [],
        users: [],
        subscribers: [],
        chartOptionsReturn: {},
        dayUserBehaviors: {},
        dayWatched: new Date(Date.UTC(2021, 2, 18)),
      };

    /*
    how many returning users：
        需要查上面每一个user之前的logs：O(number of logs * number of today's user)
        对于每一个人，他们的个人信息，之前他们都哪天来的，每天多少requests of useful features

    how many paying customers? who are they? how much they pay every month? Are they happy? 
        Will they impact their colleagues/team/firm/同行? 
        Will their team/firm sign a bigger contract (e.g., custom features, priority support)?

    how many people are possible/potential paying customers, that I could try to email/contact/convince/remind
        - people who open the payment link, but stopped
        - people who have already reached the threshold today (this week)
        - people who are close to the threshold
        - people who return (for any behavior)
        - people who visit a lot in one day

    how many times features are used / how many requests of useful features 
        - (DONE) formatting (total successful, fail, ratio的变化), 
        - 需要有一个点击课程的统计！click on that button to open course
        - write to cell 代表真正参与编辑spreadsheet了
        - optimization hinting？他们真正看了，真正点了，真正替换了
    
    how many different people had logs:
        这个代表了 marketing effect

    系统稳定指标，是否各个功能，各种环境都好使？尤其在修改production之后
        - 不同version的不同log，+数量，不用看人。从某天开始到现在
        - 如何能get到user环境的version？

    如何能get到user所用的版本，Excel版本，browser版本
    Stripe的收入？？？给个链接也行啊
    - 把我们自己人去掉：ip, login；严格控制；把相关procedure移到kpiService中
    - 都用变化的值，而不是cumulative，因为之后就是一个参数的事儿。cumulative是一个option。
    should have a function in frontend to get logs/users for a period
    */

    getApps () {
        let x = [];
        for (let i = 0; i < this.state.logs.length; i++) {
            let log = this.state.logs[i];
            if (!x.includes(log['app'])) {
                x.push(log['app'])
            }
        }
        return x
    }

    getTheTypes() {
        let x = [];
        for (let i = 0; i < this.state.logs.length; i++) {
            let log = this.state.logs[i];
            if (!x.includes(log['action']['theType'])) {
                x.push(log['action']['theType'])
            }
        }
        return x
    }

    ////////////////////////////////////////////////// chart returning users //////////////////////////////////////////////////

    // 我现在想计算的是：
    // given a day d, among the users who use the system, who had 0 session, 1 session, 2 sessions before the end of that day
    // given a week w, among the users who use the system, who had 0 session, 1 session, 2 sessions before the end of that week
    // 对于一个时间点，那里的returning users到底是谁？
    // 这里面每一个人的行为：到底return多少次；logs多少个；用了多少次formatting
    // Our goal is that the these numbers trow
    // 然后我可以联系TA，毕竟是TA刚做完动作，有印象
    // 把意义记在网页上，一目了然。
    setChartOptionsReturn() {
        let logs = this.state.logs
        let users = this.state.users
        let appearGTE = 3
        let res = kpiService.enumerateBetweenDates(s, e, "day", 0);
        let dayUsers = kpiService.enumerateBetweenDates(s, e, "day", "NEWSET");
        let dayUserBehaviors = kpiService.enumerateBetweenDates(s, e, "day", "EMPTYARRAY")

        logs.forEach(function (log: any) {
            let date = new Date(log['date'])
            let day = (new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()))).getTime(); 
            dayUsers[day].add(log.userId) // user who arrives at Monday 5am45 will be counted for Monday
        })
        let userBehaviors = kpiService.getUserBehaviors(logs, this.state.users);
        for (let day in res) {
            let usersSet = dayUsers[day];
            usersSet.forEach(function (userId) {
                let behavior = userBehaviors[userId];
                let i = behavior.sessions.length - 1;
                while (behavior.sessions[i].start.getTime() >= parseInt(day) + 24 * 60 * 60000) {
                    i = i - 1;
                }
                // then this Id appears (i + 1) times
                if (i + 1 >= appearGTE) {
                    res[day]++;
                    dayUserBehaviors[day].push({userId: userId, behavior: behavior})
                }
            })
        }

        let chartOptionsReturn = JSON.parse(JSON.stringify(chartOptions));
        chartOptionsReturn.series = [
            { data: kpiService.arrayFromObject(res), name: appearGTE + ' times',
                cursor: 'pointer', point: { events: { click: function (event) { 
                    dayUserBehaviors[event.point.x].forEach(function (userBehaviors) {
                        console.log("A USER:")
                        console.log(kpiService.displayNameFromId(userBehaviors.userId, users));
                        console.log(kpiService.userFromId(userBehaviors.userId, users))
                        console.log(userBehaviors)
                    })
                }}}}
        ]
        this.setState({chartOptionsReturn: chartOptionsReturn})
        this.setState({dayUserBehaviors: dayUserBehaviors})
        console.log("dayUsers:")
        console.log(dayUsers)
        console.log("dayUserBehaviors:")
        console.log(dayUserBehaviors)
    }

    ////////// to delete
    ////////////////////////////////////////////////// chart 5 returning users //////////////////////////////////////////////////
    getChartOptions5() {
        let logs = this.state.logs

        let numReturning1 = Object.assign({}, dateValues) // 从现在到4周前一共有多少人出现2-10次
        let numReturning2 = Object.assign({}, dateValues) // 从现在到4周前一共有多少人出现11次以上


        // wrong: 1） checks all the users in the period, it is too costly. Checking users of recent days will be enough
        let usersSet = kpiService.usersFromLogs(s, e, logs, "Funfun") 
        let usersTable = []
        usersSet.forEach(function (userId, value2, set) {
            // 2） in behaviorOfUsers, for each user, it goes through all the logs.
            // actually, we could just go through the logs for one time, and fill in behavior for all the users that interest us
            // having a behavior variable is OK.
            // 也可以包含TA的所有logs，再加上一个总结。
            let behavior = kpiService.behaviorOfUser(userId, s, e, logs) as any
            for (let end in numReturning1) {
                if (numReturning1.hasOwnProperty(end) && !isNaN(numReturning1[end])) {
                    // 4）I don't think the period of 4 weeks is good here. There are users who may return 2 months later, who are interesting as well.
                    let start = (end as any) - 2419200000 // 4 weeks
                    let numAppearance = 0;
                    // 3) there is a notion of sessions, 间隔大于30分钟就又是一个session; 
                    // it is not too bad. But the number of actions inside a session is important as well
                    for (let i = 0; i < behavior.sessions.length; i++)
                        if (behavior.sessions[i].start <= end && behavior.sessions[i].end >= start) 
                            numAppearance++
                    if (numAppearance >= 2 && numAppearance <= 10) numReturning1[end]++
                    if (numAppearance >= 11) numReturning2[end]++
                }
            }
        })

        let chartOptions5 = JSON.parse(JSON.stringify(chartOptions));
        chartOptions5.plotOptions.series.dataGrouping = { approximation: "close", forced: true, units: [['day', [1]]] };
        chartOptions5.series = [
            { data: kpiService.arrayFromObject(numReturning1), name: "1-9"},
            { data: kpiService.arrayFromObject(numReturning2), name: "10-"}
        ]
        return chartOptions5
    }

    getUsersTable () {
        let logs = this.state.logs
        let users = this.state.users as any
        let usersSet = kpiService.usersFromLogs(s, e, logs, "Funfun")    
        var usersTable = [] as any
   
        function userFromId (id) {
            for (var i = 0; i < users.length; i++)
                if (id === users[i]._id)
                    return users[i];
            return null
        }
        
        usersSet.forEach(function (userId, value2, set) {
            let behavior = kpiService.behaviorOfUser(userId, s, e, logs) as any;
            let user = userFromId(userId);
            let type; if (user!== null && user.local != null && user.local.type === "anonym") type = "anonym"; else type = "normal" 
            usersTable.push([
                behavior.nameHtml, 
                type, behavior.actions.length, behavior.sessions.length, behavior.postsHtml])
        })    
        return usersTable
    }

    ////////////////////////////////////////////////// chartA //////////////////////////////////////////////////

    getChartOptionsA() {
        let x = [] as any;
        for (let i = 0; i < this.state.logs.length; i++) {
            let log = this.state.logs[i];
            let appTheType = aux(log['app']) + "." + log['action']['theType']
            if (!x.hasOwnProperty(appTheType)) {
                x[appTheType] = Object.assign({}, dateValues)
            }
        };

        for (let i = 0; i < this.state.logs.length; i++) 
            if (!kpiService.isLogUs(this.state.logs[i])) {
                let log = this.state.logs[i];
                let date = new Date(log['date'])
                let hour = (new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours()+1))).getTime();
                let appTheType = aux(log['app']) + "." + log['action']['theType'];
                x[appTheType][hour]++
            }

        var res = [] as any;
        for (var appTheType in x) {
            res.push({name: appTheType, data: kpiService.arrayFromObject(x[appTheType])})
        }

        let chartOptionsA = JSON.parse(JSON.stringify(chartOptions));
        chartOptionsA.plotOptions.series.dataGrouping = { approximation: "sum", forced: true, units: [['day', [1]]] };
        // chartOptionsA.chart.type = "line";
        chartOptionsA.chart.type = "column";
        chartOptionsA.plotOptions.series.stacking = "stacking"
        chartOptionsA.series = res
        console.log("chartOptionsA: ");
        console.log(chartOptionsA);
        return chartOptionsA
    }

    ////////////////////////////////////////////////// chartB //////////////////////////////////////////////////

    getChartOptionsB (unit) {
        let numSuccessful = Object.assign({}, dateValues)
        let numFailed = Object.assign({}, dateValues)
        for (let i = 0; i < this.state.logs.length; i++) 
            if (!kpiService.isLogUs(this.state.logs[i])) {
                let log = this.state.logs[i];
                let date = new Date(log['date'])
                let hour = (new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours()))).getTime();
                if (log['action']['formatFormula'] != undefined && log['action']['formatFormula']['status'] === "successful")
                    numSuccessful[hour]++;
                if (log['action']['formatFormula'] != undefined && log['action']['formatFormula']['status'] === "failed")
                    numFailed[hour]++;
            }
        let chartOptionsB = JSON.parse(JSON.stringify(chartOptions));
        chartOptionsB.plotOptions.series.dataGrouping = { approximation: "sum", forced: true, units: [[unit, [1]]] };
        chartOptionsB.chart.type = "column";
        chartOptionsB.plotOptions.series.stacking = "stacking"
        chartOptionsB.series = [
            { data: kpiService.arrayFromObject(numSuccessful), name: "successful" },
            { data: kpiService.arrayFromObject(numFailed), name: "failed" } 
        ]
        return chartOptionsB
    }

    ////////////////////////////////////////////////// chartC //////////////////////////////////////////////////

    getChartOptionsC() {
        let numSuccessful = kpiService.enumerateBetweenDates(s, e, "day", 0);
        let numFailed = kpiService.enumerateBetweenDates(s, e, "day", 0);
        for (let i = 0; i < this.state.logs.length; i++) 
            if (!kpiService.isLogUs(this.state.logs[i])) {
                let log = this.state.logs[i];
                let date = new Date(log['date'])
                let day = (new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()))).getTime(); 
                if (log['action']['formatFormula'] != undefined && log['action']['formatFormula']['status'] === "successful")
                    numSuccessful[day]++ // should we use "numSuccessful[day]++" or "numSuccessful[day.toString()]++" here??? maybe the following NaN error?
                if (log['action']['formatFormula'] != undefined && log['action']['formatFormula']['status'] === "failed") 
                    numFailed[day]++
            }
        var res = [] as any;
        for (var key in numFailed) {
            if (numFailed.hasOwnProperty(key) && (numSuccessful[key] + numFailed[key] != 0)
                && !isNaN(numFailed[key])) // don't understand why it is necessary; why is there NaN?
                res.push([parseInt(key), Math.round(numSuccessful[key] / (numSuccessful[key] + numFailed[key]) * 100) / 100])
        }
        let chartOptionsC = JSON.parse(JSON.stringify(chartOptions));
        chartOptionsC.chart.type = "line";
        
        // either to show data
        // chartOptionsC.plotOptions.series.dataGrouping = { approximation: "close", forced: true, units: [['day', [1]]] };
        // chartOptionsC.series = [
        //     { data: kpiService.arrayFromObject(numSuccessful), name: "successful" },
        //     { data: kpiService.arrayFromObject(numFailed), name: "failed" },
        // ]

        // either to show ratio
        chartOptionsC.plotOptions.series.dataGrouping = { approximation: "close", forced: true, units: [['day', [1]]] };
        chartOptionsC.series = [
            { data: res, name: "ratio" }
        ]
    
        return chartOptionsC
    }

    ////////////////////////////////////////////////// chartD //////////////////////////////////////////////////

    getChartOptionsD() {
        let numSuccessful = kpiService.enumerateBetweenDates(s, e, "week", 0);
        let numFailed = kpiService.enumerateBetweenDates(s, e, "week", 0);
        for (let i = 0; i < this.state.logs.length; i++) 
            if (!kpiService.isLogUs(this.state.logs[i])) {
                let log = this.state.logs[i];
                let date = new Date(log['date'])
                let day = (new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()))).getTime(); 
                for (let i = 0; i < 6; i++)
                    if (numSuccessful.hasOwnProperty(day))
                        break
                    else 
                        day = (new Date(day - 24 * 60 * 60000)).getTime();
                if (log['action']['formatFormula'] != undefined && log['action']['formatFormula']['status'] === "successful")
                    numSuccessful[day]++;
                if (log['action']['formatFormula'] != undefined && log['action']['formatFormula']['status'] === "failed") 
                    numFailed[day]++
            }
        var res = [] as any;
        for (var key in numFailed) {
            if (numFailed.hasOwnProperty(key) && (numSuccessful[key] + numFailed[key] != 0)) // don't understand why there is NaN?
                res.push([parseInt(key), Math.round(numSuccessful[key] / (numSuccessful[key] + numFailed[key]) * 100) / 100])
        }
        let chartOptionsD = JSON.parse(JSON.stringify(chartOptions));
        chartOptionsD.chart.type = "line";

        // either to show data
        // chartOptionsD.series = [
        //     { data: kpiService.arrayFromObject(numSuccessful), name: "successful" },
        //     { data: kpiService.arrayFromObject(numFailed), name: "failed" },
        // ]
        
        // either to show ratio
        chartOptionsD.plotOptions.series.dataGrouping = { approximation: "max", forced: true, units: [['week', [1]]] };
        chartOptionsD.series = [
            { data: res, name: "ratio" }
        ]
        return chartOptionsD
    }

    ////////////////////////////////////////////////// chartE //////////////////////////////////////////////////    

    getChartOptionsE() {
        let x = [] as any;
        for (let i = 0; i < this.state.logs.length; i++) {
            let log = this.state.logs[i];
            if (log['env'] != undefined) {
                let y = aux(log['app']) + "." + log['env']['OfficeHelpersUtilitieshost'] + "." + log['env']['OfficeHelpersUtilitiesplatform']
                if (!x.hasOwnProperty(y))
                    x[y] = Object.assign({}, dateValues)
            }
        }

        for (let i = 0; i < this.state.logs.length; i++) 
            if (!kpiService.isLogUs(this.state.logs[i])) {
                let log = this.state.logs[i];
                if (log['env'] != undefined) {
                    let date = new Date(log['date'])
                    let hour = (new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours()+1))).getTime();
                    let z = aux(log['app']) + "." + log['env']['OfficeHelpersUtilitieshost'] + "." + log['env']['OfficeHelpersUtilitiesplatform']
                    x[z][hour]++
                }
            }

        var res = [] as any;
        for (var key in x) res.push({name: key, data: kpiService.arrayFromObject(x[key])})

        let chartOptionsE = JSON.parse(JSON.stringify(chartOptions));
        chartOptionsE.plotOptions.series.dataGrouping = { approximation: "sum", forced: true, units: [['day', [1]]] };
        chartOptionsE.chart.type = "column";
        chartOptionsE.plotOptions.series.stacking = "stacking"
        chartOptionsE.series = res
        return chartOptionsE
    }

    ////////////////////////////////////////////////// chart1 (better) //////////////////////////////////////////////////
    
    getChartOptions1 () {
        console.log("inside getChartOptions1")
        let numVisitPage = Object.assign({}, dateValues)
        let numVisitPageFFSite = Object.assign({}, dateValues)
        let numVisitPageFFExcelAddin = Object.assign({}, dateValues)
        let numVisitPageFFSiteCall = Object.assign({}, dateValues)
        let numVisitPageFFExcelAddinCall = Object.assign({}, dateValues)
        for (let i = 0; i < this.state.logs.length; i++) 
            if (!kpiService.isLogUs(this.state.logs[i])) {
                let log = this.state.logs[i];
                let date = new Date(log['date'])
                let hour = (new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours()+1))).getTime();
                if (log['action']['theType'] === "VisitPage" && log['app'] !== "FormatterExcelAddin") numVisitPage[hour]++
                if (log['action']['theType'] === "VisitPage" && log['app'] === "FunfunSite") numVisitPageFFSite[hour]++
                if (log['action']['theType'] === "VisitPage" && log['app'] === "FunfunExcelAddin") numVisitPageFFExcelAddin[hour]++
                if (log['action']['theType'] === "CallPost" && log['app'] === "FunfunSite") numVisitPageFFSiteCall[hour]++
                if (log['action']['theType'] === "CallPost" && log['app'] === "FunfunExcelAddin") numVisitPageFFExcelAddinCall[hour]++
            }

        let chartOptions1 = JSON.parse(JSON.stringify(chartOptions));
        chartOptions1.plotOptions.series.dataGrouping = { approximation: "sum", forced: true, units: [['week', [1]]] };
        chartOptions1.chart.type = "line";
        chartOptions1.series = [ 
            { data: kpiService.arrayFromObject(numVisitPageFFExcelAddinCall), name: "FFExcelAddinCall" },
            { data: kpiService.arrayFromObject(numVisitPageFFSiteCall), name: "FFSiteCall" },
            { data: kpiService.arrayFromObject(numVisitPageFFExcelAddin), name: "FFExcelAddin" },
            { data: kpiService.arrayFromObject(numVisitPageFFSite), name: "FFSite", visible: false }, 
            { data: kpiService.arrayFromObject(numVisitPage), name: "Total" }
        ];

        console.log("chartOptions1: ");
        console.log(chartOptions1);
        return chartOptions1
    }

    ////////////////////////////////////////////////// chart3 //////////////////////////////////////////////////

    getChartOptions3 () {
        console.log("inside getChartOptions3")
        let numUsers =  Object.assign({}, dateValues)
        let numUsersAnonym =  Object.assign({}, dateValues)
        let numUsersRegistered =  Object.assign({}, dateValues)
        let numUsersSubscriber =  Object.assign({}, dateValues)
        for (let i = 0; i < this.state.users.length; i++) 
            if (!kpiService.isUserUs(this.state.users[i]) && this.state.users[i]['firstApp'] !== "FormatterExcelAddin") {
                let date = new Date(this.state.users[i]['firstDate']);
                kpiService.addOne(date, numUsers);
                if (this.state.users[i]['local']['type'] === "anonym") 
                    kpiService.addOne(new Date(this.state.users[i]['firstDate']), numUsersAnonym)
                else 
                    kpiService.addOne(new Date(this.state.users[i]['firstDate']), numUsersRegistered)
            }
        for (let i = 0; i < this.state.subscribers.length; i++) 
            if (!kpiService.isSubscriberUs(this.state.subscribers[i])) {
                let date = new Date(this.state.subscribers[i]['firstDate'])
                kpiService.addOne(date, numUsers);
                kpiService.addOne(date, numUsersSubscriber);
            }
        numUsers = kpiService.arrayFromObject(numUsers)
        numUsersAnonym = kpiService.arrayFromObject(numUsersAnonym)
        numUsersRegistered = kpiService.arrayFromObject(numUsersRegistered)
        numUsersSubscriber = kpiService.arrayFromObject(numUsersSubscriber)

        let chartOptions3 = JSON.parse(JSON.stringify(chartOptions));
        chartOptions3.plotOptions.series.dataGrouping = { approximation: "close", forced: true, units: [['week', [1]]] };
        chartOptions3.chart.type = "line"
        chartOptions3.series = [ 
            { data: numUsersSubscriber, name: "Subscribed"},
            { data: numUsersRegistered, name: "Registered" },
            { data: numUsersAnonym, name: "Anonym", visible: false },
            { data: numUsers, name: "Total", visible: false },
        ];
        console.log("chartOptions3: ");
        console.log(chartOptions3);
        return chartOptions3
    }

    componentDidMount() {
        console.log("inside componentDidMount");
        // axios.get(`${FUNFUN_URL}/httpOnly/subscribers`).then(
        //   subscribers => {
        //     console.log(subscribers.data);
        //     this.setState({subscribers: subscribers.data});
        //     console.log("end subscribers");
        //   }
        // );
        axios.get(`${FUNFUN_URL}/httpOnly/users`).then(
            users => {
            //   console.log(users.data);
              this.setState({users: users.data});
              console.log("end users");
              axios.get(`${FUNFUN_URL}/httpOnly/kpi/logsRange`, {params: {start: s, end: new Date(e.getTime() + 24 * 60 * 60000)}}).then(
                logs => {
                  console.log("logs: ")
                  console.log(logs);
                  console.log(JSON.stringify(logs.data[0]))
                  this.setState({logs: logs.data});
                  console.log("end logs");
                  console.log("setChartOptionsReturn begin")
                  this.setChartOptionsReturn()
                  console.log("setChartOptionsReturn end")
                }
            );
            }
        );
      };

    getLength (x) {
        if (x == undefined) 
        return 0
        else return x.length
    }

    render() {
        console.log("inside render")
        return (
        <div> 
            <HighchartsReact highcharts={Highcharts} constructorType="stockChart" options={this.state.chartOptionsReturn} {...this.props} />
            <HighchartsReact highcharts={Highcharts} constructorType="stockChart" options={this.getChartOptionsA()} {...this.props} />
            <HighchartsReact highcharts={Highcharts} constructorType="stockChart" options={this.getChartOptionsE()} {...this.props} />
            <HighchartsReact highcharts={Highcharts} constructorType="stockChart" options={this.getChartOptionsB("day")} {...this.props} />
            <HighchartsReact highcharts={Highcharts} constructorType="stockChart" options={this.getChartOptionsC()} {...this.props} />
            <HighchartsReact highcharts={Highcharts} constructorType="stockChart" options={this.getChartOptionsB("week")} {...this.props} />
            <HighchartsReact highcharts={Highcharts} constructorType="stockChart" options={this.getChartOptionsD()} {...this.props} /> 
            <HighchartsReact highcharts={Highcharts} constructorType="stockChart" options={this.getChartOptions1()} {...this.props} />
            {/* <HighchartsReact highcharts={Highcharts} constructorType="stockChart" options={this.getChartOptions3()} {...this.props} /> */}
            {/* <HighchartsReact highcharts={Highcharts} constructorType="stockChart" options={this.getChartOptions5()} {...this.props} /> */}
            {/* <div>{this.getUsersTable()}</div> */}
            {/* <div>
                <HotTable
                    settings={{ licenseKey: 'non-commercial-and-evaluation' }}
                    data={this.getUsersTable()}
                    colHeaders={false}
                    rowHeaders={false}
                    readOnly={true}
                    width="600"
                    height="300"
                />
            </div> */}
        </div>
        )}
}

export default Kpi;