export function runWithCancel(fn: any, ...args: any[]) {
    const gen = fn(...args);
    let cancelled: boolean, cancel;
    const promise = new Promise((resolve, reject) => {
        // 定义 cancel 方法，并返回它
        cancel = () => {
            cancelled = true;
            reject({ reason: 'cancelled' });
        };

        onFulfilled();

        function onFulfilled(res?: any) {
            if (!cancelled) {
                let result;
                try {
                    result = gen.next(res);
                } catch (e) {
                    return reject(e);
                }
                next(result);
                return null;
            }
        }

        function onRejected(err: any) {
            let result;
            try {
                result = gen.throw(err);
            } catch (e) {
                return reject(e);
            }
            next(result);
        }

        function next({ done, value }: any) {
            if (done) {
                return resolve(value);
            }
            // 假设我们总是接收 Promise，所以不需要检查类型
            return value.then(onFulfilled, onRejected);
        }
    });

    return { promise, cancel };
}

// "Sheet1!A1:A10" ==> "A1:A10"
export function removeSheet(str: string): string {
    return str.substring(str.indexOf("!") + 1, str.length);
}

export function round(value: number, precision: number): string {
    const multiplier = Math.pow(10, precision || 0);
    return (Math.round(value * multiplier) / multiplier).toFixed(precision);
}
