import * as OfficeHelpers from '@microsoft/office-js-helpers';
import queryString from 'query-string';

export enum OfficeHost {
  Web,
  Excel,
  Other,
}

export function getOfficeHost(): OfficeHost {
  if (OfficeHelpers.Utilities.host === 'WEB' && OfficeHelpers.Utilities.platform === null) {
    return OfficeHost.Web;
  } else if (OfficeHelpers.Utilities.host === 'EXCEL') {
    return OfficeHost.Excel;
  }
  return OfficeHost.Other;
}

//tslint:disable-next-line:no-any
export function getNextFromUrl(location: any): string {
  const query = queryString.parse(location.search);
  let next = '';
  if (query.next) {
    next = Array.isArray(query.next) ? query.next[0] : query.next;
  } else {
    return '';
  }
  if (next.length === 0 || next[0] !== '/') {
    next = '/' + next;
  }
  return next;
}
