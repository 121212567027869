import React from "react";
import { List } from 'office-ui-fabric-react/lib/components/List';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import  styles from "./index.module.scss";
import { FocusZone, FocusZoneDirection, PrimaryButton } from 'office-ui-fabric-react';
import { runWithCancel, removeSheet, round } from "../utils";
import { ProgressBar } from "react-bootstrap"

type BottleneckDetectorOp = undefined | "time" | "sheet" | "range"

type IBottleneckDetectorState = {
    lastOp: BottleneckDetectorOp,
    calculating: boolean,
    noFormula: boolean,
    blocks: any[],
    timecost: string,
    logs: string,
    showLogs: boolean,
    progress: number,
}

function getInitBottleneckDetectorState(): IBottleneckDetectorState {
    const blocks: any[] = [];
    return {
        lastOp: undefined,
        calculating: false,
        noFormula: false,
        blocks,
        timecost: "",
        logs: "",
        showLogs: false,
        progress: 0,
    }
}

const nrGlobal = 1048576;
const ncGlobal = 16384;

export type IBlockItem = any

type DetectMode = "ocaml" | "sheets"

class BottleneckDetector extends React.Component<{}, IBottleneckDetectorState> {
    readonly state = getInitBottleneckDetectorState();
    private _logDiv = React.createRef<HTMLDivElement>();

    componentDidMount() {
        if(typeof OfficeExtension !== 'undefined')
            OfficeExtension.config.extendedErrorLogging = true;
        this.scrollToBottom()
    }

    componentDidUpdate() {
        this.scrollToBottom()
    }

    scrollToBottom = () => {
        if (this._logDiv.current) {
            this._logDiv.current.scrollTop = this._logDiv.current.scrollHeight;
        }
    }

    printlnLogs = (log: string) => {
        this.setState({ logs: this.state.logs + log + "\n" })
    }

    printLogs = (log: string) => {
        this.setState({ logs: this.state.logs + log })
    }

    clearLogs = () => {
        this.setState({ logs: "" });
        this.forceUpdate();
    }

    clearResult = () => {
        this.setState({ blocks: [] });
    }

    getSheetNames = () => Excel.run(async (ctx) => {
        const sheetNames : string[] = [];
        var worksheets = ctx.workbook.worksheets;
        worksheets.load('name');
        await ctx.sync();
        for (var i = 0; i < worksheets.items.length; i++) {
            sheetNames.push(worksheets.items[i].name);
        }
        return sheetNames;
    })

    unprotectSheets = (sheetNames: string[]) => {
        return Excel.run(async (ctx) => {
            let sheetName: string;
            for (let i = 0; i < sheetNames.length; i++) {
                const sheet = ctx.workbook.worksheets.getItem(sheetNames[i]);
                sheetName = sheetNames[i];
                sheet.protection.unprotect();
            }
            const f = async () => {
                try {
                    return await ctx.sync();
                }
                catch (error) {
                    this.printlnLogs('You need to manually unprotect "' + sheetName +
                        '" with a password to continue');
                    throw new Error("unprotectSheets");
                }
            };
            await f();
        });
    }

    unhideSheets = (sheetNames: string[], nr: number, nc: number) => {
        return Excel.run(async (ctx) => {
            for (let i = 0; i < sheetNames.length; i++) {
                const sheet = ctx.workbook.worksheets.getItem(sheetNames[i]);
                sheet.visibility = "Visible";
                let r = sheet.getUsedRange();
                r = r.getBoundingRect("A1");
                r.rowHidden = false;
                r.columnHidden = false;
            }
            return await ctx.sync();
        });
    }

    getConcrete = (sheetNames: string[]) => {
        return Excel.run(async (ctx) => {
            const worksheetsOCaml : any[] = [];
            const usedRangeAsOCaml : any[] = [];
            const worksheets = ctx.workbook.worksheets;
            const usedRangeAs : any[] = [];
            const activateSheet = ctx.workbook.worksheets.getActiveWorksheet();
            for (let i = 0; i < sheetNames.length; i++) {
                const sheet = worksheets.getItem(sheetNames[i]);
                sheet.activate();
                const uR = sheet.getUsedRange();
                usedRangeAs[i] = uR.getBoundingRect("A1"); // larger usedRange 
                this.printlnLogs(`    getting address and formulas in '${sheetNames[i]}'`);
                usedRangeAs[i].load(["address", "formulasR1C1"]);
                await ctx.sync();
            }
            activateSheet.activate();
            await ctx.sync();
            this.addProgress(4);
            for (let i = 0; i < sheetNames.length; i++) {
                worksheetsOCaml.push({ nameCode: sheetNames[i] });
                usedRangeAsOCaml.push({
                    sheet: sheetNames[i],
                    address: usedRangeAs[i].address,
                    formulasR1C1: usedRangeAs[i].formulasR1C1
                });
            }
            return { worksheetsOCaml, usedRangeAsOCaml }
        });
    }

    setBlocksUnit = (om: { worksheetsOCaml: any, usedRangeAsOCaml: any }) => {
        const { worksheetsOCaml, usedRangeAsOCaml } = om;
        const usedRangeAsOCamlWOValues : any[] = [];
        // tslint:disable-next-line: no-console
        console.log(worksheetsOCaml, usedRangeAsOCaml);
        for (let i = 0; i < usedRangeAsOCaml.length; i++) {
            const formulasR1C1 = usedRangeAsOCaml[i].formulasR1C1;
            const formulasR1C1String : any[] = [];
            for (let j = 0; j < formulasR1C1.length; j++) {
                const tmp : any = [];
                for (let k = 0; k < formulasR1C1[j].length; k++) {
                    let x = String(formulasR1C1[j][k]);
                    if (x.length >= 1 && x.substring(0, 1) !== "=") {
                        x = "";
                    }
                    if (x !== "") {
                        // tslint:disable-next-line: no-console
                        console.log(x)
                    }
                    tmp.push(x);
                }
                formulasR1C1String[j] = tmp;
            };
            usedRangeAsOCamlWOValues.push({
                sheet: usedRangeAsOCaml[i].sheet,
                address: usedRangeAsOCaml[i].address,
                formulasR1C1: formulasR1C1String
            });
        }
        this.printLogs("    getting unit blocks from the engine")
        var bef = performance.now();
        const blocksUnit = getBlocksUnit(worksheetsOCaml, usedRangeAsOCamlWOValues);
        var af = performance.now();
        const tcost = round((af - bef) / 1000, 2);
        this.printlnLogs(` : ${tcost}s`);
        if (blocksUnit.length === 0) throw new Error("no formulas");
        return blocksUnit
    }

    setBlocksUnitF = async (blocksUnit: any[]) => {
        return Excel.run(async function (ctx) {
            const blocksUnitF : any[] = [];
            const worksheets = ctx.workbook.worksheets;
            const rangesLocal : any[] = [];
            for (let i = 0; i < blocksUnit.length; i++) {
                rangesLocal[i] =
                    worksheets.getItem(blocksUnit[i].sh).getRange(blocksUnit[i].ad);
                rangesLocal[i].load(["formulasR1C1", "values"]);
            }
            await ctx.sync();
            for (let i = 0; i < blocksUnit.length; i++) {
                blocksUnitF.push({
                    sh: blocksUnit[i].sh,
                    ad: blocksUnit[i].ad,
                    co: blocksUnit[i].co,
                    f: blocksUnit[i].f,
                    formulasR1C1: rangesLocal[i].formulasR1C1,
                    values: rangesLocal[i].values
                });
            }
            return blocksUnitF
        });
    }

    findBlocksUnitF = (blocksUnitF: any[], sheetName: string) => {
        var res : any[] = [];
        for (var i = 0; i < blocksUnitF.length; i++) {
            if (blocksUnitF[i].sh === sheetName)
                res.push(i);
        }
        return res;
    }

    setBlocksWithSheets = async (blocksUnitF: any[], sheetNames: string[]) => {
        return Excel.run(async (ctx) => {
            const blocks : any[] = [];
            var worksheets = ctx.workbook.worksheets;
            var uRs : any[] = [];
            for (let i = 0; i < sheetNames.length; i++) {
                var sheet = worksheets.getItem(sheetNames[i]);
                uRs[i] = sheet.getUsedRange();
                uRs[i].load('address');
            }
            await ctx.sync();
            for (let i = 0; i < sheetNames.length; i++) {
                blocks.push({
                    sh: sheetNames[i],
                    ad: removeSheet(uRs[i].address),
                    co: coFromString(uRs[i].address),
                    f: "mixed",
                    units: this.findBlocksUnitF(blocksUnitF, sheetNames[i]),
                    t: 0
                });
            }
            return blocks
        });
    }

    setBlocksByOCaml = (blocksUnitF: any[]) => {
        const blocks : any[] = [];
        for (var i = 0; i < blocksUnitF.length; i++) {
            blocks.push({
                sh: blocksUnitF[i].sh,
                ad: blocksUnitF[i].ad,
                co: blocksUnitF[i].co,
                f: blocksUnitF[i].f,
                units: [i],
                t: 0
            });
        }
        return blocks
    }

    fillBlock = async (blocks: any[], blocksUnitF: any[], prop: "formulasR1C1" | "values", i: number) => {
        return Excel.run(async (ctx) => {
            var bsuf = blocks[i].units;
            for (var j = 0; j < bsuf.length; j++) {
                var buf = blocksUnitF[bsuf[j]];
                var r = ctx.workbook.worksheets.getItem(buf.sh).getRange(buf.ad);
                switch (prop) {
                    case "formulasR1C1":
                        r.formulasR1C1 = buf.formulasR1C1;
                        break;
                    case "values":
                        r.formulasR1C1 = buf.values;
                        break;
                }
            }
            return await ctx.sync();
        });
    }

    fillBlocks = async (blocks: any[], blocksUnitF: any[], prop: "formulasR1C1" | "values") => {
        return Excel.run(async (ctx) => {
            for (var i = 0; i < blocks.length; i++) {
                var bsuf = blocks[i].units;
                for (var j = 0; j < bsuf.length; j++) {
                    var buf = blocksUnitF[bsuf[j]];
                    var r = ctx.workbook.worksheets.getItem(buf.sh).getRange(buf.ad);
                    switch (prop) {
                        case "formulasR1C1":
                            r.formulasR1C1 = buf.formulasR1C1;
                            break;
                        case "values":
                            r.formulasR1C1 = buf.values;
                            break;
                    }
                }
            }
            this.printLogs(`    filling blocks with ${prop}`)
            const bef = performance.now();
            await ctx.sync();
            const af = performance.now();
            const tcost = round((af - bef) / 1000, 2)
            this.printlnLogs(` : ${tcost}s`);
            this.addProgress(4);
        });
    }

    calculate = async (mode: Excel.CalculationType) => {
        return await Excel.run(async (ctx) => {
            ctx.workbook.application.calculate(mode);
            var before = performance.now();
            await ctx.sync();
            var after = performance.now();
            var t = round((after - before) / 1000, 2);
            return t;
        })
    }

    calculateBlocks = async (blocks: any[], blocksUnitF: any[]) => {
        var unit = 10 / blocks.length;
        for (var i = 0; i < blocks.length; i++) {
            await this.fillBlock(blocks, blocksUnitF, 'formulasR1C1', i);
            this.addProgress(unit);
            this.printLogs("    " + blocks[i].sh + "!" + blocks[i].ad + " ")
            const res = await this.calculate(Excel.CalculationType.fullRebuild);
            this.printlnLogs("=> " + res + "s");
            this.addProgress(unit);
            blocks[i].t = res;
            await this.fillBlock(blocks, blocksUnitF, 'values', i);
            this.addProgress(unit);
        }
    }

    sortBlocks = (blocks: any[]) => {
        const blocksS = blocks.slice();
        blocksS.sort(function (a, b) { return b.t - a.t; });
        return blocksS
    }

    get lastOp(): BottleneckDetectorOp {
        return this.state.lastOp;
    }

    set lastOp(v: BottleneckDetectorOp) {
        this.setState({ lastOp: v });
    }

    get calculating(): boolean {
        return this.state.calculating;
    }

    set calculating(v: boolean) {
        this.setState({ calculating: v });
    }

    setShowLogs = (show: boolean) => {
        this.setState({ showLogs: show });
    }

    toggleShowLogs = () => {
        this.setState({ showLogs: !this.state.showLogs });
    }

    cancel: (() => void) | undefined = undefined;

    addProgress(n: number) {
        this.setState({ progress: this.state.progress + n })
    }

    setProgress(n: number) {
        this.setState({ progress: n })
    }

    resetProgress() {
        this.setState({ progress: 0 })
    }

    * doCalcFullRebuild() {
        this.setShowLogs(false);
        this.setProgress(0);
        this.clearLogs();
        this.setShowLogs(true);
        this.lastOp = undefined;
        this.calculating = true;
        this.printlnLogs("BEGIN");
        this.setProgress(30);
        const timecost = yield this.calculate(Excel.CalculationType.fullRebuild);
        this.setState({ timecost, lastOp: "time" });
        this.printlnLogs("DONE");
        this.setProgress(100);
        this.setShowLogs(false);
        return timecost;
    }

    * doDetectSlow(mode: DetectMode) {
        this.setShowLogs(false);
        this.setProgress(0);
        this.setShowLogs(true);
        this.lastOp = undefined;
        this.calculating = true;
        this.clearLogs();
        this.printlnLogs("BEGIN")
        this.printlnLogs("getting sheet names");
        this.addProgress(4);
        const sheetNames = yield this.getSheetNames();
        this.printlnLogs("unprotecting sheets");
        this.addProgress(4);
        yield this.unprotectSheets(sheetNames);
        this.printlnLogs("unhiding sheets");
        this.addProgress(4);
        yield this.unhideSheets(sheetNames, nrGlobal, ncGlobal);
        this.printlnLogs("getting workbook state");
        this.addProgress(4);
        const om = yield this.getConcrete(sheetNames);
        this.printlnLogs("making unit blocks");
        this.addProgress(4);
        const blocksUnit = this.setBlocksUnit(om);
        this.printlnLogs("making full unit blocks");
        this.addProgress(4);
        const blocksUnitF = yield this.setBlocksUnitF(blocksUnit);
        this.printlnLogs("making measurable blocks");
        this.addProgress(4);
        let blocks: any;
        if (mode === "sheets") {
            blocks = yield this.setBlocksWithSheets(blocksUnitF, sheetNames);
        } else {
            blocks = this.setBlocksByOCaml(blocksUnitF);
        }
        this.printlnLogs("filling blocks with values");
        this.addProgress(4);
        yield this.fillBlocks(blocks, blocksUnitF, "values");
        this.printlnLogs("re-calculating blocks");
        this.addProgress(4);
        yield this.calculateBlocks(blocks, blocksUnitF);
        this.printlnLogs("sorting blocks");
        this.addProgress(4);
        const blocksS = this.sortBlocks(blocks);
        this.printlnLogs("filling blocks with formulas");
        this.addProgress(4);
        yield this.fillBlocks(blocks, blocksUnitF, "formulasR1C1");
        this.printlnLogs("showing blocks");
        this.addProgress(4);
        const lastOp: BottleneckDetectorOp = (mode === "ocaml") ? "range" : "sheet"
        this.setState({ blocks: blocksS, lastOp, showLogs: false });
        this.printlnLogs("DONE");
        this.setProgress(100);
        return true
    }

    select(sheetName: string, rangeAddress: string) {
        Excel.run(function (ctx) {
            const range = ctx.workbook.worksheets.getItem(sheetName).getRange(rangeAddress);
            range.select();
            return ctx.sync();
        });
    }

    calcFullRebuild = () => {
        if (this.cancel) {
            this.cancel();
        }
        var promise = new OfficeExtension.Promise(function (resolve, reject) {
            resolve(null);
        });
        promise
            .then(async () => {
                const { promise, cancel } = runWithCancel(this.doCalcFullRebuild.bind(this));
                this.cancel = cancel;

                return promise.catch((err) => {
                    if (err.reason === "cancelled") {
                        // tslint:disable-next-line: no-console
                        console.log("canceled last verification");
                    } else {
                        throw err;
                    }
                });
            }).catch((error) => {
                if (error.message === "no formulas") {
                    this.setState({ noFormula: true });
                    this.printlnLogs("DONE");
                    this.setProgress(100);
                    this.setShowLogs(false);
                }
                else if (error.message !== "unprotectSheets") {
                    this.printlnLogs("Error: " + error);
                    if (error instanceof OfficeExtension.Error) {
                        this.printlnLogs("Debug info: " + JSON.stringify(error.debugInfo));
                    }
                    this.setProgress(100);
                }
            }).finally(() => {
                this.calculating = false;
            });
    }

    detectSlow = (mode: DetectMode) => {
        if (this.cancel) {
            this.cancel();
        }
        var promise = new OfficeExtension.Promise(function (resolve, reject) {
            resolve(null);
        });
        promise
            .then(async () => {
                const { promise, cancel } = runWithCancel(this.doDetectSlow.bind(this, mode));
                this.cancel = cancel;
                return promise.catch((err) => {
                    if (err.reason === "cancelled") {
                        // tslint:disable-next-line: no-console
                        console.log("canceled last verification");
                    } else {
                        throw err;
                    }
                });
            })
            .catch((error) => {
                if (error.message === "no formulas") {
                    const lastOp: BottleneckDetectorOp = (mode === "ocaml") ? "range" : "sheet"
                    this.setState({ noFormula: true, lastOp });
                    this.printlnLogs("DONE");
                    this.setProgress(100);
                    this.setShowLogs(false);
                }
                else if (error.message !== "unprotectSheets") {
                    this.printlnLogs("Error: " + error);
                    if (error instanceof OfficeExtension.Error) {
                        this.printlnLogs("Debug info: " + JSON.stringify(error.debugInfo));
                    }
                    this.setProgress(100);
                }
            }).finally(() => {
                this.calculating = false;
            });
    }

    _onRenderCell = (item: IBlockItem | undefined, index: number | undefined): JSX.Element => {
        if (!item) {
            return <></>
        }
        if (item.units.length !== 0) {
            const onClick = () => this.select(item.sh, item.ad);
            return (
                <div className={styles.blockCell}>
                    <span className={(item.t >= 1) ? styles.blockItemError : styles.blockItem} onClick={onClick}>
                        {item.sh + "!" + item.ad + " => " + item.t + "s"}<br />
                        {item.f}
                    </span>
                    <span>
                        <br />
                        <br />
                    </span>
                </div>
            );
        } else {
            return <></>
        }
    }

    renderResult = (): JSX.Element => {
        const items: IBlockItem[] = this.state.blocks;
        switch (this.state.lastOp) {
            case "range":
            case "sheet":
                if (items.length > 0) {
                    return (
                        <div className={styles.result}>
                            <span>Click on the results to select:</span>
                            <br />
                            <br />
                            <FocusZone direction={FocusZoneDirection.vertical}>
                                <List items={items} onRenderCell={this._onRenderCell} />
                            </FocusZone>
                        </div>
                    )
                } else {
                    if (this.state.noFormula) {
                        return (
                            <div className={styles.result} >
                                <span>There are no single formulas to calculate in this workbook. </span>
                            </div >
                        )
                    } else {
                        return <div className={styles.result} />
                    }
                }
            case "time":
                return (
                    <div className={styles.result} >
                        <span>The full calculation time of the workbook is {this.state.timecost} s.</span>
                    </div >
                )
            case undefined:
                if (this.state.calculating) {
                    return (
                        <div className={styles.result} >
                            <span>A detection may take a few minutes, please be patient...</span>
                        </div >
                    )
                }
                return <div className={styles.result} />
        }
    }

    onClickManual = () => {
        // window.open("/help.html#detector");
        window.open("https://www.10studio.tech/docs/bottleneckDetector")
    }

    render() {
        const iconName = this.state.showLogs ? "CaretSolidRight" : "CaretSolidDown";
        const progressFinished = this.state.progress === 100 ? false : true;
        return (
            <div className={styles.main}>
                <div className={styles.padding}>
                    <div style={{ textAlign: "center" }}>
                        <PrimaryButton
                            style={{ width: '240px' }}
                            // tslint:disable-next-line: jsx-no-lambda
                            onClick={() => this.calcFullRebuild()}
                            disabled={this.calculating}
                        >Measure Full Calculation Time
                        </PrimaryButton>
                    </div>
                    <br />
                    <div style={{ textAlign: "center" }}>
                        <PrimaryButton
                            style={{ width: '240px' }}
                            // tslint:disable-next-line: jsx-no-lambda
                            onClick={() => this.detectSlow("sheets")}
                            disabled={this.calculating}
                        >Detect Slow Sheets
                        </PrimaryButton>
                    </div>
                    <br />
                    <div style={{ textAlign: "center" }}>
                        <PrimaryButton
                            style={{ width: '240px' }}
                            // tslint:disable-next-line: jsx-no-lambda
                            onClick={() => this.detectSlow("ocaml")}
                            disabled={this.calculating}
                        >Detect Slow Ranges
                        </PrimaryButton>
                        {/* <p onClick={this.onClickManual} className={styles.manual}>Manual</p> */}
                    </div>
                    <hr className={styles.hr} />
                    {this.renderResult()}
                </div>
                <div className={styles.footer}>
                    <div className={styles.logIcon} onClick={this.toggleShowLogs}>
                        <Icon iconName={iconName} />
                    </div>
                    {// tslint:disable-next-line: jsx-no-multiline-js
                        this.state.showLogs &&
                        <div>
                            <div ref={this._logDiv} className={styles.logContainer}>
                                <div className={styles.logContent}>
                                    <Text className={styles.logText} block={true}>
                                        {this.state.logs}
                                    </Text>
                                </div>
                            </div>
                            <div className="container">
                                <ProgressBar style={{ height: 12 }} className={progressFinished ? "active" : ""} striped={progressFinished} now={this.state.progress} animated={progressFinished} />
                            </div>
                        </div>
                    }
                </div>
            </div >
        )
    }
}

export default BottleneckDetector
