import React, { lazy, Suspense, ChangeEvent } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css'; // bootstrap.css may interfere slightly the initial css. Try home => demo => home and see differences
import './demo.css';
import copyToClipboard from 'copy-to-clipboard';
// import Head from '@docusaurus/Head';
import { Toggle } from 'office-ui-fabric-react/lib/Toggle';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Label } from 'office-ui-fabric-react/lib/Label';
import Slider, { SliderProps, Mark } from '@material-ui/core/Slider';
import DiscreteSlider from '../DiscreteSlider'; 
import { withStyles } from '@material-ui/core/styles';

import { DefaultButton, PrimaryButton, IStackTokens } from 'office-ui-fabric-react';
import { TextField, MaskedTextField } from 'office-ui-fabric-react/lib/TextField';
import { Dropdown, DropdownMenuItemType, IDropdownStyles, IDropdownOption } from 'office-ui-fabric-react/lib/Dropdown';

const ResizableMonacoEditor = lazy(() => import('../EditorLSP/editorLSP'));
  
// import 'infima/dist/css/default/default.css'
// import 'office-ui-fabric-react/dist/css/fabric.css';

const monacoOptions = {
  fontSize: 16,
  // fontFamily: '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif, monospace',
  lineNumbers: 'off',
  // glyphMargin: true, //left side,
  lineDecorationsWidth: 0, // width between line number and content,
  renderIndentGuides: false, // no indent guide lines
  minimap: { enabled: false },
  extraEditorClassName: 'editor'
};

const sliderValues = [30, 40, 50, 60, 70, 80, 100, 160];

const sliderMasks = sliderValues.map(i => ({
  value: i,
  label: i.toString(),
}));

const StyledSlider = withStyles({
  root: { margin: '0 12px 0 8px' },
})(DiscreteSlider);

const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: 300 },
};

export const options: IDropdownOption[] = [
  { key: 'sample 1', text: '=VLOOKUP(CW30,$G$487:$AF$498,2,FALSE)-VLOOKUP(CW31,$G$487:$AF$498,2,FALSE)' },
  { key: 'sample 2', text: '=(AA19-VLOOKUP(G14,$D$11:$X$18,8,FALSE))*100' },
  { key: 'sample 3', text: '=IF(AG108="--","low",IF(AG108>$AG$5,"high",IF(AG108>$AG$6,"medium","low")))' },
  { key: 'sample 4', text: '=(CL295*CK295)+(BX295*BW295)+(BC295*BB295)' },
  { key: 'sample 5', text: '=IF(E10=0,0,VLOOKUP(3,Z12:AD17,4,FALSE))*(D10)*(26+AX10/12+BA10/12)' },
  { key: 'sample 6', text: '=IF(AA17="Agree",AA18,IF(AA17="Disagree - Low",1,IF(AA17="Disagree - Medium",3,5)))' },
  { key: 'sample 7', text: '=IF(AA17="high",AA18+2,IF(AA17="medium",AA18+1,AA18))' },
  { key: 'sample 8', text: '=IF(AA22="Not needed for this sotre",5,IF(AA22<AC$19,AD$19,IF(AA22<AC$20,AD$20,IF(AA22<AC$21,AD$21,IF(AA22<AC$23,AD$23,0)))))' },
  { key: 'sample 9', text: '=IF(BX370=0,6,VLOOKUP(Z17,$AB$17:$AE$22,2,FALSE))' },
  { key: 'sample 10', text: '=IF(AA17>0,(AA17-AA18)/AA17+100,0)' },
  { key: 'sample 11', text: '=IF(AC17>0,AA17/AC17*AE17/12+100,0)' },
  { key: 'sample 12', text: '=VLOOKUP($C17,$F$9:$J$491,4,FALSE)' },
  { key: 'sample 13', text: '=IF(AA17="Non demandé pour ce magasin",5,IF(AA17<AC$19,AD$19,IF(AA17<AC$20,AD$20,IF(AA17<AC$21,AD$21,IF(AA17<AC$22,AD$22,IF(AA17<AC$23,AD$23,IF(AA17<AC$24,AD$24,IF(AA17<AC$25,AD$25,IF(AA17<AC$26,AD$26,0)))))))))' },
  { key: 'sample 14', text: '=IF(SEARCH("ABC",H49)>0,IF((DATE(((2014+IF(VALUE(MID(H49,(SEARCH("/",H49))-2,2))=1,1,0))),(MID(H49,(SEARCH("/",H49))-2,2)),(MID(H49,(SEARCH("/",H49))+1,2))))<100,"Send","Dont"),"#VALUE!")'}
];

const versionOptions: IDropdownOption[] = [
  { key: '1', text: 'Formatter version 1.0' }
]

class Demo extends React.Component <any, any> {
  editor: any;
  formatAction: any;
  widthLimit: any;
  timeout: any;

  constructor(props) {
    super(props)
    let screenHeight = window.innerHeight
    let widthLimit = 80
    let autoWidthLimit = true

    window.addEventListener('size', () => {
      this.setState({  screenHeight: window.innerHeight });
    });

    let formula = new URLSearchParams(window.location.search).get('formula')
    let version = new URLSearchParams(window.location.search).get('version')

    formula = formula ? decodeURIComponent(formula) : '';
    version = version ? version : '1';

    this.state = {
      screenHeight, formula, version, widthLimit, autoWidthLimit
    }

  }
  
  _handleEditorDidMount (editor, monaco) {
    this.editor = editor;
    this.formatAction = this.editor.getAction('editor.action.formatDocument');
    if (this.state.formula !== "") {
      this.runFormat(); // I'm not very sure this is the best way. At least, if we don't have this line, https://...formula=xxx will not be auto-formatted after loading.
    }
  }

  runFormat () {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      console.log(`this.state.widthLimit in runFormat: ${this.state.widthLimit}`);
      console.log(`this.state.autoWidthLimit in runFormat: ${this.state.autoWidthLimit}`);
      this.formatAction.run();
    }, 500);
  }
 
  inputChange (event) {
    let value = event.target.value;
    this.setState((s) => ({ formula: value }));
    this.runFormat();
  }

  versionChange (event) {
    let value = event.target.value;
    this.setState((s) => ({  version: value }));
  }

  formulaChange (event) {
    let value = event.target.value;
    console.log(`formulaChange:`)
    let text = event.target.innerText
    this.setState({ formula: text }) 
    this.runFormat()
  }

  copyToClipboard () {
    let uri = `https://www.10studio.tech/demo/?v=${this.state.version}&formula=${encodeURIComponent(this.state.formula)}`;
    copyToClipboard(uri);
  }

  onClickAutoWidthLimit = (
    _ev: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
    checked?: boolean,
  ) => {
    this.setState({ autoWidthLimit: checked === undefined ? true : checked }, this.runFormat.bind(this));
  };

  // set widthLimit(value: number | undefined) {
  //   console.log("set widthLimit")
  //   if (value !== undefined) {
  //     const oldValue = this.state.widthLimit;
  //     if (oldValue !== value) {
  //       this.setState({ widthLimit: value }, this.format);
  //     }
  //   }
  // }

  // get widthLimit(): number | undefined {
  //   console.log("get widthLimit")
  //   if (this.state.autoWidthLimit) {
  //     return undefined;
  //   }
  //   return this.state.widthLimit;
  // }

  onWidthLimitChange = (_ev: object, value: number | number[]) => {
    console.log("onWidthLimitChange " + value)
    let x
    if (typeof value === 'number') {
      x = value
    } else {
      x = value[0]
    }
    if (x !== undefined) {
      const oldValue = this.widthLimit;
      if (oldValue !== x) {
        this.setState( {  widthLimit: x }, this.runFormat.bind(this));
      }
    }
  };

  render () {
    const widthLimit = this.state.widthLimit;
    const hGap = 5;
    const editorVh = 40;
    const contentTotalHeight = 40 + 31 + 2 * 32 + 31 + 31 + 24 + 31 + 32 + 20; // Header + Toggle + 2XButton + Tittle + Toggle + Slider + Tittle + Drapdown + 20
    const screenHeight = this.state.screenHeight;
    console.log(screenHeight);
    let vGap = screenHeight
      ? ((screenHeight * (100 - editorVh)) / 100 - contentTotalHeight) / 22
      : 5;
    vGap = Math.max(Math.min(vGap, 6), 2);
    console.log(vGap);

    let _handleEditorDidMount = this._handleEditorDidMount.bind(this)
    return (
      <div className="App">
        {/* <Head>
          <script src="/lib/analyzejs.js"></script>
        </Head> */}

        <div className="container">
          <div className="row">
            {/* <div className="col col--10 col--offset-1"> */}
            <div className="col col--12">
              <div className="row align-items-end">
                <div className="col col--7">
                  <br/><br/>
                  <TextField
                    value={this.state.formula} 
                    onChange={this.inputChange.bind(this)} 
                    multiline rows={3}
                    placeholder="=Insert your formula"
                    style={{
                      fontSize: 16,
                      fontFamily: 'Menlo, Monaco, "Courier New", monospace', 
                      paddingLeft: 15.9943
                    }}
                  />
                  <br/><br/>
                  <div className="editor-wrapper">
                    <Suspense fallback={
                        <div style={{
                          fontFamily: '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
                          fontWeight: 300,
                          fontSize: 14,
                          color: '#C0C0C0'}}><br/><br/>&nbsp;&nbsp;&nbsp;&nbsp;... loading
                      </div>}>
                      <ResizableMonacoEditor 
                        autoWidthLimit={this.state.autoWidthLimit} 
                        widthLimit={this.state.widthLimit} 
                        value={this.state.formula} 
                        editorDidMount={_handleEditorDidMount} 
                        height="100%" 
                        options={monacoOptions} />
                    </Suspense>
                  </div>
                </div>
                <div className="col col--3 col--offset-1">
                  <br/><br/>
                  <Dropdown
                      onChange={this.formulaChange.bind(this)}
                      placeholder="Select a sample formula"
                      options={options}
                      styles={dropdownStyles}
                    />
                  {/* <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/> */}
                  <div className="verticalspace"></div>
                  <Stack horizontal={true} wrap={true} styles={{ inner: { margin: 0 } }}>
                    <div
                      style={{
                        flex: '1 0 0px',
                        minWidth: '310px',
                        maxWidth: '340px',
                        margin: `${3 * vGap}px ${hGap}px`, // 3vGap
                      }}>
                      {/* <Label>Width Limit:</Label> */}
                      <Toggle
                        onChange={this.onClickAutoWidthLimit}
                        checked={this.state.autoWidthLimit}
                        label="Width Limit Auto"
                        inlineLabel={true}
                      />
                      <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                        <StyledSlider
                          defaultValue={widthLimit}
                          value={widthLimit}
                          // tslint:disable:jsx-no-lambda
                          getAriaValueText={_ => widthLimit.toString()}
                          aria-labelledby="discrete-slider-custom"
                          values={sliderValues}
                          disabled={this.state.autoWidthLimit}
                          valueLabelDisplay="off"
                        //   onChange={this.onWidthLimitChange} // comment for TypeScript reason
                          marks={sliderMasks}
                        />
                      </div>
                    </div>
                  </Stack>
                  <br/><br/>
                  <Dropdown
                      onChange={this.formulaChange.bind(this)}
                      placeholder="Select an option"
                      // label="Formatter Version"
                      options={versionOptions}
                      styles={dropdownStyles}
                      defaultSelectedKey={this.state.version}
                      disabled={true}
                    />
                  <br/>
                  <PrimaryButton
                    ariaDescription="Copy URL to Clipboard"
                    onClick={this.copyToClipboard.bind(this)} 
                    allowDisabledFocus 
                    disabled={false}
                    checked={false}
                  >Copy URL to Clipboard
                  </PrimaryButton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br/><br/>
      </div>
    );
  }
}

export default Demo;
