import axios, { AxiosRequestConfig, AxiosError } from 'axios';

import moment from 'moment';
import { getSignToken, payloadFromToken, saveSignToken, delSignToken } from './auth-utils';
import { LoginOauthPayload as RawLoginOauthPayload, LoginFrom as RawLoginFrom } from './auth-oauth';
import { getOfficeHost, OfficeHost } from '../utils';
export { loginOauth } from './auth-oauth';
export { loginSSO } from './auth-sso';
export type LoginFrom = RawLoginFrom;

export type LoginOauthPayload = RawLoginOauthPayload;

//tslint:disable-next-line:no-any
export async function fetch(): Promise<any> {
  const token = await getSignToken();
  let user;
  try {
    user = payloadFromToken(token);
    if (user.exp) {
      if (Date.now() > user.exp * 1000) {
        throw new Error('token has expired');
      }
    }
  } catch {
    user = await registerAnonym();
  }
  if (!user) {
    user = await logout();
  }
  return user;
}

export async function logout() {
  await axios.post(`${AUTH_BACKEND_URL}/auth/logout`, { withCredentials: true });
  await delSignToken();
  return fetch();
}

export interface LoginPayload {
  email: string;
  password: string;
}

export async function login({ email, password }: LoginPayload) {
  const res = await axios.post(
    `${FUNFUN_URL}/httpOnly/signin`,
    {
      username: email,
      password,
    },
    { withCredentials: true },
  );
  const token = res.data.token;
  await saveSignToken(token);
  return fetch();
}

export enum UserType {
  NORMAL = 'normal',
  ANONYM = 'anonym',
}

export interface RegisterPayload {
  email: string;
  password: string;
  passwordRepeat: string;
  firstApp?: FirstAppType;
  type?: UserType;
}

export enum FirstAppType {
  TEN_STUDIO = '10Studio',
  TEN_STUDIO_WEB = '10StudioWeb',
}

export function getFirstApp(): FirstAppType {
  if (getOfficeHost() === OfficeHost.Excel) {
    return FirstAppType.TEN_STUDIO;
  }
  return FirstAppType.TEN_STUDIO_WEB;
}

export async function registerAnonym() {
  const n = moment().format() + ' ' + Math.floor(Math.random() * 10000);
  const anonymUser = {
    email: n + '@gmail.com',
    password: 'password',
    passwordRepeat: 'password',
    type: UserType.ANONYM,
  };
  return register(anonymUser);
}

export async function register({
  email,
  password,
  passwordRepeat,
  firstApp,
  type,
}: RegisterPayload) {
  const res = await axios.post(
    `${FUNFUN_URL}/httpOnly/register`,
    {
      email,
      password,
      passwordRepeat,
      firstApp: firstApp || getFirstApp(),
      type: type || UserType.NORMAL,
    },
    { withCredentials: true },
  );
  const token = res.data.token;
  await saveSignToken(token);
  return fetch();
}

export function resetPassword(email) {
  return axios.post(
    `${FUNFUN_URL}/httpOnly/reset`,
    {
      email,
    }
  );
}
export function verifyResetPassword({ code, email, password }) {
  return axios.post(
    `${FUNFUN_URL}/httpOnly/reset-verification`,
    {
      code,
      password,
      email,
    }
  );
}

export async function getHeaders() {
  const token = await getSignToken();
  if (token) {
    return { Authorization: 'Bearer ' + token };
  }
  return undefined;
}

export async function getConfig(): Promise<AxiosRequestConfig> {
  return { headers: await getHeaders(), withCredentials: true };
}


export async function getUserPreferences() {
  let response = await axios.get(`${FUNFUN_URL}/httpOnly/users-preferences`, await getConfig());
  return response.data;
}
