import { getOfficeHost, OfficeHost } from "../utils";
import { ISpreadsheetCommunicator } from "./abstract-spreadsheet-communicator";
import { ExcelDesktopSpreadsheetCommunicators } from "./excel-desktop-spreadsheet-communicator";
import { ExcelWebSpreadsheetCommunicators } from "./excel-web-spreadsheet-communicator";
import { GoogleSpreadsheetCommunicators } from "./google-spreadsheet-communicator";
declare var google: any;
export abstract class SpreadsheetCommunicatorFactory {

    static createContextCommunicator() : ISpreadsheetCommunicator{
        if(this.isInGoogle()) return  new GoogleSpreadsheetCommunicators(); 
        if(this.isInExcelWeb()) return new ExcelWebSpreadsheetCommunicators(); 
        if(this.isInExcelDesktop()) return new ExcelDesktopSpreadsheetCommunicators(); 
        throw "not in a spreadsheet";
    }
    static isInExcelDesktop(){
        //@ts-ignore
        return typeof window.chrome?.webview !== "undefined"; 
    }
    static  isInExcelWeb() {
        return getOfficeHost() === OfficeHost.Excel;
    }
    static  isInGoogle() {
        return typeof google !== "undefined";
    }

    
}
