import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';/*
import 'core-js/es6/symbol';
import 'core-js/es6/object';
import 'core-js/es6/function';
import 'core-js/es6/parse-int';
import 'core-js/es6/parse-float';
import 'core-js/es6/number';
import 'core-js/es6/math';
import 'core-js/es6/string';
import 'core-js/es6/date';
import 'core-js/es6/array';
import 'core-js/es6/regexp';
import 'core-js/es6/map';
import 'core-js/es6/weak-map';
import 'core-js/es6/set';*/

import dva from 'dva';
import './index.css';
import router from './router';
import AuthModel from './models/auth';
import SubscribtionModel from './models/subscription';
import AppModel from './models/app';
import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';


function patchOfficeExtension() {
    // https://stackoverflow.com/questions/53327711/how-to-add-a-polyfill-to-support-finally-in-edge
    //@ts-ignore
    OfficeExtension.Promise.prototype.finally = /* Promise.prototype.finally || */ {
        finally(fn: any): any {
            const onFinally = (cb: any) => OfficeExtension.Promise.resolve(fn()).then(cb);
            // @ts-ignore
            return this.then(
                (result: any) => onFinally(() => result),
                (reason: any) => onFinally(() => OfficeExtension.Promise.reject(reason)),
            );
        },
    }.finally;
    // tslint:enable
}

//@ts-ignore
//import createLoading from 'dva-loading';

initializeIcons();

// 1. Initialize
const app = dva();
//app.use(createLoading());

// 2. Plugins
// app.use({});

// 3. Model
//@ts-ignore
app.model(AuthModel);
app.model(SubscribtionModel)
app.model(AppModel);
// 4. Router
//@ts-ignore
app.router(router);

// 5. Start
app.start('#root');

