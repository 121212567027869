import React, { ChangeEvent } from 'react';
import * as monacoEditor from 'monaco-editor/esm/vs/editor/editor.api';
import { Toggle } from 'office-ui-fabric-react/lib/Toggle';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Label } from 'office-ui-fabric-react/lib/Label';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { Dropdown, IDropdownOption } from 'office-ui-fabric-react/lib/Dropdown';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';

import ResizableMonacoEditor from '../EditorPlain/editorPlain';
import { getOfficeHost, OfficeHost } from '../../utils';
import materialTheme from './theme';
import DiscreteSlider from '../DiscreteSlider';
import { isUndefined } from 'util';
import style from './index.module.css';
import * as kpiServices from '../../services/kpi';
import { State as ReduxState } from '../../store/reducer';
import { connect } from 'dva';
import selectors from '../../selectors';
import { SpreadsheetCommunicatorFactory } from '../../communicators/spreadsheet-communicator.factory';
import { ISpreadsheetCommunicator } from '../../communicators/abstract-spreadsheet-communicator';

declare var google;
const fontSizeOptions: IDropdownOption[] = [8, 9, 10, 11, 12, 13, 14, 15, 16, 18, 20, 22, 24].map(
  i => ({ key: i, text: i.toString() }),
);

const monacoOptions: monacoEditor.editor.IEditorConstructionOptions = {
  lineNumbers: 'off',
  glyphMargin: false, //left side,
  lineDecorationsWidth: 0, // width between line number and content,
  renderIndentGuides: false, // no indent guide lines
  minimap: { enabled: false },
};

const sliderValues = [30, 40, 50, 60, 70, 80, 100, 160];

const sliderMasks = sliderValues.map(i => ({
  value: i,
  label: i.toString(),
}));

const StyledSlider = withStyles({
  root: { margin: '0 12px 0 8px' },
})(DiscreteSlider);

interface IFomulaEditorProps {
  placeholder: string;
  app: string;
  uid: string
}

interface IFomulaEditorState {
  code: string;
  enabled: boolean;
  widthLimit: number;
  fontSize: number;
  autoWidthLimit: boolean;
  waiting: boolean;
  screenHeight?: number;
}

class PrettyFormula extends React.Component<IFomulaEditorProps, IFomulaEditorState> {
  static defaultProps = {
    placeholder: 'Toggle above to activate,\nthen select cells...',
  };

  communicator?:ISpreadsheetCommunicator;
  constructor(props) {
    super(props);
    try {
      this.communicator = SpreadsheetCommunicatorFactory.createContextCommunicator();
    } catch (ex) {

    }
  }

  shortcutKey(){
  
    this.format(); 
  }

  listened: boolean = false;

  readonly state = {
    code: '',
    enabled: false,
    autoWidthLimit: true,
    widthLimit: 80,
    fontSize: 16,
    waiting: false,
    screenHeight: undefined,
  };



  componentDidMount() {
    this.listenCells();
    this.setState({ screenHeight: window.innerHeight });
    window.addEventListener('size', () => {
      this.setState({ screenHeight: window.innerHeight });
    });    
  }

  editorWillMount = (monaco: typeof monacoEditor) => {
    monaco.editor.defineTheme('vs-grey', {
      base: 'vs',
      inherit: true,
      rules: [],
      colors: { 'editor.background': '#eaeaea' },
    });
  };

  listenCells = () => {
    if (this.listened) {
      return;
    }
    if (!this.communicator) return;
    if (this.communicator.addOnSelectionChangeHandler(this.onCellChange.bind(this))) {
      this.listened = true;
    }

  };

  readFormula = () => {
    this.setState({ waiting: true });
    if (this.state.enabled) {

      this.communicator?.read_formula("A1")
        .then((x: string) => {
          // tslint:disable-next-line: no-console
          if (typeof x == 'string' && x.substring(0, 1) == '=') {
            this.format(x);
          } else {
            this.code = x.toString();
          }
          this.setState({ waiting: false });
          // tslint:disable-next-line: no-console
          console.log('done');
        })
        .catch(error => {
          this.setState({ waiting: false });
          // tslint:disable-next-line: no-console
          console.log('Error: ' + error);
        });
    }
  };

  onCellChange = () => {
    this.readFormula();
  };

  set code(newValue: string) {
    this.setState({ code: newValue });
  }

  get code(): string {
    if (this.state.enabled) {
      return this.state.code;
    } else {
      return this.props.placeholder;
    }
  }

  get activateStatusText(): string {
    return this.state.enabled ? 'On' : 'Off';
  }

  set widthLimit(value: number | undefined) {
    if (value !== undefined) {
      const oldValue = this.state.widthLimit;
      if (oldValue !== value) {
        this.setState({ widthLimit: value }, this.format);
      }
    }
  }

  get widthLimit(): number | undefined {
    if (this.state.autoWidthLimit) {
      return undefined;
    }
    return this.state.widthLimit;
  }

  // Tie: 20210907: it seems that the following function is not called; functions in LSP are called.
  _formatFormula = (formula: string, widthLimit: number | undefined): string => {
    if (formula[0] === '=') {
      let code = formatFormulaWrap(formula.slice(1), {}, widthLimit); // Tie: call formatFormula_wrap
      let lines = code.split('\n');
      lines = lines.map((line, i) => (i === 0 ? '= ' + line : '  ' + line));
      return lines.join('\n');
    } else {
      return formatFormulaWrap(formula, {}, widthLimit); // Tie: call formatFormula_wrap
    }
  };

  // Tie: auxiliary, can be renamed to "formatFormula_ts"
  formatFormula = (formula: string) => {
    formula = formula.trimLeft();
    let widthLimit = this.state.widthLimit;
    if (this.state.autoWidthLimit) {
      widthLimit = getFormatWidthLimit(formula, 40);
      console.log(`getFormatWidthLimit: ${widthLimit}`)

      const action: any = { theType: 'getFormatWidthLimit', getFormatWidthLimit: {} }
      action.getFormatWidthLimit.formulaInitial = formula;
      action.getFormatWidthLimit.base = 40
      action.getFormatWidthLimit.widthLimitResult = widthLimit
      kpiServices.post({
        uid: this.props.uid,
        app: this.props.app === "pretty-formula" ? "pretty-formula-addin" : this.props.app,
        url: "",
        action: action
      })
    }
    const action: any = { theType: 'formatFormula', formatFormula: {} }
    try {
      action.formatFormula.formulaInitial = formula;
      action.formatFormula.widthLimitInput = widthLimit;
      var formulaR = this._formatFormula(formula, widthLimit);
    } catch (e) {
      // when calling OCaml function failed, set code to raw formula
      this.setState({ code: formula }, function (this: any) {
        console.log(e.toString())
        action.formatFormula.formulaResult = formulaR;
        action.formatFormula.status = "failed";
        action.formatFormula.errorMessage = e.toString();
        kpiServices.post({
          uid: this.props.uid,
          app: this.props.app === "pretty-formula" ? "pretty-formula-addin" : this.props.app,
          url: "",
          action: action
        })
      });
      throw e;
    }

    if (widthLimit != this.state.widthLimit) {
      this.setState({ code: formulaR, widthLimit }, function () {
        action.formatFormula.formulaResult = formulaR;
        action.formatFormula.status = "successful";
      });
    } else {
      this.setState({ code: formulaR }, function () {
        action.formatFormula.formulaResult = formulaR;
        action.formatFormula.status = "successful";
      });
    }

    kpiServices.post({
      uid: this.props.uid,
      app: this.props.app === "pretty-formula" ? "pretty-formula-addin" : this.props.app,
      url: "",
      action: action
    });
  };

  unformatFormula(formula: string): string {
    formula = formula.trimLeft();
    if (formula[0] === '=') {
      return '= ' + unformatFormula(formula.slice(1));
    } else {
      return unformatFormula(formula);
    }
  }

  format = (formula?: string) => {
    if (this.state.enabled) {
      formula = formula ? formula : this.state.code;
      this.formatFormula(formula); // Tie: call formatFormula_ts
      this.editor?.revealPosition({lineNumber : 1 , column : 1}); 

    }
  };

  unformat = () => {
    if (this.state.enabled) {
      this.code = this.unformatFormula(this.state.code);
    }
  };

  write = () => {
    this.setState({ waiting: true });

    this.communicator?.write_formula(this.state.code, "A1")
      .then(() => {
        this.setState({ waiting: false });
        // tslint:disable-next-line: no-console
        console.log('done');
      })
      .catch(error => {
        this.setState({ waiting: false });
        // tslint:disable-next-line: no-console
        console.log('Error: ' + error);
      });
  };

  onToggleActivate = (_ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
    this.listenCells();
    this.setState({ enabled: checked === undefined ? false : checked }, this.readFormula);
  };

  onClickAutoWidthLimit = (
    _ev: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
    checked?: boolean,
  ) => {
    this.setState({ autoWidthLimit: checked === undefined ? true : checked }, this.format);
  };

  onClickUnformatAndWrite = () => {
    if (this.state.enabled) {
      this.unformat();

      this.write();

    }
  };

  onWidthLimitChange = (_ev: ChangeEvent<{}>, value: number | number[]) => {
    if (typeof value === 'number') {
      this.widthLimit = value;
    } else {
      this.widthLimit = value[0];
    }
  };

  onCodeChange = (newCode: string) => {
    if (this.state.enabled) {
      this.code = newCode;
    }
  };
  editor?:monacoEditor.editor.IStandaloneCodeEditor; 
  handleEditorDidMount(editor:monacoEditor.editor.IStandaloneCodeEditor, monaco) {
    this.editor = editor;
    
    //@ts-ignore
    this.editor._standaloneKeybindingService.addDynamicKeybinding('foo-command' ,  monaco.KeyMod.Alt | monaco.KeyMod.Shift     | monaco.KeyCode.KEY_F  , this.shortcutKey.bind(this)); // alt + shift + F is default shortkey for formatting in monaco-editor 
    
  }
  onFontSizeChange = (_ev: object, option: IDropdownOption | undefined) => {
    if (!isUndefined(option)) {
      if (typeof option.key === 'number') {
        this.setState({ fontSize: option.key });
      }
    }
  };

  render() {
    const code = this.code;
    const monacoTheme = this.state.enabled ? 'vs' : 'vs-grey';
    const finalMonacoOptions = {
      ...monacoOptions,
      readOnly: !this.state.enabled,
      fontSize: this.state.fontSize,
    };
    const widthLimit = this.state.widthLimit;
    const hGap = 5;
    const editorVh = 40;
    const contentTotalHeight = 40 + 31 + 2 * 32 + 31 + 31 + 24 + 31 + 32 + 20; // Header + Toggle + 2XButton + Tittle + Toggle + Slider + Tittle + Drapdown + 20
    console.log(contentTotalHeight);
    const screenHeight = this.state.screenHeight;
    console.log(screenHeight);
    let vGap = screenHeight
      ? ((screenHeight * (100 - editorVh)) / 100 - contentTotalHeight) / 22
      : 5;
    vGap = Math.max(Math.min(vGap, 6), 2);
    console.log(vGap);
    const bottonCommonStyles = {
      margin: `${2 * vGap}px ${hGap}px 0px ${hGap}px`, // 2 * vGap * 2 * 2 = 8vGap
      flex: '1 0 0px',
    };
    return (
      /* tslint:disable:jsx-no-multiline-js */
      <MuiThemeProvider theme={materialTheme}>
        <div className="content" style={{ minWidth: '320px' }}>
          <Stack
            tokens={{ childrenGap: 2 * vGap } /* 2 * vGap * 2 = 4vGap */}
            styles={{ root: { marginTop: `${2 * vGap}px` } } /* 2vGap */}
          >
            <Toggle
              styles={{ root: { margin: `0 ${hGap}px`, maxWidth: '590px' } }}
              onChange={this.onToggleActivate}
              label={this.activateStatusText}
              inlineLabel={true}
            />
            <div>
              <div style={{ margin: `0 ${hGap}px` }}>
                <ResizableMonacoEditor
                  height={`${editorVh}vh`}
                  language="javascript"
                  theme={monacoTheme}
                  value={code}
                  options={finalMonacoOptions}
                  onChange={this.onCodeChange}
                  editorDidMount={this.handleEditorDidMount.bind(this)}
                  editorWillMount={this.editorWillMount}                  
                />
              </div>
            </div>
            <div
              className="content-control"
              style={{
                minWidth: '320px',
                maxWidth: '640px',
              }}
            >
              <div
                className="content-buttons"
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
              >
                <div
                  style={{
                    display: 'inline-flex',
                    flex: '1 0 0px',
                    minWidth: '320px',
                    maxWidth: '350px',
                  }}
                > 
                  <PrimaryButton
                    styles={{
                      root: {
                        ...bottonCommonStyles,
                        minWidth: '145px',
                        maxWidth: '165px',
                      },
                    }}
                    allowDisabledFocus={true}
                    text="Format"
                    // tslint:disable-next-line: jsx-no-lambda
                    onClick={() => this.format()}
                  />
                  <PrimaryButton
                    styles={{
                      root: {
                        ...bottonCommonStyles,
                        minWidth: '145px',
                        maxWidth: '165px',
                      },
                    }}
                    allowDisabledFocus={true}
                    text="Unformat"
                    onClick={this.unformat}
                  />
                </div>
                <PrimaryButton
                  styles={{
                    root: {
                      ...bottonCommonStyles,
                      minWidth: '310px',
                      maxWidth: '340px',
                    },
                  }}
                  allowDisabledFocus={true}
                  text="Unformat & Write to Cell"
                  onClick={this.onClickUnformatAndWrite}
                />
              </div>
              <Stack horizontal={true} wrap={true} styles={{ inner: { margin: 0 } }}>
                <div
                  style={{
                    flex: '1 0 0px',
                    minWidth: '310px',
                    maxWidth: '340px',
                    margin: `${3 * vGap}px ${hGap}px`, // 3vGap
                  }}
                >
                  <hr className={style.hr} />
                  <Label>Width Limit:</Label>
                  <Toggle
                    onChange={this.onClickAutoWidthLimit}
                    checked={this.state.autoWidthLimit}
                    label="Auto"
                    inlineLabel={true}
                  />
                  <div style={{ display: 'flex', alignItems: 'flex-start' }}>

                    <StyledSlider
                      defaultValue={widthLimit}
                      value={widthLimit}
                      // tslint:disable:jsx-no-lambda
                      getAriaValueText={_ => widthLimit.toString()}
                      aria-labelledby="discrete-slider-custom"
                      values={sliderValues}
                      disabled={this.state.autoWidthLimit}
                      valueLabelDisplay="off"
                      // @ts-ignore 
                      onChange={this.onWidthLimitChange}
                      marks={sliderMasks}
                    />
                  </div>
                </div>
                <div
                  style={{
                    flex: '1 0 0px',
                    minWidth: '310px',
                    maxWidth: '340px',
                    margin: `${3 * vGap}px ${hGap}px`, // 3vGap
                  }}
                >
                  <hr className={style.hr} />
                  <Dropdown
                    label="Font Size:"
                    options={fontSizeOptions}
                    defaultSelectedKey={this.state.fontSize}
                    styles={{ label: { paddingTop: `${vGap}px`, paddingBottom: `${vGap}px` } }} // 1vGap
                    onChange={this.onFontSizeChange}
                  />
                </div>
              </Stack>
            </div>
          </Stack>
        </div>
      </MuiThemeProvider>
      /* tslint:enable:jsx-no-multiline-js */
    );
  }
}

export default connect((state: ReduxState) => ({
  app: selectors.app.selectAppName(state),
  uid: selectors.auth.getUid(state)
}))(PrettyFormula);