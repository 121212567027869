import React from 'react';

import { connect } from 'dva';
import { Dispatch } from 'redux';
import { push } from 'react-router-redux';
import _ from 'lodash';

import { Card } from '@uifabric/react-cards';
import { PrimaryButton, Spinner, Stack, Text, ITextProps } from 'office-ui-fabric-react';
import {
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  SelectionMode,
} from 'office-ui-fabric-react/lib/DetailsList';

import { State as ReduxState } from '../../../store/reducer';
import selectors from '../../../selectors';
import { Subscription, Invoice, Event } from '../../../services/subscription';
import { getAppSubscribePage } from '../../../services/app-utils';

import {
  getMoneyText,
  getPlanProductName,
  getDatetimeText,
  getPlanPrice,
  getPlanProductItems,
  getIsSubscriptionActive,
} from '../utils';
import { MainText, ContentCard, TitleText } from '../common';

export interface PlansSubscriptionProps {
  dispatch: Dispatch<any>;
  loading: boolean;
  error?: Error;
  subscription?: Subscription;
  trial?: Subscription;
  invoices?: Invoice[];
  upcomingInvoice: Invoice;
  events: Event[];
  app: string;
}

interface EventListItem {
  text: string;
  datetime: string;
}

function titleCaseWord(word: string) {
  if (!word) return word;
  return word[0].toUpperCase() + word.substr(1).toLowerCase();
}

export function getEventText(event: Event): EventListItem {
  let text, datetime: string;
  switch (event.type) {
    case 'invoice.created':
      text = 'System created a draft billing';
      break;
    case 'invoice.finalized':
      text = `Received a billing of ${getMoneyText(
        event.data.object.amount_due,
        event.data.object.currency,
      )}`;
      break;
    case 'invoice.payment_succeeded':
      text = `Paid a billing of ${getMoneyText(
        event.data.object.amount_due,
        event.data.object.currency,
      )}`;
      break;
    case 'invoiceitem.created':
      text = `Received a billing of ${getMoneyText(
        event.data.object.amount,
        event.data.object.currency,
      )} for changing plan`;
      break;
    case 'customer.subscription.created':
      text = `Subsribed plan: ${event.data.object.items.data
        .map((x: any) => getPlanProductName(x.plan))
        .join(',')}`;
      break;
    case 'customer.subscription.updated':
      text = `Change plan from ${event.data.previous_attributes.items.data
        .map((x: any) => getPlanProductName(x.plan))
        .join(',')} to ${event.data.object.items.data
          .map((x: any) => getPlanProductName(x.plan))
          .join(',')}`;
      break;
    case 'customer.subscription.deleted':
      text = 'Subscription has been canceled.';
      break;
    default:
      text = 'unknown type of history event';
  }
  datetime = getDatetimeText(event.created);
  return {
    text,
    datetime,
  };
}

const BorderedTitleText: React.StatelessComponent<ITextProps> = props => {
  const style = props.style;
  return (
    <TitleText style={{ borderBottom: '1px solid rgb(234, 234, 234)', ...style }}>
      {props.children}
    </TitleText>
  );
};

class MySubscription extends React.Component<PlansSubscriptionProps> {
  onClick = () => {
    
    this.props.dispatch(push(`/${getAppSubscribePage(this.props.app)}`));
  };

  private _plansColumns: IColumn[] = [
    {
      key: 'column1',
      name: 'Key',
      fieldName: 'key',
      minWidth: 105,
      maxWidth: 105,
      onRender: (x: any) => <MainText>{x.key}</MainText>,
    },
    {
      key: 'column2',
      name: 'Value',
      fieldName: 'value',
      minWidth: 100,
      maxWidth: 175,
      isResizable: true,
      onRender: (x: any) => <MainText>{x.value}</MainText>,
    },
  ];

  private _nextBillingColumns = [
    {
      key: 'column1',
      name: 'Key',
      fieldName: 'key',
      minWidth: 105,
      maxWidth: 105,
      onRender: (x: any) => <MainText>{x.key}</MainText>,
    },
    {
      key: 'column2',
      name: 'Value',
      fieldName: 'value',
      minWidth: 100,
      maxWidth: 175,
      isResizable: true,
      onRender: (x: any) => <MainText>{x.value}</MainText>,
    },
  ];

  private _eventsColumns: IColumn[] = [
    {
      key: 'column1',
      name: 'Time',
      fieldName: 'datetime',
      minWidth: 105,
      maxWidth: 105,
      onRender: (x: EventListItem) => <MainText>{x.datetime}</MainText>,
    },
    {
      key: 'column2',
      name: 'Event',
      fieldName: 'text',
      minWidth: 100,
      maxWidth: 175,
      isResizable: true,
      onRender: (x: EventListItem) => <MainText>{x.text}</MainText>,
    },
  ];

  renderTrial = (trial: Subscription) => {
    const items = [
      { key: 'name', value: getPlanProductName(trial.plan) },
      { key: 'created date', value: getDatetimeText(trial.created) },
      { key: 'expired date', value: getDatetimeText(trial.cancel_at) },
      { key: 'status', value: getIsSubscriptionActive(trial) ? 'Active' : 'Expired' },
    ];
    return (
      <DetailsList
        key={trial.id}
        compact={true}
        isHeaderVisible={false}
        items={items}
        columns={this._plansColumns}
        selectionMode={SelectionMode.none}
        setKey="none"
        layoutMode={DetailsListLayoutMode.justified}
      ></DetailsList>
    );
  };

  renderSubPlans = (subscription: Subscription) => {
    const subItems: any[] = subscription.items.data;
    return subItems.map(item => {
      const plan = item.plan;
      const items = [
        { key: 'name', value: getPlanProductName(plan) },
        {
          key: 'price',
          value: getPlanPrice(plan),
        },
        { key: 'created date', value: getDatetimeText(item.created) },
      ];
      return (
        <DetailsList
          key={plan.id}
          compact={true}
          isHeaderVisible={false}
          items={items}
          columns={this._plansColumns}
          selectionMode={SelectionMode.none}
          setKey="none"
          layoutMode={DetailsListLayoutMode.justified}
        ></DetailsList>
      );
    });
  };

  renderSubPlanItems = (subscription: Subscription) => {
    const subItems: any[] = subscription.items.data;
    const items = _.uniqBy(
      _.concat(
        [],
        ...subItems.map(item => getPlanProductItems(getPlanProductName(item.plan), true)),
      ),
      'name',
    );
    return (
      <Stack
        horizontal
        wrap
        horizontalAlign="baseline"
        tokens={{ childrenGap: 5 }}
        styles={{ root: { padding: 10, borderTop: '1px solid rgb(234, 234, 234)' } }}
      >
        {items.map(item => (
          <Stack.Item key={item.name}>
            <Stack horizontalAlign="center" styles={{ root: { width: 85 } }}>
              <img src={item.iconName} />
              <MainText variant="tiny">{item.name}</MainText>
            </Stack>
          </Stack.Item>
        ))}
      </Stack>
    );
  };

  renderUpcomingInvoice = (upcomingInvoice?: Invoice) => {
    const items = upcomingInvoice
      ? [
        { key: 'next billing date', value: getDatetimeText(upcomingInvoice.date) },
        {
          key: 'next billing amount',
          value: getMoneyText(upcomingInvoice.amount_due, upcomingInvoice.currency),
        },
      ]
      : [
        { key: 'next billing date', value: 'NA' },
        {
          key: 'next billing amount',
          value: 'NA',
        },
      ];
    return (
      <DetailsList
        compact={true}
        isHeaderVisible={false}
        items={items}
        columns={this._nextBillingColumns}
        selectionMode={SelectionMode.none}
        setKey="none"
        layoutMode={DetailsListLayoutMode.justified}
      ></DetailsList>
    );
  };

  renderEvents = (events: Event[]) => {
    return (
      <DetailsList
        compact={true}
        isHeaderVisible={false}
        items={events.map(getEventText)}
        columns={this._eventsColumns}
        selectionMode={SelectionMode.none}
        setKey="none"
        layoutMode={DetailsListLayoutMode.justified}
      ></DetailsList>
    );
  };

  render() {
    const { loading, error, subscription, invoices, upcomingInvoice, trial, events } = this.props;
    if (loading) {
      return (
        <ContentCard>
          <Card.Item>
            <Spinner />
          </Card.Item>
        </ContentCard>
      );
    }
    if (error) {
      return (
        <ContentCard>
          <Card.Item>
            <Text>{error.message}</Text>
          </Card.Item>
          <Card.Item>
            <PrimaryButton>Reload</PrimaryButton>
          </Card.Item>
        </ContentCard>
      );
    }
    const cardFooter = (
      <Card.Item>
        <PrimaryButton style={{ width: '100%' }} onClick={this.onClick}>
          Go Subscribe
        </PrimaryButton>
      </Card.Item>
    );
    if (subscription === undefined) {
      if (trial) {
        return (
          <ContentCard>
            <Card.Item>
              <div>
                <BorderedTitleText>Subscription</BorderedTitleText>
                {this.renderTrial(trial)}
              </div>
            </Card.Item>
            {cardFooter}
          </ContentCard>
        );
      }
      return (
        <ContentCard>
          <Card.Item>You have no subscription now.</Card.Item>
          {cardFooter}
        </ContentCard>
      );
    }
    return (
      <>
        <ContentCard>
          <Card.Item>
            <div>
              <BorderedTitleText>Subscription</BorderedTitleText>
              {this.renderSubPlans(subscription)}
            </div>
          </Card.Item>
          <Card.Item>
            <div>
              <BorderedTitleText>Status</BorderedTitleText>
              <DetailsList
                compact={true}
                isHeaderVisible={false}
                items={[subscription]}
                columns={[
                  {
                    key: 'column1',
                    name: 'status',
                    fieldName: 'status',
                    minWidth: 100,
                    onRender: (x: any) => <MainText>{titleCaseWord(x.status)}</MainText>,
                  },
                ]}
                selectionMode={SelectionMode.none}
                setKey="none"
                layoutMode={DetailsListLayoutMode.justified}
              ></DetailsList>
            </div>
          </Card.Item>
          <Card.Item>
            <div>
              <BorderedTitleText>Billing</BorderedTitleText>
              {this.renderUpcomingInvoice(upcomingInvoice)}
            </div>
          </Card.Item>
          <Card.Item>
            <div>
              <BorderedTitleText>Items</BorderedTitleText>
              {this.renderSubPlanItems(subscription)}
            </div>
          </Card.Item>
          <Card.Item>
            <div>
              <BorderedTitleText>History</BorderedTitleText>
              {this.renderEvents(events)}
            </div>
          </Card.Item>
          {getIsSubscriptionActive(subscription) ? (
            <Card.Item>
              <div>
                <BorderedTitleText>Change or Cancel</BorderedTitleText>
                <MainText style={{ padding: '10px 5px' }}>
                  If you want to change or cancel your plan, please send email to{' '}
                  <a href="mailto:chengtie@gmail.com">chengtie@gmail.com</a>.
                </MainText>
              </div>
            </Card.Item>
          ) : (
              cardFooter
            )}
        </ContentCard>

      </>
    );
  }
}

export default connect((state: ReduxState) => ({
  loading:
    selectors.subscription.getIsSubscriptionsLoading(state) ||
    selectors.subscription.getIsSubscriptionDetailLoading(state),
  error:
    selectors.subscription.getSubscriptionsError(state) ||
    selectors.subscription.getSubscriptionDetailError(state),
  subscription: selectors.subscription.getSubscription(state),
  trial: selectors.subscription.getTrial(state),
  invoices: selectors.subscription.getInvoices(state),
  upcomingInvoice: selectors.subscription.getUpcomingInvoice(state),
  events: selectors.subscription.getEvents(state),
  app: selectors.app.selectAppName(state)
}))(MySubscription);
