import { Model } from 'dva';
import { DvaModelBuilder, actionCreatorFactory, removeActionNamespace } from 'dva-model-creator';
import * as subscriptionService from '../services/subscription';

interface BaseModel {
  loaded: boolean;
  error?: Error;
}

type PlansData = subscriptionService.Plan[];

interface PlansState extends BaseModel {
  data?: PlansData;
}

interface SubscriptionsData {
  subscriptions: subscriptionService.Subscription[];
  trial: subscriptionService.Subscription;
}

interface SubscriptionsState extends BaseModel {
  data?: SubscriptionsData;
}

interface SubscriptionDetailData {
  invoices?: subscriptionService.Invoice[];
  upcomingInvoice?: subscriptionService.Invoice;
  events?: subscriptionService.Event[];
}

interface SubscriptionDetailState extends BaseModel {
  data?: SubscriptionDetailData;
}

export interface State {
  plans: PlansState;
  subscriptions: SubscriptionsState;
  subscriptionDetail: SubscriptionDetailState;
  subscribingError?: Error;
  subscribing?: boolean;
  updatingSubscription?: boolean;

}

//namespace
const namespace = 'subscription';
const actionCreator = actionCreatorFactory(undefined);

//reducers
const savePlans = actionCreator<PlansData>('savePlans');
const savePlansError = actionCreator<Error>('savePlansError');
const clearPlans = actionCreator<{}>('clearPlans');
const saveSubscriptions = actionCreator<SubscriptionsData>('saveSubscriptions');
const saveSubscriptionsError = actionCreator<Error>('saveSubscriptionsError');
const clearSubscriptions = actionCreator<{}>('clearSubscriptions');
const saveSubscriptionDetail = actionCreator<SubscriptionDetailData>('saveSubscriptionDetail');
const saveSubscriptionDetailError = actionCreator<Error>('saveSubscriptionDetailError');
const clearSubscriptionDetail = actionCreator<{}>('clearSubscriptionDetail');
const saveSubscribingError = actionCreator<Error>('saveSubscribingError');
const clearSubscribingError = actionCreator<{}>('clearSubscribingError');
const setSubscribing = actionCreator<{}>('setSubscribing');
const setUpdatingSubscription = actionCreator<{}>('setUpdatingSubscription');
//effects
const subscribe = actionCreator<subscriptionService.SubscriptionCreatePayload>('subscribe');
const updatePlans = actionCreator<{}>('updatePlans');
const updateSubscriptions = actionCreator<{}>('updateSubscriptions');
const updateSubscriptionDetail = actionCreator<subscriptionService.Subscription | undefined>(
  'updateSubscriptionDetail',
);
const fetchPlans = actionCreator<{}>('fetchPlans');
const fetchSubscriptions = actionCreator<{}>('fetchSubscriptions');
const fetchSubscriptionDetail = actionCreator<subscriptionService.Subscription | undefined>(
  'fetchSubscriptionDetail',
);

const model = new DvaModelBuilder<State>(
  {
    plans: {
      loaded: false,
    },
    subscriptions: {
      loaded: false,
    },
    subscriptionDetail: {
      loaded: false,
    },
  },
  namespace,
)
  .immer(savePlans, (state, payload) => {
    return { ...state, plans: { data: payload, loaded: true } };
  })
  .immer(savePlansError, (state, payload) => {
    return { ...state, plans: { error: payload, loaded: true } };
  })
  .immer(clearPlans, (state, _) => {
    return { ...state, plans: { loaded: false } };
  })
  .immer(saveSubscriptions, (state, payload) => {
    return { ...state, subscriptions: { data: payload, loaded: true } };
  })
  .immer(saveSubscriptionsError, (state, payload) => {
    return { ...state, subscriptions: { error: payload, loaded: true } };
  })
  .immer(clearSubscriptions, (state, _) => {
    return { ...state, subscriptions: { loaded: false } };
  })
  .immer(saveSubscriptionDetail, (state, payload) => {
    return { ...state, subscriptionDetail: { data: payload, loaded: true } };
  })
  .immer(saveSubscriptionDetailError, (state, payload) => {
    return { ...state, subscriptionDetail: { error: payload, loaded: true } };
  })
  .immer(clearSubscriptionDetail, (state, _) => {
    return { ...state, subscriptionDetail: { loaded: false } };
  })
  .immer(saveSubscribingError, (state, payload) => {
    return { ...state, subscribingError: payload };
  })
  .immer(clearSubscribingError, (state, _) => {
    return { ...state, subscribingError: undefined };
  })
  .immer(setSubscribing, (state, payload) => {
    return { ...state, subscribing: payload }
  })
  .immer(setUpdatingSubscription, (state, payload) => {
    return { ...state, updatingSubscription: payload }
  })
  .takeEvery(subscribe, function* (payload, { call, put }) {
    try {
      yield put.resolve(setSubscribing(true))
      yield put.resolve(clearSubscribingError({}));
      yield call(subscriptionService.create, { ...payload });
      yield put.resolve(clearSubscriptionDetail({}));
      yield put.resolve(clearSubscriptions({}));
      yield put.resolve(updateSubscriptions({}));
    } catch (e) {
      console.log(e);
      yield put.resolve(saveSubscribingError(e));
    } finally {
      yield put.resolve(setSubscribing(false));
    }
  })
  .takeEvery(updatePlans, function* (_, { call, put }) {
    try {
      yield put.resolve(clearPlans({}));
      const plans = yield call(subscriptionService.fetchPlans);
      yield put.resolve(savePlans(plans));
    } catch (e) {
      console.log(e);
      yield put.resolve(savePlansError(e));
    }
  })
  .takeEvery(updateSubscriptions, function* (_, { call, put, select }) {
    try {
      yield put.resolve(setUpdatingSubscription(true));
      yield put.resolve(clearSubscriptions({}));
      let app = yield select(({ app: { name } }) => name);
      const subscriptions = yield call(subscriptionService.fetch);
      const trial = yield call(subscriptionService.fetchTrial, app);
      yield put.resolve(saveSubscriptions({ subscriptions, trial }));
    } catch (e) {
      console.log(e);
      yield put.resolve(saveSubscriptionsError(e));
    } finally {
      yield put.resolve(setUpdatingSubscription(false));
    }
  })
  .takeEvery(updateSubscriptionDetail, function* (subscription, { call, put, select }) {
    try {
      yield put.resolve(clearSubscriptionDetail({}));
      if (subscription === undefined) {
        const subscriptions: SubscriptionsState = yield select(
          ({ subscription: { subscriptions } }: { subscription: State }) => subscriptions,
        );
        if (subscriptions.loaded && subscriptions.data) {
          console.log(subscriptions.data);
          subscription = subscriptions.data.subscriptions[0] || subscriptions.data.trial;
        }
      }
      if (subscription === undefined) {
        throw new Error('Subscriptions should be loaded first before the subscription detail');
      }
      const invoices = yield call(subscriptionService.fetchInvoices, subscription.id);
      const events = yield call(subscriptionService.fetchSubscriptionEvents, subscription.id);
      let upcomingInvoice: subscriptionService.Invoice | undefined;
      if (subscription.status === 'active') {
        upcomingInvoice = yield call(subscriptionService.fetchUpcomingInvoice, subscription.id);
      } else {
        upcomingInvoice = undefined;
      }
      yield put.resolve(saveSubscriptionDetail({ invoices, upcomingInvoice, events }));
    } catch (e) {
      console.log(e);
      yield put.resolve(saveSubscriptionDetailError(e));
    }
  })
  .takeEvery(fetchPlans, function* (_, { put, select }) {
    console.log('fetchPlans');
    const plans: PlansState = yield select(
      ({ subscription: { plans } }: { subscription: State }) => plans,
    );
    if (!plans.loaded || plans.error) {
      yield put(updatePlans({}));
    }
  })
  .takeEvery(fetchSubscriptions, function* (_, { put, select }) {
    const subscriptions: SubscriptionsState = yield select(
      ({ subscription: { subscriptions } }: { subscription: State }) => subscriptions,
    );
    if (!subscriptions.loaded || subscriptions.error) {
      yield put.resolve(updateSubscriptions({}));
    }
  })
  .takeEvery(fetchSubscriptionDetail, function* (subscription, { put, select }) {
    yield put.resolve(fetchSubscriptions({}));
    if (subscription !== undefined) {
      yield put.resolve(updateSubscriptionDetail(subscription));
    } else {
      // make sure subscriptions has been loaded
      const subscriptionDetail: SubscriptionDetailState = yield select(
        ({ subscription: { subscriptionDetail } }: { subscription: State }) => subscriptionDetail,
      );
      if (!subscriptionDetail.loaded || subscriptionDetail.error) {
        yield put.resolve(updateSubscriptionDetail(undefined));
      }
    }
  })
  .subscript(({ dispatch, history }) => {
    return history.listen(({ pathname }) => {
      dispatch({ type: 'app/updateApp', payload: history });

      if (pathname == "/" || pathname == "/sign" || pathname == "/welcome") return;

      dispatch(removeActionNamespace(fetchSubscriptions({})));
      if (pathname === '/my-subscription') {
        dispatch(removeActionNamespace(fetchSubscriptionDetail(undefined)));
      }
    });
  })
  .build();

//tslint:disable-next-line:no-default-export
export default model as Model;
