
const rules = require("./rules.json").rules;

export const infoFromResult = (r: any) => {
    let m = "`" + r.original.substring(1) + "` can be replaced by " + "`" + r.optimized + "`"
    let x: any = {};
    if (rules[r.rule]) {
        x = { ...rules[r.rule] };
        x.contentMarkdown = m + x.contentMarkdown + ` (${x.code})`;
        x.optimized = r.optimized;
        x.quickFix = "Replace " + r.original.substring(1) + " by " + r.optimized + `. (${x.code})`;
    } else {
        x = { ...rules["default"] };
        x.optimized = "default optimized";
        x.quickFix = "default quickFix " + `. (${x.code})`;
        x.diagnosticMessage = r.rule;
    }
    console.log("info", x);
    return x
}

// let message = `## Suggestion 
//  You can replace VLOOKUP with INEDX for better performance click the link for more information  \n
//  \n[Google](https://www.google.com) 
//  🔓🔓` ;