import { TextEdit, Position, TextDocument, FormattingOptions, Range, Diagnostic, CodeAction, CancellationToken, TextDocumentEdit, WorkspaceEdit, Command } from 'vscode-languageserver-protocol';
import * as kpiServices from '../../services/kpi';
import * as monaco from 'monaco-editor';
import * as utilities from './utilities'
import { AppName } from '../../models/app';
declare var getFormatWidthLimit, formatFormula;

function kpiServiceLog(data){
	//@ts-ignore
	if(window.__$$InGoogle) {
		data.app = AppName.GoogleSheetFormulaEditor; 
		return ; 
	}else {
		data.app = AppName.FormulaEditor; 
	}

	//@ts-ignore
	kpiServices.post(data); 
}
export class FormulaLanguageService {
	currentModelVersionIdProvideDocumentRangeFormattingEdits: any;
	currentModelVersionIdProvideHover: any;
	currentModelVersionIdProvideCodeActions: any;
	widthLimit: any;
	autoWidthLimit: any;
	formulaStyle: any;
	numberDecimalSeparator: any;
	displayLanguage: any;
	contentLanguage: any;

	constructor() {
		this.widthLimit = undefined
		this.autoWidthLimit = undefined
		console.log(`widthLimit in constructor: ${this.widthLimit}`)
		console.log(`autoWidthLimit in constructor: ${this.autoWidthLimit}`)
	}

	_formatFormula(formula, widthLimit) {
		// console.log("_formatFormula");
		// console.log(JSON.stringify(formula));
		if (formula[0] === '=') {
			console.log("widthLimit: ", widthLimit);
			console.log(this.formulaStyle);
			var options;
			if (this.formulaStyle === "R1C1")
				options = { formulaStyle: this.formulaStyle, numberDecimalSeparator: ".", 
					displayLanguage: this.displayLanguage, contentLanguage: this.contentLanguage };
			else 
				options = { formulaStyle: this.formulaStyle, numberDecimalSeparator: this.numberDecimalSeparator, 
					displayLanguage: this.displayLanguage, contentLanguage: this.contentLanguage };
			let code = formatFormulaWrap(formula.slice(1), options, widthLimit);
			let lines = code.split('\n');
			lines = lines.map((line, i) => (i === 0 ? '= ' + line : '  ' + line));
			return lines.join('\n');
		} else {
			console.log(this.formulaStyle);
			var options;
			if (this.formulaStyle === "R1C1") 
				options = { formulaStyle: this.formulaStyle, numberDecimalSeparator: ".", 
					displayLanguage: this.displayLanguage, contentLanguage: this.contentLanguage };
			else 
				options = { formulaStyle: this.formulaStyle, numberDecimalSeparator: this.numberDecimalSeparator, 
					displayLanguage: this.displayLanguage, contentLanguage: this.contentLanguage };
			return formatFormulaWrap(formula, options, widthLimit);
		}
	};

	formatFormulaLSP(formula) {
		let formulaR
		formula = formula.trimLeft();
		let widthLimit = this.widthLimit;
		if (this.autoWidthLimit) {
			widthLimit = getFormatWidthLimit(formula, 40);
			console.log(`getFormatWidthLimit: ${widthLimit}`)

			const action: any = { theType: 'getFormatWidthLimit', getFormatWidthLimit: {} }
			action.getFormatWidthLimit.formulaInitial = formula;
			action.getFormatWidthLimit.base = 40
			action.getFormatWidthLimit.widthLimitResult = widthLimit
			kpiServiceLog({action}); 
		}
		const action: any = { theType: 'formatFormula', formatFormula: {} }
		action.formatFormula.formulaInitial = formula;
		action.formatFormula.widthLimitInput = widthLimit;

		try {
			console.log("before this._formatFormula(formula, widthLimit)")
			formulaR = this._formatFormula(formula, widthLimit);
		} catch (e) {
			console.log(e.toString())
			// when calling OCaml function failed, set code to raw formula
			action.formatFormula.formulaResult = formulaR;
			action.formatFormula.status = "failed";
			action.formatFormula.errorMessage = e.toString();
			kpiServiceLog({action}); 
			throw e;
		}

		if (widthLimit != this.widthLimit) {
			// this.widthLimit = widthLimit
			action.formatFormula.formulaResult = formulaR;
			action.formatFormula.status = "successful";
		} else {
			action.formatFormula.formulaResult = formulaR;
			action.formatFormula.status = "successful";
		}

		kpiServiceLog({action}); 

		return formulaR
	};

	format(document: TextDocument, range: Range, option: FormattingOptions): TextEdit[] {
        console.log("format in formula-language-server.ts")
		let edits : any[] = [];
		edits.push(TextEdit.del(range));

		let t = this.formatFormulaLSP(document.getText())

		edits.push(TextEdit.insert(Position.create(0, 0), t));
		return edits;
	}

    codeAction(model: monaco.editor.ITextModel, document: TextDocument, 
        diagnostic: Diagnostic, // defined in vscode-languageserver-types, rather than Diagnostic in language-feature.ts
        uri: string,
        analysisResult: any): CodeAction | Command | null {

        let edits : any
        let action : any
 
        analysisResult.forEach(resultA1 => {
            console.log("resultA1 in codeAction", resultA1)
            let start = resultA1.start + 1 || 0;
            let length = resultA1.ending - resultA1.start || 1;
            const { lineNumber: startLineNumber, column: startColumn } = model.getPositionAt(start);
            const { lineNumber: endLineNumber, column: endColumn } = model.getPositionAt(start + length);
            if ((startLineNumber == diagnostic.range.start.line + 1) && (startColumn == diagnostic.range.start.character + 1) &&
				(endLineNumber == diagnostic.range.end.line + 1) && (endColumn == diagnostic.range.end.character + 1) &&
				(utilities.infoFromResult(resultA1).code == diagnostic.code)) {    
                    // console.log("diagnostic.range", diagnostic.range)
                    // console.log(startLineNumber);
                    // console.log(startColumn);
                    // console.log(endLineNumber);
                    // console.log(endColumn);        
					edits = [TextEdit.replace(diagnostic.range, utilities.infoFromResult(resultA1).optimized)];
					console.log("result A1 lalala", resultA1);
					console.log("diagnostic lalala", diagnostic);
                    action = CodeAction.create(utilities.infoFromResult(resultA1).quickFix, { changes: { uri: edits } }, "quickfix");
                    action.diagnostics = [diagnostic];
                }
        })
        return action
	}

	public async provideHover(model: monaco.editor.ITextModel, position: monaco.Position, token: CancellationToken, analysisResult: any): Promise<monaco.languages.Hover | undefined> {
		/*  getModelMarkers()[0]
		    code: "112"
            endColumn: 12
            endLineNumber: 1
            message: " "
            owner: "formula"
            relatedInformation: undefined
            resource: _URI {scheme: "inmemory", authority: "model.json", path: "", query: "", fragment: "", …}
            severity: 8
            source: undefined
            startColumn: 5
            startLineNumber: 1
            tags: []
        */

        console.log("monaco.editor.getModelMarkers({})", monaco.editor.getModelMarkers({}))

        console.log("providerHover ", analysisResult)

        let contents: any[] = [];
		let rangeStart = -1; 
		let range:any = null;
        analysisResult.forEach(resultA1 => {
			console.log("resultA1", resultA1);
			let start = resultA1.start + 1 || 0;
			let length = resultA1.ending - resultA1.start || 1;
			const { lineNumber: startLineNumber, column: startColumn } = model.getPositionAt(start);
			const { lineNumber: endLineNumber, column: endColumn } = model.getPositionAt(start + length);
			let markerStart = new monaco.Position(startLineNumber, startColumn);
			let markerEnd = new monaco.Position(endLineNumber, endColumn);

			if (markerStart.isBeforeOrEqual(position) && position.isBeforeOrEqual(markerEnd)) {
				contents.push({ value: utilities.infoFromResult(resultA1).contentMarkdown, isTrusted: false })
				if (rangeStart == -1 || rangeStart > start) { // select the largest range 
					range = { startLineNumber, startColumn, endLineNumber, endColumn };
					rangeStart = start ; 
				}
			}
		})

		console.log("contents", contents)
		return { contents: contents , range };
	}
}