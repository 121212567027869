import React from 'react';
import { routerRedux, Switch, Route } from 'dva/router';
import Layout from './layouts';
import LoginPage from './components/LoginPage';
import PrettyFormula from './components/PrettyFormula';
import FormulaEditorWeb from './components/FormulaEditorWeb';
import FormulaEditorAddin from './components/FormulaEditorAddin';
import BottleneckDetector from './components/BottleneckDetector';
import Verificator from './components/Verificator';
import Optimizer from './components/Optimizer';
import Welcome from './components/welcome';
import LoginGuard from './guards/Login';
import SubscriptionGuard from './guards/Subscription';
import MySubscription from './components/Plans/MySubscription';
import PlansSubscription from './components/Plans/PlansSubscription';
import SocialLoginSuccess from './components/socialLoginSuccess';
import { AppName } from './models/app';
import { AwaitPromiseThenRender } from './components/AwaitPromiseThenRender';
import RedirectHelpdeskPage from './components/RedirectHelpdeskPage';
import RedirectDocumentPage from './components/RedirectDocumentPage';
import ResetForm from './components/ResetForm';
import Kpi from './components/Kpi';
const { ConnectedRouter } = routerRedux;
function RouterConfig({ history }: any) {
  //@ts-ignore
  return (
    <AwaitPromiseThenRender
      //@ts-ignore
      promise={typeof window.__$$notInOffice !== "undefined" ? Promise.resolve(true) : Office.onReady()}
    >
      <ConnectedRouter history={history}>
        <Layout>
          <Switch>
            <Route path="/socialLoginSuccess">
              <SocialLoginSuccess />
            </Route>
            <Route path="/sign">
              <LoginPage />
            </Route>


            <Route path="/home">
              <Welcome />
            </Route>
            <Route path="/kpi">
              <Kpi />
            </Route>
            <Route path="/redirect-document">
              <RedirectDocumentPage />
            </Route>
            <Route path="/redirect-helpdesk">
              {/* <ButtonPage url="https://www.10studio.tech/help" /> */}
              <RedirectHelpdeskPage />

            </Route>
            <Route exact path="/">
              <div>
                Welcome On Board!
              </div>
            </Route>
            <Route exact path="/formula-editor-web">
              <FormulaEditorWeb />
            </Route>
            <Route exact path="/formula-editor-addin">
              <FormulaEditorAddin />
            </Route>
            <Route exact path="/pretty-formula">
              <PrettyFormula />
            </Route>
            <Route exact path="/bottleneck-detector">
              <BottleneckDetector />
            </Route>
            <Route exact path="/spreadsheet-verificator">
              <Verificator />
            </Route>
            <Route exact path="/spreadsheet-optimizer">
              <Optimizer />
            </Route>
            <Route exact path="/reset">
              <ResetForm />
            </Route>
            

            <LoginGuard>
              <Switch>
                <Route exact path="/my-subscription">
                  <MySubscription />
                </Route>
                <Route exact path="/subscribe">
                  <PlansSubscription />
                </Route>
                <Route exact path="/subscribe-pretty-formula">
                  <PlansSubscription app={AppName.PrettyFormula} />
                </Route>
                <SubscriptionGuard>
                  <Switch>
                    {/* 
                    //  require login for pretty-formula 
                     <Route exact path="/pretty-formula">
                      <PrettyFormula />
                    </Route>
                    <Route exact path="/bottleneck-detector">
                      <BottleneckDetector />
                    </Route>
                    <Route exact path="/spreadsheet-verificator">
                      <Verificator />
                    </Route>
                    <Route exact path="/spreadsheet-optimizer">
                      <Optimizer />
                    </Route> */}

                  </Switch>
                </SubscriptionGuard>
              </Switch>
            </LoginGuard>
          </Switch>
        </Layout>
      </ConnectedRouter>
    </AwaitPromiseThenRender>
  );
}

export default RouterConfig;