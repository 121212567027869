import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'dva';
import { getIsLoggedIn, getisLoggingInOrOut } from '../../selectors/auth';
import { State } from '../../store/reducer';
import { getNextPath } from '../../selectors/header';
import { Spinner } from 'office-ui-fabric-react';

import {Redirect , withRouter, RouteComponentProps } from 'dva/router';

interface LoginGuardProps {
    dispatch: Dispatch<any>;
    isLoggedIn: boolean;
    isLoggingInOrOut: boolean;
    next?: string;
}

class LoginGuard extends React.Component<RouteComponentProps<any> & LoginGuardProps , {} > {

    render () {
        
        if (this.props.isLoggingInOrOut) {
            return <Spinner style={{ paddingTop: 10, paddingBottom: 10 }} />;
        }
        if (!this.props.isLoggedIn) {
            if (this.props.next) {
                return <Redirect to={`/sign?next=${this.props.next}`} push />
            } else {
                return <Redirect to='/sign' push />
            }
            return <></>;
        }
        return <>{this.props.children}</>;
    }
    
};

export default connect((state: State) => ({
    next: getNextPath(state),
    isLoggedIn: getIsLoggedIn(state),
    isLoggingInOrOut: getisLoggingInOrOut(state),
}))(withRouter(LoginGuard));
