import { getTheme } from 'office-ui-fabric-react/lib/Styling'
import { createMuiTheme } from '@material-ui/core/styles';

const fabricTheme = getTheme()

const theme = createMuiTheme(
    {
        palette: {
            primary: {
                // light: will be calculated from palette.primary.main,
                main: fabricTheme.palette.themePrimary,
                // dark: will be calculated from palette.primary.main,
                // contrastText: will be calculated to contrast with palette.primary.main
            },
        }
    })

export default theme
