import React, { Suspense, lazy } from 'react';
import { MonacoToProtocolConverter, ProtocolToMonacoConverter } from 'monaco-languageclient/lib/monaco-converter';
import * as monaco from 'monaco-editor';
import { FormulaLanguageService } from './formula-language-server';
import { DiagnosticsAdapter } from './language-feature';
import { TextDocument } from 'vscode-languageserver-protocol';
import { TextEdit, Position } from 'vscode-languageserver-protocol';
import * as formula from './formula';
import { connect } from 'dva';
import { getPreferences, getisLoggingInOrOut } from '../../selectors/auth';
const LANGUAGE_ID_A1 = 'formula_A1'; // used by Excel in A1 mode + Google Sheets
const LANGUAGE_ID_R1C1 = 'formula_R1C1'; // used by Excel in R1C1 mode
const MODEL_URI = 'inmemory://model.json';
const MonacoEditor = lazy(() => import('react-monaco-editor'));

// create the Monaco editor
const value = `IF ( A , B , C )`;

const m2p = new MonacoToProtocolConverter();
const p2m = new ProtocolToMonacoConverter();

function createDocument(model) {
  return TextDocument.create(MODEL_URI, model.getModeId(), model.getVersionId(), model.getValue());
}

monaco.languages.register({ id: LANGUAGE_ID_A1, extensions: [], aliases: [], mimetypes: [], });
monaco.languages.register({ id: LANGUAGE_ID_R1C1, extensions: [], aliases: [], mimetypes: [], });

monaco.languages.setLanguageConfiguration(LANGUAGE_ID_A1, formula.conf);
monaco.languages.setLanguageConfiguration(LANGUAGE_ID_R1C1, formula.conf);

monaco.languages.setMonarchTokensProvider(LANGUAGE_ID_A1, formula.language);
monaco.languages.setMonarchTokensProvider(LANGUAGE_ID_R1C1, formula.language);

let formulaLanguageService_A1 = new FormulaLanguageService();
let formulaLanguageService_R1C1 = new FormulaLanguageService();

function _provideDocumentRangeFormattingEdits(model, range, options, token, formulaLanguageService) {
  // if (model.getVersionId() != formulaLanguageService.currentModelVersionIdprovideDocumentRangeFormattingEdits) {
  console.log("call provideDocumentRangeFormattingEdits", model.getVersionId())
  const document = createDocument(model);
  const edits = formulaLanguageService.format(document, m2p.asRange(range), m2p.asFormattingOptions(options));
  formulaLanguageService.currentModelVersionIdprovideDocumentRangeFormattingEdits = model.getVersionId()
  console.log("call currentModelVersionIdprovideDocumentRangeFormattingEdits", formulaLanguageService.currentModelVersionIdprovideDocumentRangeFormattingEdits)
  return p2m.asTextEdits(edits)
}
function setupModeR1C1(optimizer = true, preferences = {}) {
  monaco.languages.registerDocumentRangeFormattingEditProvider(LANGUAGE_ID_R1C1, {
    provideDocumentRangeFormattingEdits(model, range, options, token) {
      return _provideDocumentRangeFormattingEdits(model, range, options, token, formulaLanguageService_R1C1)
    }
  });
  
  // if we don't want hinting, we don't register HoverProvider and CodeActionProvider
  if (!optimizer) return;
  let da_R1C1 = new DiagnosticsAdapter(LANGUAGE_ID_R1C1, "R1C1", preferences)


  
  monaco.languages.registerHoverProvider(LANGUAGE_ID_R1C1, {
    provideHover: (model, position, token) => {
      console.log("call provideHover", model.getVersionId())
      formulaLanguageService_R1C1.currentModelVersionIdProvideHover = model.getVersionId()
      console.log("call currentModelVersionIdProvideHover", formulaLanguageService_R1C1.currentModelVersionIdProvideHover)
      return formulaLanguageService_R1C1.provideHover(model, position, token, da_R1C1.analysisResult);
    }
  });

  monaco.languages.registerCodeActionProvider(LANGUAGE_ID_R1C1, {
    provideCodeActions: (model, _range, context, token) => {
      console.log("_range", _range);
      console.log("context", context)
      console.log("call provideCodeActions", model.getVersionId())
      const document = createDocument(model);
      const uri = model.uri.toString();
      const actions = context.markers.map(marker => m2p.asDiagnostic(marker)).map(diagnostic => {
        let x = formulaLanguageService_R1C1.codeAction(model, document, diagnostic, uri, da_R1C1.analysisResult);
        console.log("codeAction", x)
        return x
      }).filter(action => action != null)
        .map(action => p2m.asCodeAction(action))
        .map(action => (
          {
            ...action,
            edit: {
              ...action.edit,
              edits: action.edit.edits.map(e => ({
                ...e,
                resource: model.uri,
                edit: e.edits[0],
              }))
                .map(({ edits, ...rest }) => rest)
            }
          }
        ));
      formulaLanguageService_R1C1.currentModelVersionIdProvideCodeActions = model.getVersionId()
      console.log("call currentModelVersionIdProvideCodeActions", formulaLanguageService_R1C1.currentModelVersionIdProvideCodeActions)
      return {
        actions: actions,
        dispose: () => { }
      }
    }
  });

}
function setupModeA1(optimizer = true, preferences = {}) {
  monaco.languages.registerDocumentRangeFormattingEditProvider(LANGUAGE_ID_A1, {
    provideDocumentRangeFormattingEdits(model, range, options, token) {
      return _provideDocumentRangeFormattingEdits(model, range, options, token, formulaLanguageService_A1)
    }
  });
 

  // if we don't want hinting, we don't register HoverProvider and CodeActionProvider
  if (!optimizer) return;

  let da_A1 = new DiagnosticsAdapter(LANGUAGE_ID_A1, "A1" ,preferences)

  monaco.languages.registerHoverProvider(LANGUAGE_ID_A1, {
    provideHover: (model, position, token) => {
      console.log("call provideHover", model.getVersionId())
      formulaLanguageService_A1.currentModelVersionIdProvideHover = model.getVersionId()
      console.log("call currentModelVersionIdProvideHover", formulaLanguageService_A1.currentModelVersionIdProvideHover)
      return formulaLanguageService_A1.provideHover(model, position, token, da_A1.analysisResult);
    }
  });

  // don't know why we may have "formulaLanguageService.codeAction is not a function" error if we make a function provideCodeActions: (model, _range, context, token, formulaLanguageService, da)for the repetitive part below.
  monaco.languages.registerCodeActionProvider(LANGUAGE_ID_A1, {
    provideCodeActions: (model, _range, context, token) => {
      console.log("_range", _range);
      console.log("context", context)
      console.log("call provideCodeActions", model.getVersionId())
      const document = createDocument(model);
      const uri = model.uri.toString();
      const actions = context.markers.map(marker => m2p.asDiagnostic(marker)).map(diagnostic => {
        let x = formulaLanguageService_A1.codeAction(model, document, diagnostic, uri, da_A1.analysisResult);
        console.log("codeAction", x)
        return x
      }).filter(action => action != null)
        .map(action => p2m.asCodeAction(action))
        .map(action => (
          {
            ...action,
            edit: {
              ...action.edit,
              edits: action.edit.edits.map(e => ({
                ...e,
                resource: model.uri,
                edit: e.edits[0],
              }))
                .map(({ edits, ...rest }) => rest)
            }
          }
        ));
      formulaLanguageService_A1.currentModelVersionIdProvideCodeActions = model.getVersionId()
      console.log("call currentModelVersionIdProvideCodeActions", formulaLanguageService_A1.currentModelVersionIdProvideCodeActions)
      return {
        actions: actions,
        dispose: () => { }
      }
    }
  });
 
}

class ResizableMonacoEditor extends React.Component {

  _handleEditorDidMount(editor, monaco) {


    monaco.languages.onLanguage(LANGUAGE_ID_A1, () => {
      return setupModeA1(this.props.optimizer, this.props.preferences);
    });
    monaco.languages.onLanguage(LANGUAGE_ID_R1C1, () => {
      return setupModeR1C1(this.props.optimizer, this.props.preferences);
    });
    this.monacoFormulaModel_A1 = monaco.editor.createModel(value, LANGUAGE_ID_A1);
    this.monacoFormulaModel_R1C1 = monaco.editor.createModel(value, LANGUAGE_ID_R1C1);

    formulaLanguageService_A1.widthLimit = this.props.widthLimit
    formulaLanguageService_A1.autoWidthLimit = this.props.autoWidthLimit
    formulaLanguageService_A1.formulaStyle = this.props.formulaStyle
    formulaLanguageService_A1.numberDecimalSeparator = this.props.numberDecimalSeparator
    formulaLanguageService_A1.displayLanguage = this.props.displayLanguage
    formulaLanguageService_A1.contentLanguage = this.props.contentLanguage

    formulaLanguageService_R1C1.widthLimit = this.props.widthLimit
    formulaLanguageService_R1C1.autoWidthLimit = this.props.autoWidthLimit
    formulaLanguageService_R1C1.formulaStyle = this.props.formulaStyle
    formulaLanguageService_R1C1.numberDecimalSeparator = this.props.numberDecimalSeparator
    formulaLanguageService_R1C1.displayLanguage = this.props.displayLanguage
    formulaLanguageService_R1C1.contentLanguage = this.props.contentLanguage

    this.editor = editor;
    if (this.props.formulaStyle === "A1") this.editor.setModel(this.monacoFormulaModel_A1)
    else this.editor.setModel(this.monacoFormulaModel_R1C1)
    this.forceUpdate();
    this.handleResize();
    // in case fix size didn't work 
    // this.editor.layout({ height: 500, width: window.innerWidth * 0.79 });

    // fix size 
    setTimeout(() => {
      this.handleResize();
    }, 1000);

    if (this.props.editorDidMount) {
      this.props.editorDidMount(editor, monaco);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.widthLimit !== this.props.widthLimit) {
      formulaLanguageService_A1.widthLimit = this.props.widthLimit;
      formulaLanguageService_R1C1.widthLimit = this.props.widthLimit;
    }
    if (prevProps.autoWidthLimit !== this.props.autoWidthLimit) {
      formulaLanguageService_A1.autoWidthLimit = this.props.autoWidthLimit
      formulaLanguageService_R1C1.autoWidthLimit = this.props.autoWidthLimit
    }
    if (prevProps.formulaStyle != this.props.formulaStyle) {
      formulaLanguageService_A1.formulaStyle = this.props.formulaStyle;
      formulaLanguageService_R1C1.formulaStyle = this.props.formulaStyle;
      if (this.props.formulaStyle === "A1") this.editor.setModel(this.monacoFormulaModel_A1)
      else this.editor.setModel(this.monacoFormulaModel_R1C1)
    }
    if (prevProps.numberDecimalSeparator !== this.props.numberDecimalSeparator) {
      formulaLanguageService_A1.numberDecimalSeparator = this.props.numberDecimalSeparator;
      formulaLanguageService_R1C1.numberDecimalSeparator = this.props.numberDecimalSeparator
    }
    if (prevProps.displayLanguage !== this.props.displayLanguage) {
      formulaLanguageService_A1.displayLanguage = this.props.displayLanguage;
      formulaLanguageService_R1C1.displayLanguage = this.props.displayLanguage
    }
    if (prevProps.contentLanguage !== this.props.contentLanguage) {
      formulaLanguageService_A1.contentLanguage = this.props.contentLanguage;
      formulaLanguageService_R1C1.contentLanguage = this.props.contentLanguage
    }
    // Tie 20210908: what's the difference between componentDidUpdate and _handleEditorDidMount?
  }

  fallback() {
    return <div style={{
      fontFamily: '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
      fontWeight: 300,
      fontSize: 14,
      color: '#C0C0C0'
    }}><br /><br />&nbsp;&nbsp;&nbsp;&nbsp;... loading
    </div>;
  }

  render() {
    console.log(`{...this.props}: ${JSON.stringify({ ...this.props })}`)
    let _handleEditorDidMount = this._handleEditorDidMount.bind(this)
    return <Suspense fallback={
      <div style={{
        fontFamily: '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
        fontWeight: 300,
        fontSize: 14,
        color: '#C0C0C0'
      }}><br /><br />&nbsp;&nbsp;&nbsp;&nbsp;... loading
      </div>}>
      <MonacoEditor {...this.props} editorDidMount={_handleEditorDidMount} />
    </Suspense>
  }

  handleResize() {
    if (this.editor) {
      this.editor.layout();
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize.bind(this));
  }
}

export default connect((state) => ({
  preferences: getPreferences(state),
  IsLoggingInOrOut: getisLoggingInOrOut(state)
}))(ResizableMonacoEditor);