import React from 'react';
import { Dispatch } from 'redux';

import { connect } from 'dva';
import { State } from '../../store/reducer';
import {
  getSubscription,
  getIsSubscriptionsLoading,
  getTrial,
  getSubscriptionsError,
} from '../../selectors/subscription';
import { selectPathname } from '../../selectors/app';
import { Subscription, Plan } from '../../services/subscription';
import { getPlanProductName, getIsSubscriptionActive } from '../../components/Plans/utils';
import { getNextFromUrl } from '../../utils';

import { Spinner } from 'office-ui-fabric-react';
import NoAuthLoginRedirect from '../../components/NoAuthLoginRedirect';

interface SubscriptionProps {
  dispatch: Dispatch<any>;
  error?: Error;
  subscription?: Subscription;
  trial?: Subscription;
  isSubscriptionsLoading: boolean;
  path?: string;
}

enum AddinAuth {
  All = 0,
  Funfun,
  Optimizer,
  Verificator,
  BottleneckDetector,
  PrettyFormula,
}

// Tie: should be called 'product2AddinAuth'
const subscriptionAddinAuth: { [key: string]: AddinAuth[] } = {
  'All-Apps Trial': [AddinAuth.All],
  'All-in-One': [AddinAuth.All], // Tie: All-in-One could be deleted
  'All-Apps': [AddinAuth.All],
  'Funfun': [AddinAuth.Funfun],
  'Optimizer': [AddinAuth.Optimizer],
  'Verificator' :[AddinAuth.Verificator],
  'Pretty Formula': [AddinAuth.PrettyFormula],
  'Pretty Formula Trial': [AddinAuth.PrettyFormula],
  'Bottleneck Detector' : [AddinAuth.BottleneckDetector]
};

// Tie: should be called 'AddinAuth2Url'
const addinUrl: { [key in AddinAuth]: string } = {
  [AddinAuth.All]: '',
  [AddinAuth.Verificator]: 'spreadsheet-verificator',
  [AddinAuth.Optimizer]: 'spreadsheet-optimizer',
  [AddinAuth.BottleneckDetector]: 'bottleneck-detector',
  [AddinAuth.Funfun]: 'funfun',
  [AddinAuth.PrettyFormula]: 'pretty-formula'
};

function getCurrentUrlPath(path: string): string {

  path = path[0] === '/' ? path.slice(1) : path;
  path = path.split('/')[0];

  if (path === 'welcome') {
    return getNextFromUrl(window.location)
      .slice(1)
      .split('/')[0]; // first character is '/'
  }
  return path;
}

function checkPlan(plan: Plan, path: string): boolean {
  const product = getPlanProductName(plan);

  let auth = subscriptionAddinAuth[product] || []; // Tie: should be called 'product2AddinAuth'

  if (auth.some(x => x === AddinAuth.All)) {
    return true;
  }
  auth = auth.filter(x => x !== AddinAuth.All);

  return auth.some(x => addinUrl[x] === path);
}

function checkSubscription(path: string, subscription?: Subscription): boolean {
  if (!subscription) return false;
  if (!getIsSubscriptionActive(subscription)) return false;
  const subItems: any[] = subscription.items.data;

  return subItems.some(item => checkPlan(item.plan, path));

}

function checkTrial(path: string, trial?: Subscription): boolean {
  return checkSubscription(path, trial);

}

const SubscriptionGuard: React.StatelessComponent<SubscriptionProps> = props => {

  if (props.path === undefined || props.path === null || props.isSubscriptionsLoading || props.error) {
    return <Spinner style={{ paddingTop: 20, paddingBottom: 20 }} />;

  }
  const path = getCurrentUrlPath(props.path);
  
  if (!checkSubscription(path, props.subscription) && !checkTrial(path, props.trial)) {
    return <NoAuthLoginRedirect feature={path}></NoAuthLoginRedirect>;
  }
  return <div>{props.children}</div>;
};

export default connect((state: State) => ({
  error: getSubscriptionsError(state),
  isSubscriptionsLoading: getIsSubscriptionsLoading(state),
  subscription: getSubscription(state),
  trial: getTrial(state),
  path: selectPathname(state)
}))(SubscriptionGuard);
