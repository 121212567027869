import axios, { AxiosRequestConfig, AxiosError } from 'axios';
import _ from 'lodash';
import { getConfig } from './auth';

//tslint:disable
export type Plan = any;
export type Subscription = any;
export type Invoice = any;
export type Event = any;
//tslint:enable


export async function fetchPlans(): Promise<Plan[]> {
  const res = await axios.get(`${FUNFUN_URL}/httpOnly/stripe/plans`);
  return res.data.plans;
}

export async function fetch(activeOnly = true): Promise<Subscription[]> {
  const res = await axios.get(`${FUNFUN_URL}/httpOnly/stripe/subscriptions`, await getConfig());
  return res.data.subscriptions as Subscription[];
}

export async function fetchTrial(app?: string): Promise<Subscription> {
  const res = await axios.get(
    `${FUNFUN_URL}/httpOnly/stripe/subscriptions/trial?app=${app}`,
    await getConfig(),
  );
  return res.data.trial as Subscription;
}

export async function fetchInvoices(subscriptionId: string): Promise<Invoice[]> {
  const res = await axios.get(
    `${FUNFUN_URL}/httpOnly/stripe/invoices/${subscriptionId}`,
    await getConfig(),
  );
  return res.data.invoices as Invoice[];
}

export async function fetchUpcomingInvoice(subscriptionId: string): Promise<Invoice | undefined> {
  try {
    const res = await axios.get(
      `${FUNFUN_URL}/httpOnly/stripe/invoices/${subscriptionId}/upcoming`,
      await getConfig(),
    );
    return res.data.invoice as Invoice;
  } catch (e) {
    const err: AxiosError = e;
    if (_.get(err, 'response.status') === 404) {
      return undefined
    }
    throw e;
  }
}

export async function fetchSubscriptionEvents(subscriptionId: string): Promise<Event[]> {
  const res = await axios.get(
    `${FUNFUN_URL}/httpOnly/stripe/subscriptions/${subscriptionId}/events`,
    await getConfig(),
  );
  return res.data.events as Event[];
}

export interface SubscriptionCreatePayload {
  plans: any[]; //tslint:disable-line:no-any
  source: string;
}

//tslint:disable-next-line:no-any
export async function create(payload: SubscriptionCreatePayload): Promise<any> {
  const { plans, source } = payload;
  //tslint:disable-next-line:no-any
  const res: any = await axios.post(
    `${FUNFUN_URL}/httpOnly/stripe/subscriptions`,
    {
      plans,
      source,
    },
    await getConfig(),
  );
  return res.data.subscription;
}
