import { Plan, Subscription } from '../../services/subscription';
import _ from 'lodash';
import { AppName } from '../../models/app';

export const ALL_APPS_PRODUCT_NAME = 'All-Apps';
export const SINGLE_APPS_PRODUCT_NAME = 'Single-Apps';

const currecyMap: { [key: string]: string } = {
  usd: '$',
  cny: '￥',
  eur: '€',
};

export function getCurrencyChar(currency: string): string | undefined {
  return currecyMap[currency];
}

export function getDatetimeText(dt: number): string {
  return new Date(dt * 1000).toLocaleString(undefined, {
    hour12: false,
  });
}

export function getMoneyText(amount: number, currency: string) {
  return `${getCurrencyChar(currency) || ''}${amount / 100}`;
}

export function getPlanProductName(plan: Plan) {
  return plan.nickname.split(',')[0];
}

export interface ProductItem {
  name: string;
  iconName: string;
  link: string;
}
const appProductsMap: { [key: string]: string[] } = {

  [AppName.PrettyFormula]: ['Pretty Formula'],
  [AppName.Studio]: ['All-Apps', 'Pretty Formula', 'Funfun', 'Bottleneck Detector', 'Optimizer', 'Verificator']
}
export function getAppProducts(app: AppName = AppName.Studio) {
  return appProductsMap[app] || [];
}
const planItemsMap: { [key: string]: ProductItem[] } = {
  [ALL_APPS_PRODUCT_NAME]: [
    {
      name: 'PlanAllAppsName',
      iconName: 'images/10studioLogo3232.jpg',
      link: '/welcome',
    },
  ],
  // 'Formula Editor': [
  //   {
  //     name: 'Formula Editor',
  //     iconName: 'images/formulaEditorLogo3232.png',
  //     link: '/welcome/?next=formulaEditor',
  //   },
  // ],
  Funfun: [
    {
      name: 'Funfun',
      iconName: 'images/funfunLogoCode3232.png',
      link: '/welcome/?next=funfun/addin/homePlus',
    },
  ],
  'Bottleneck Detector': [
    {
      name: 'Detector',
      iconName: 'images/detectorLogo3232.png',
      link: '/welcome/?next=bottleneckDetector',
    },
  ],
  Optimizer: [
    {
      name: 'Optimizer',
      iconName: 'images/optimizerLogo3232.png',
      link: '/welcome/?next=optimizer',
    },
  ],
  Verificator: [
    {
      name: 'Verificator',
      iconName: 'images/verificatorLogo3232.png',
      link: '/welcome/?next=verificator',
    },
  ],
  'Pretty Formula': [
    {
      name: 'Pretty Formula',
      iconName: 'images/verificatorLogo3232.png',
      link: '/welcome/?next=pretty-formula',
    }
  ]
};

export function getPlanProductItems(product: string, split = false): ProductItem[] {
  if (product === ALL_APPS_PRODUCT_NAME && split) {
    return _.concat(
      [],
      ...Object.keys(planItemsMap)
        .filter(x => x !== ALL_APPS_PRODUCT_NAME)
        .map(x => planItemsMap[x]),
    );
  }
  return planItemsMap[product] || [];
}

const planDescriptionsMap: { [key: string]: string } = {
  [ALL_APPS_PRODUCT_NAME]:
    '10 Studio is a toolset that helps you better build, edit, verify and optimize your spreadsheets.',
  // 'Formula Editor': 'Format long and complex formulas for a better readability.',
  Funfun:
    'Use and write powerful scripts in your spreadsheet for data visualization, analytics and more.',
  'Bottleneck Detector': 'Detect the bottleneck that slows down your workbook.',
  Optimizer: 'Identify the formulas that can be improved in terms of efficiency and readability.',
  Verificator: 'Verify and detect errors in your spreadsheets.',
  'Pretty Formula': 'Format long and complex formulas for a better readability.'
};

export function getPlanDescription(product: string): string {
  return planDescriptionsMap[product] || '';
}

const planIndexMap: { [key: string]: number } = {
  [ALL_APPS_PRODUCT_NAME]: 1,
  // 'Formula Editor': 2,
  'Pretty Formula': 2,
  Funfun: 3,
  'Bottleneck Detector': 4,
  Optimizer: 5,
  Verificator: 6,
};

export function getPlanIndex(product: string): number {
  return planIndexMap[product] || 999;
}

export const getPlanPrice = (plan: Plan): string => {
  const currency = getCurrencyChar(plan.currency);
  return `${currency}${plan.amount / 100}/${plan.interval}`;
};

//TODO: different subscription status
export const getIsSubscriptionActive = (trial: Subscription): boolean => {
  return trial.status === 'active';
};
