
import { State } from '../store/reducer';
import { Plan, Subscription, Invoice, Event } from '../services/subscription';
import { get } from 'lodash';
export const getPlans = (state: State): Plan[] | undefined => {
  return state.subscription.plans.data;
};

export const getIsSubscriptionsLoading = (state: State): boolean => {
  return !state.subscription.subscriptions.loaded;
};

export const getIsSubscriptionDetailLoading = (state: State): boolean => {
  return !state.subscription.subscriptionDetail.loaded;
};

export const getSubscriptionsError = (state: State): Error | undefined => {
  return state.subscription.subscriptions.error;
};

export const getSubscriptionDetailError = (state: State): Error | undefined => {
  return state.subscription.subscriptionDetail.error;
};

export const getSubscription = (state: State): Subscription | undefined => {

  const subscriptions = get(state, 'subscription.subscriptions.data.subscriptions');
  if (subscriptions && subscriptions.length) return subscriptions[0];
  return undefined;
};

export const getIsSubscribing = (state: State): boolean => {
  return (
    state.subscription.subscribing ||
    state.subscription.updatingSubscription
    || false);
};

export const getSubscribingError = (state: State): Error | undefined => {
  return state.subscription.subscribingError;
};

export const getTrial = (state: State): Subscription | undefined => {
  return get(state, 'subscription.subscriptions.data.trial');
};

export const getInvoices = (state: State): Invoice[] | undefined => {
  return get(state, 'subscription.subscriptionDetail.data.invoices');
};

export const getUpcomingInvoice = (state: State): Invoice | undefined => {
  return get(state, 'subscription.subscriptionDetail.data.upcomingInvoice');
};

export const getEvents = (state: State): Event[] | undefined => {
  return get(state, 'subscription.subscriptionDetail.data.events');
};
