import React from "react";
import { List } from 'office-ui-fabric-react/lib/components/List';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { isNullOrUndefined } from 'util';
import { DefaultButton, Stack, PrimaryButton, TooltipHost } from 'office-ui-fabric-react';

import styles from "./index.module.scss";
import LoggerComponent from "../Logger";
import { runWithCancel, round } from "../utils";

interface IOptimizerState {
    noFormula: boolean,
    checkResults: ICheckResultItem[],
    showLogs: boolean,
    logs: string,
}

function getInitOptimizerState(): IOptimizerState {
    const checkResults: ICheckResultItem[] = [];
    return {
        noFormula: false,
        checkResults,
        showLogs: false,
        logs: "",
    }
}

const nrGlobal = 1048576;
const ncGlobal = 16384;

interface ICheckResultItem {
    sheet: string,
    co: any,
    range: string,
    formerFormulaR1C1: string,
    formerFormulaA1: string,
    afterFormulaR1C1: string,
    afterFormulaA1: string,
    rule: string,
    optimized: boolean,
}

const ruleTooltipMap: { [key: string]: string } = {
    "VLOOKUP => INDEX+MATCH": "Inserting a column in the table may mess up the VLOOKUP formula, whereas the INDEX+MATCH formula will always refer to the initial column regardless of the structure change.",
    "SUMIFs => SUMIFS": "For a big table, the SUM+SUMIFS formula will be more efficient than the SUMIFS+SUMIFS formula, because the SUM+SUMIFS formula goes through the table less than the SUMIFS+SUMIFS formula.",
    "IF+ISERROR => IFERROR": "The IFERROR formula is more succinct than the IF+ISERROR formula",
    "nested-IF => IFS": "IFS is a newly introduced function, the IFS formula is more succint than a certain type of nested-if formulas",
    "nested-IF => IF": "There may be surplus in a nest-if, the latter formula is more succint than the former one.",
    "nested-IF => IF+AND/OR": "Some logic in a nested-if can be expressed by logical operators such as AND or OR, which makes a formula more succint.",
    "nested-AND/OR => AND/OR": "The latter formula is more succint than the former."
}

class Optimizer extends LoggerComponent<{}, IOptimizerState> {
    readonly state = getInitOptimizerState();

    clearResult = () => {
        this.setState({ checkResults: [] });
    }

    getSheetNames = () => Excel.run(async (ctx) => {
        const sheetNames : any[] = [];
        var worksheets = ctx.workbook.worksheets;
        worksheets.load('name');
        await ctx.sync();
        for (var i = 0; i < worksheets.items.length; i++) {
            sheetNames.push(worksheets.items[i].name);
        }
        return sheetNames;
    })

    unprotectSheets = (sheetNames: string[]) => {
        return Excel.run(async (ctx) => {
            let sheetName: string;
            for (let i = 0; i < sheetNames.length; i++) {
                const sheet = ctx.workbook.worksheets.getItem(sheetNames[i]);
                sheetName = sheetNames[i];
                sheet.protection.unprotect();
            }
            const f = async () => {
                try {
                    return await ctx.sync();
                }
                catch (error) {
                    this.printlnLogs('You need to manually unprotect "' + sheetName +
                        '" with a password to continue');
                    throw new Error("unprotectSheets");
                }
            };
            await f();
        });
    }

    unhideSheets = (sheetNames: string[], nr: number, nc: number) => {
        return Excel.run(async (ctx) => {
            for (let i = 0; i < sheetNames.length; i++) {
                const sheet = ctx.workbook.worksheets.getItem(sheetNames[i]);
                sheet.visibility = "Visible";
                let r = sheet.getUsedRange();
                r = r.getBoundingRect("A1");
                r.rowHidden = false;
                r.columnHidden = false;
            }
            return await ctx.sync();
        });
    }

    getConcrete = (sheetNames: string[]) => {
        return Excel.run(async (ctx) => {
            const worksheetsOCaml : any[] = [];
            const usedRangeAsOCaml : any[] = [];
            const worksheets = ctx.workbook.worksheets;
            const usedRangeAs : any[] = [];
            const activateSheet = ctx.workbook.worksheets.getActiveWorksheet();
            for (let i = 0; i < sheetNames.length; i++) {
                const sheet = worksheets.getItem(sheetNames[i]);
                sheet.activate();
                const uR = sheet.getUsedRange();
                usedRangeAs[i] = uR.getBoundingRect("A1"); // larger usedRange 
                this.printlnLogs(`    getting address and formulas in '${sheetNames[i]}'`);
                usedRangeAs[i].load(["address", "formulasR1C1"]);
                await ctx.sync();
            }
            activateSheet.activate();
            await ctx.sync();
            for (let i = 0; i < sheetNames.length; i++) {
                worksheetsOCaml.push({ nameCode: sheetNames[i] });
                usedRangeAsOCaml.push({
                    sheet: sheetNames[i],
                    address: usedRangeAs[i].address,
                    formulasR1C1: usedRangeAs[i].formulasR1C1
                });
            }
            return { worksheetsOCaml, usedRangeAsOCaml }
        });
    }

    setBlocksUnit = (om: { worksheetsOCaml: any, usedRangeAsOCaml: any }) => {
        const { worksheetsOCaml, usedRangeAsOCaml } = om;
        const usedRangeAsOCamlWOValues : any[] = [];
        for (let i = 0; i < usedRangeAsOCaml.length; i++) {
            const formulasR1C1 = usedRangeAsOCaml[i].formulasR1C1;
            const formulasR1C1String : any[] = [];
            for (let j = 0; j < formulasR1C1.length; j++) {
                const tmp : any[] = [];
                for (let k = 0; k < formulasR1C1[j].length; k++) {
                    let x = String(formulasR1C1[j][k]);
                    if (x.length >= 1 && x.substring(0, 1) !== "=") {
                        x = "";
                    }
                    tmp.push(x);
                }
                formulasR1C1String[j] = tmp;
            };
            usedRangeAsOCamlWOValues.push({
                sheet: usedRangeAsOCaml[i].sheet,
                address: usedRangeAsOCaml[i].address,
                formulasR1C1: formulasR1C1String
            });
        }
        this.printLogs("    getting unit blocks from the engine")
        var bef = performance.now();
        const blocksUnit = getBlocksUnit(worksheetsOCaml, usedRangeAsOCamlWOValues);
        var af = performance.now();
        const tcost = round((af - bef) / 1000, 2);
        this.printlnLogs(` : ${tcost}s`);
        if (blocksUnit.length === 0) throw new Error("no formulas");
        return blocksUnit
    }

    setBlocksUnitF = async (blocksUnit: any[]) => {
        return Excel.run(async function (ctx) {
            const blocksUnitF : any[] = [];
            const worksheets = ctx.workbook.worksheets;
            const rangesLocal : any[] = [];
            for (let i = 0; i < blocksUnit.length; i++) {
                rangesLocal[i] =
                    worksheets.getItem(blocksUnit[i].sh).getRange(blocksUnit[i].ad);
                rangesLocal[i].load(["formulas", "formulasR1C1", "values"]);
            }
            await ctx.sync();
            for (let i = 0; i < blocksUnit.length; i++) {
                blocksUnitF.push({
                    sh: blocksUnit[i].sh,
                    ad: blocksUnit[i].ad,
                    co: blocksUnit[i].co,
                    f: blocksUnit[i].f,
                    formulas: rangesLocal[i].formulas,
                    formulasR1C1: rangesLocal[i].formulasR1C1,
                    values: rangesLocal[i].values
                });
            }
            return blocksUnitF
        });
    }

    checkOptimize = (blocksUnitF: any[]): ICheckResultItem[] => {
        const checkResults: ICheckResultItem[] = [];
        for (let i = 0; i < blocksUnitF.length; i++) {
            try {
                const block = blocksUnitF[i];
                let tryFormulaA1 = block.formulas[0][0];
                let tryFormulaR1C1 = block.formulasR1C1[0][0];
                let resultA1 = checkFormula(tryFormulaA1, "A1");  // transform.ml type checkresult
                let resultR1C1 = checkFormula(tryFormulaR1C1, "R1C1");  // transform.ml type checkresult
                if (resultR1C1.canBeConverted == 1) {
                    checkResults.push({
                        sheet: block.sh,
                        range: block.ad,
                        co: block.co,
                        formerFormulaR1C1: tryFormulaR1C1,
                        formerFormulaA1: tryFormulaA1,
                        afterFormulaR1C1: resultR1C1.result,
                        afterFormulaA1: resultA1.result,
                        rule: resultR1C1.rule,
                        optimized: false,
                    })
                }
            }
            catch (e) {
                console.log("there is an error, and we ignore it.")
                // Tie: should send log to backend 
            }
        }
        return checkResults
    }

    setShowLogs = (show: boolean) => {
        this.setState({ showLogs: show });
    }

    toggleShowLogs = () => {
        this.setState({ showLogs: !this.state.showLogs });
    }

    cancel: (() => void) | undefined = undefined;

    *doOptimize() {
        this.setShowLogs(false);
        this.clearLogs();
        this.setShowLogs(true);
        this.printlnLogs("BEGIN")
        this.printlnLogs("getting sheet names");
        const sheetNames = yield this.getSheetNames();
        this.printlnLogs("unprotecting sheets");
        yield this.unprotectSheets(sheetNames);
        this.printlnLogs("unhiding sheets");
        yield this.unhideSheets(sheetNames, nrGlobal, ncGlobal);
        this.printlnLogs("getting workbook state");
        const om = yield this.getConcrete(sheetNames);
        this.printlnLogs("making unit blocks");
        const blocksUnit = this.setBlocksUnit(om);
        this.printlnLogs("making full unit blocks");
        const blocksUnitF = yield this.setBlocksUnitF(blocksUnit);
        this.printlnLogs("check formulas for optimizing");
        const checkResults = this.checkOptimize(blocksUnitF);
        this.setState({ checkResults });
        this.printlnLogs("DONE");
        this.setShowLogs(false);
        return checkResults;
    }

    select(sheetName: string, rangeAddress: string) {
        Excel.run(function (ctx) {
            const range = ctx.workbook.worksheets.getItem(sheetName).getRange(rangeAddress);
            range.select();
            return ctx.sync();
        });
    }

    optimize = () => {
        if (this.cancel) {
            this.cancel();
        }
        var promise = new OfficeExtension.Promise(function (resolve, reject) {
            resolve(null);
        });
        promise
            .then(async () => {
                const { promise, cancel } = runWithCancel(this.doOptimize.bind(this));
                this.cancel = cancel;
                return promise.catch((err) => {
                    if (err.reason === "cancelled") {
                        // tslint:disable-next-line: no-console
                        console.log("canceled last optimize");
                    } else {
                        throw err;
                    }
                });
            })
            .catch((error) => {
                if (error.message === "no formulas") {
                    this.setState({ noFormula: true });
                    this.printlnLogs("DONE");
                    this.setShowLogs(false);
                }
                else if (error.message !== "unprotectSheets") {
                    this.printlnLogs("Error: " + error);
                    if (error instanceof OfficeExtension.Error) {
                        this.printlnLogs("Debug info: " + JSON.stringify(error.debugInfo));
                    }
                }
            })
    }

    _onRenderCell = (item: ICheckResultItem | undefined, index: number | undefined): JSX.Element => {
        if (!isNullOrUndefined(item) && !isNullOrUndefined(index)) {
            let tooltipContent = ruleTooltipMap[item.rule];
            tooltipContent = tooltipContent ? tooltipContent : item.rule;
            return (
                <div className={styles.itemContainer}>
                    <Stack horizontal={true} verticalAlign="center" gap={10}>
                        <span className={styles.itemRange} onClick={this.onClickSelectRange.bind(this, index)}>
                            {item.sheet + "!" + item.range}
                        </span>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <div>
                            <DefaultButton style={{ borderColor: "#EFF0F1"}} onClick={this.onClickOptimize.bind(this, index)}>Optimize</DefaultButton>
                            &nbsp;
                            <DefaultButton style={{ borderColor: "#EFF0F1"}} onClick={this.onClickUndo.bind(this, index)}>Undo</DefaultButton>
                        </div>
                    </Stack>
                    <span
                        onClick={this.onClickSelectRange.bind(this, index)}
                        className={item.optimized ? styles.itemFormulaInactivate : styles.itemFormula}
                    >{item.formerFormulaA1}
                    </span>
                    <span
                        onClick={this.onClickSelectRange.bind(this, index)}
                        className={item.optimized ? styles.itemFormula : styles.itemFormulaInactivate}
                    >{item.afterFormulaA1}
                    </span>
                    <TooltipHost content={tooltipContent}>
                        <Stack horizontal={true} verticalAlign="center" gap={5}>
                            <span className={styles.itemRule}>{item.rule}</span>
                            <Icon iconName="Info" />
                        </Stack>
                    </TooltipHost>
                    <br />
                </div>
            )
        }
        return <></>
    }

    renderResult = (): JSX.Element => {
        const items: ICheckResultItem[] = this.state.checkResults;

        if (items.length > 0) {
            return (
                <div className={styles.result}>
                    <span>Click on the results to select:</span>
                    <br />
                    <br />
                    <List items={items} onRenderCell={this._onRenderCell} />
                </div>
            )
        } else {
            if (this.state.noFormula) {
                return (
                    <div className={styles.result} >
                        <span>There are no single formulas to optimize in this workbook. </span>
                    </div >
                )
            } else {
                return <div className={styles.result} />
            }
        }
    }

    onClickOptimize = async (index: number) => {
        return await Excel.run(async ctx => {
            const checkResults = [...this.state.checkResults];
            const f = checkResults[index].afterFormulaR1C1;
            const rg = checkResults[index].range;
            const co = checkResults[index].co;
            const sh = checkResults[index].sheet;
            const range = ctx.workbook.worksheets.getItem(sh).getRange(rg);
            const data : any[] = [];
            for (let i = 0; i <= co.x1 - co.x0; i++) {
                const columnData : any[] = [];
                for (let j = 0; j <= co.y1 - co.y0; j++) {
                    columnData.push(f);
                }
                data.push(columnData);
            }
            range.select();
            range.formulasR1C1 = data;
            await ctx.sync();
            checkResults[index].optimized = true;
            this.setState({ checkResults }, this.forceUpdate);
        }).catch((error) => {
            OfficeHelpers.UI.notify(error);
            OfficeHelpers.Utilities.log(error);
        });
    }

    onClickUndo = async (index: number) => {
        return await Excel.run(async ctx => {
            const checkResults = [...this.state.checkResults];
            const f = checkResults[index].formerFormulaR1C1;
            const rg = checkResults[index].range;
            const co = checkResults[index].co;
            const sh = checkResults[index].sheet;
            const range = ctx.workbook.worksheets.getItem(sh).getRange(rg);
            const data : any[] = [];
            for (let i = 0; i <= co.x1 - co.x0; i++) {
                const columnData : any[] = [];
                for (let j = 0; j <= co.y1 - co.y0; j++) {
                    columnData.push(f);
                }
                data.push(columnData);
            }
            range.select();
            range.formulasR1C1 = data;
            await ctx.sync();
            checkResults[index].optimized = false;
            this.setState({ checkResults });
        }).catch((error) => {
            OfficeHelpers.UI.notify(error);
            OfficeHelpers.Utilities.log(error);
        });
    }

    onClickSelectRange = async (index: number) => {
        return await Excel.run(async ctx => {
            const rg = this.state.checkResults[index].range;
            const sh = this.state.checkResults[index].sheet;
            const r = ctx.workbook.worksheets.getItem(sh).getRange(rg);
            r.select();
            return await ctx.sync();
        }).catch((error) => {
            OfficeHelpers.UI.notify(error);
            OfficeHelpers.Utilities.log(error);
        })
    }

    render() {
        const Logger = this.getLogger();
        const iconName = this.state.showLogs ? "CaretSolidRight" : "CaretSolidDown";

        return (
            <div className={styles.main}>
                <div className={styles.padding}>
                    <br />
                    <div className={styles.wrapping}>
                        <PrimaryButton
                            style={{ width: '240px' }}
                            // tslint:disable-next-line: jsx-no-lambda
                            onClick={() => this.optimize()}
                        >Optimize
                        </PrimaryButton>
                    </div>
                    <br />
                    <hr className={styles.hr} />
                    {this.renderResult()}
                </div>
                <div className={styles.footer}>
                    <div className={styles.logIcon} onClick={this.toggleShowLogs}>
                        <Icon iconName={iconName} />
                    </div>
                    {this.state.showLogs && <Logger />}
                </div>
            </div >
        )
    }
}

export default Optimizer
