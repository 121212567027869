import React from "react";
import { Text } from 'office-ui-fabric-react/lib/Text';
import styles from './index.module.scss';

interface ILoggerProps {
    logs: string;
}

interface ILoggerState {
    logs: string;
    [props: string]: any;
}

export class LoggerComponent<P, S extends ILoggerState, SS = any> extends React.Component<P, S, SS> {
    protected printlnLogs = (log: string) => {
        this.setState({ logs: this.state.logs + log + "\n" })
    }

    protected printLogs = (log: string) => {
        this.setState({ logs: this.state.logs + log })
    }

    protected clearLogs = () => {
        this.setState({ logs: "" });
        this.forceUpdate();
    }

    protected getLogger = (): React.ComponentType<{}> => {
        const logs = this.state.logs;
        return class extends React.Component<{}> {
            render() {
                return <Logger logs={logs} />;
            }
        };
    }
}

export class Logger extends React.PureComponent<ILoggerProps> {
    private _logDiv = React.createRef<HTMLDivElement>();

    componentDidMount() {
        this.scrollToBottom()
    }

    componentDidUpdate() {
        this.scrollToBottom()
    }

    scrollToBottom = () => {
        if (this._logDiv.current) {
            this._logDiv.current.scrollTop = this._logDiv.current.scrollHeight;
        }
    }

    render() {
        return (
            <div ref={this._logDiv} className={styles.logContainer}>
                <div className={styles.logContent}>
                    <Text className={styles.logText} block={true}>
                        {this.props.logs}
                    </Text>
                </div>
            </div>
        )
    }
}

export default LoggerComponent
