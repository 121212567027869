import axios from 'axios';
import { CrossStorageClient } from 'cross-storage';


const storage = new CrossStorageClient(`${FUNFUN_URL}/htmls/hub.html`, {});

export async function delSignToken() {
  await storage.onConnect();
  storage.del('flapper-news-token');
}

export async function getSignToken() {
  await storage.onConnect();
  const res = storage.get('flapper-news-token');
  if (res) {
    return res;
  } else {
    return null;
  }
}

export async function saveSignToken(token: string) {
  await storage.onConnect();
  storage.set('flapper-news-token', token);
}

export async function getSignTokenLocal() {
  try {
    // const res = await axios.get(`${AUTH_BACKEND_URL}/auth/token?t=${new Date().getTime()}`, {
    //   withCredentials: true,
    // });
    const res = await axios.get(`${AUTH_BACKEND_URL}/10studio/auth/tokenTie?t=${new Date().getTime()}`, {
      withCredentials: true,
    });
    const token = res.data.token;
    await saveSignToken(token);
    return token;
  } catch (err) {
    // tslint:disable-next-line: no-console
    console.log('unable to get user token, please close this page and refresh you login page!');
    return null;
  }
}

export const payloadFromToken = (token: string) => {
  if (token === null || token === undefined) return null;
  else {
    return JSON.parse(atob(token.split('.')[1])); // {JavaScript}: avatar breaks generateJWT of my facebook
  }
};
