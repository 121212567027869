import { AbstractSpreadsheetCommunicator } from "./abstract-spreadsheet-communicator";
export class ExcelWebSpreadsheetCommunicators extends AbstractSpreadsheetCommunicator {
    read_formula(formulaStyle: string) {
        return Excel.run(async (ctx: any) => {
            if (formulaStyle === "A1") {
                // https://docs.microsoft.com/en-us/javascript/api/excel/excel.range?view=excel-js-preview#formulasLocal
                // Represents the formula in A1-style notation, in the user's language and number-formatting locale. 
                const selectedRange = ctx.workbook.getSelectedRange();
                selectedRange.load(['formulasLocal']);
                await ctx.sync();
                let x = selectedRange.formulasLocal[0][0]; 
                // console.log("read_formula: ");
                // console.log(x);
                return x;
            } else {
                // https://docs.microsoft.com/en-us/javascript/api/excel/excel.range?view=excel-js-preview#formulasR1C1
                const selectedRange = ctx.workbook.getSelectedRange();
                selectedRange.load(['formulasR1C1']);
                await ctx.sync();
                let x = selectedRange.formulasR1C1[0][0];
                return x;
            }
 
        });
    }

    write_formula(value: string, formulaStyle: string) {
        return Excel.run(async (ctx: any) => {
            var selectedRange = ctx.workbook.getSelectedRange();
            if (formulaStyle === "A1")
                selectedRange.formulasLocal = value
            else
                selectedRange.formulasR1C1 = value
            await ctx.sync();
        })
    }

    // https://docs.microsoft.com/en-us/javascript/api/excel/excel.numberformatinfo?view=excel-js-preview#numberDecimalSeparator
    read_numberDecimalSeparator () {
        return Excel.run(async (ctx: any) => {
            ctx.application.cultureInfo.numberFormat.load("numberDecimalSeparator");
            await ctx.sync();
            return ctx.application.cultureInfo.numberFormat.numberDecimalSeparator;
        });
    }

    // https://docs.microsoft.com/en-us/javascript/api/office/office.context?view=excel-js-preview#displayLanguage
    read_displayLanguage() {
        return Office.context.displayLanguage
    }

    // https://docs.microsoft.com/en-us/javascript/api/office/office.context?view=excel-js-preview#contentLanguage
    read_contentLanguage() {
        return Office.context.contentLanguage
    }
 
    addOnSelectionChangeHandler(handler: any) {
        Office.context.document.addHandlerAsync(
            Office.EventType.DocumentSelectionChanged,
            handler,
        );
    }
}
