import React from 'react';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { Form, Field, ErrorMessage, FormikProps } from 'formik';
import './index.css';
import { resetPassword } from '../../services/auth';
import ResetValidationForm from '../ResetFormValidation';

interface ResetFormValues {
  email: string;
}


class Reset extends React.Component<FormikProps<ResetFormValues>, {}> {
  constructor(props) {
    super(props);
    this.props.setStatus({ sent: false })
  }
  request() {

    const { status, ...formik } = this.props;
    return <div className="container">
      <div className="reset-form login-form">
        <h3 className="border-label"> Reset Password </h3>

        <div className="row">
          <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12">
            <div className="login-form-wrapper sign-form-wrapper">
              <div className="formContainer">
                <h5 className="description">
                  To reset your password enter your email, and click reset.
                </h5>
                <Form className="form">
                  <Field className="input" type="text" name="email" placeholder="you@example.com" />

                  <button className="button" type="submit">
                    Reset Password
                  </button>
                </Form>
              </div>
              <div className="messages-wrapper">
                {formik.errors.email && <div className="alert alert-danger ">
                  <ErrorMessage name="email" />
                </div>
                }
                {status && status.msg &&
                  <div className="alert alert-success msg-status">{status.msg}</div>
                }
                {status && status.error &&
                  <div className="alert alert-danger error-status">{status.error}</div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
  verification(email) {
    return <ResetValidationForm email={email} />
  }
  render() {
    return this.props.status?.sent ? this.verification(this.props.values.email) : this.request();
  }

}


const RegisterValidation = yup.object().shape({
  email: yup
    .string()
    .email()
    .required()
});

const ResetForm = withFormik<{}, ResetFormValues>({
  // Handles our submission
  handleSubmit: async (values, { setSubmitting, setStatus }) => {
    // This is where you could send the submitted values to the backend
    try {
      setSubmitting(true);
      const res = await resetPassword(values.email);
      setStatus({ sent: true });
    } catch (e) {
      // tslint:disable-next-line: no-console
      if (e.response.data.message && e.response.status == 200) {
        setStatus({ msg: e.response.data.message });
      } else if (e.response.data.message){
        setStatus({ error: e.response.data.message });
      } else {
        setStatus(JSON.stringify(e.response.data));
      }
      setSubmitting(false);
    }
  },
  validationSchema: RegisterValidation,
})(Reset);

export default ResetForm;
