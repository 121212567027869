import MonacoEditor from 'react-monaco-editor';
import React from 'react';

class ResizableMonacoEditor extends MonacoEditor {
  _handleEditorDidMount = (editor: any, monaco: any) => {
    this.editor = editor;
    if (this.props.editorDidMount) {
      this.props.editorDidMount(editor, monaco);
    }
  };

  render() {
    return <MonacoEditor {...this.props} editorDidMount={this._handleEditorDidMount} />;
  }

  handleResize = () => {
    if (this.editor) {
      this.editor.layout();
    }
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }
}

export default ResizableMonacoEditor;
