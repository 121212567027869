import React from 'react';
import { connect } from 'dva';
import { Dispatch } from 'redux';
import { push } from 'react-router-redux';

import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { Dialog, getId, DialogType, Stack, Text } from 'office-ui-fabric-react';

import { State } from '../../store/reducer';
import { getNextPath } from '../../selectors/header';
import { getSubscription, getTrial } from '../../selectors/subscription';
import { Subscription } from '../../services/subscription';
import { getIsSubscriptionActive, getPlanProductName } from '../Plans/utils';
import { selectAppName } from '../../selectors/app';
import { AppName } from '../../models/app';
import { getAppSubscribePage } from '../../services/app-utils';
import { withRouter , RouteComponentProps } from 'dva/router';
export interface INoAuthLoginRedirectProps {
  dispatch: Dispatch<any>;
  onDissmiss?: Function;
  next?: string;
  subscription?: Subscription;
  trial: Subscription;
  feature: string;
  app: AppName;
}


export interface INoAuthLoginRedirectState {
  hideDialog: boolean;
}

class NoAuthLoginRedirect extends React.Component<
  RouteComponentProps<any> & INoAuthLoginRedirectProps ,
  INoAuthLoginRedirectState
  > {
  state: INoAuthLoginRedirectState = {
    hideDialog: false,
  };
  private _labelId: string = getId('dialogLabel');
  private _subTextId: string = getId('subTextLabel');

  private _closeDialog = (): void => {
    this.setState({ hideDialog: true });
    if (this.props.onDissmiss) {
      this.props.onDissmiss();
    } else {
      if (this.props.next) {
        this.props.history.push(`/${getAppSubscribePage(this.props.app)}?next=${this.props.next}`);
      } else {
        this.props.history.push(`/${getAppSubscribePage(this.props.app)}?next=welcome`);
      }
    }
  };

  render() {
    const { hideDialog } = this.state;
    const { subscription, trial, feature } = this.props;
    let subText: string;
    let showToExtendTrial = false;
    if (!subscription) {
      if (!trial || !getIsSubscriptionActive(trial)) {
      }
      subText = 'Your trial period has expired.';
      showToExtendTrial = true;
    } else {
      if (subscription.status === 'canceled') {
        subText = 'Your last subscription has been canceled.';
      } else {
        const subItems: any[] = subscription.items.data;
        const planNames = subItems.map(x => getPlanProductName(x.plan)).join(',');
        subText = `You want to use the feature ${feature}, which is not included in your current subscription: ${planNames}.`;
      }
    }
    return (
      <Dialog
        hidden={hideDialog}
        onDismiss={() => this._closeDialog()}
        dialogContentProps={{
          type: DialogType.normal,
          // title: 'No Authentication',
          closeButtonAriaLabel: 'Close',
          subText,
          styles: { subText: { fontSize: 16 } },
        }}
        modalProps={{
          titleAriaId: this._labelId,
          subtitleAriaId: this._subTextId,
          isBlocking: true,
          styles: { main: { maxWidth: 280 } },
        }}
      >
        <Stack horizontalAlign="center" tokens={{ childrenGap: 10 }}>
          <PrimaryButton onClick={() => this._closeDialog} text="Go Subscribe" />
          {showToExtendTrial && (
            <a>
              <Text variant="tiny">Extend trial by other ways</Text>
            </a>
          )}
        </Stack>
      </Dialog>
    );
  }
}

export default connect((state: State) => ({
  next: getNextPath(state),
  subscription: getSubscription(state),
  trial: getTrial(state),
  app: selectAppName(state)
}))(withRouter(NoAuthLoginRedirect));
