import React, { Component } from 'react';

import {
  injectStripe,
  ReactStripeElements,
  CardCVCElement,
  CardExpiryElement,
  CardNumberElement,
} from 'react-stripe-elements';
import { Stack, PrimaryButton, Spinner, Text } from 'office-ui-fabric-react';

const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#c23d4b',
      },
    },
  };
};

interface IState {
  isHandling: boolean;
  errorMessage?: string;
}

interface StripeProps extends ReactStripeElements.StripeProps {
  handleCardAction(clientSecret: string, options?: any): Promise<any>;
}

interface InjectedStripeProps extends ReactStripeElements.InjectedStripeProps {
  confirmMessage?: string;
  handleToken(token: stripe.Token): void | Promise<void>;
  stripe: StripeProps | null;
}

class StripeCheckoutForm extends Component<InjectedStripeProps, IState> {
  readonly state: IState = {
    isHandling: false,
    errorMessage: undefined,
  };

  handleChange = ({ error }: { error?: stripe.Error | undefined }) => {
    if (error) {
      this.setState({ errorMessage: error.message });
    }
  };

  handleSubmit = async (ev: any) => {
    ev.preventDefault();
    this.setState({ isHandling: true }, async () => {
      // Use Elements to get a reference to the Card Element mounted somewhere
      // in your <Elements> tree. Elements will know how to find your Card Element
      // becase only one is allowed.
      // See our getElement documentation for more:
      // https://stripe.com/docs/stripe-js/reference#elements-get-element
      // const cardElement = this.props.elements.getElement('card');

      // From here we cal call createPaymentMethod to create a PaymentMethod
      // See our createPaymentMethod documentation for more:
      // https://stripe.com/docs/stripe-js/reference#stripe-create-payment-method
      if (!this.props.stripe) {
        console.log('nostripe');
        return;
      }
      try {
        const { token, error } = await this.props.stripe.createToken();
        if (error) {
          this.setState({ errorMessage: error.message });
        } else {
          if (token) {
            await this.props.handleToken(token);
          } else {
            this.setState({ errorMessage: 'no token created' });
          }
        }
      } catch (e) {
        this.setState({ errorMessage: e.message });
      }
      this.setState({ isHandling: false });
    });
  };

  render() {
    const { isHandling } = this.state;
    return (
      <form style={{ width: 280 }} onSubmit={this.handleSubmit.bind(this)}>
        <div className="split-form">
          <label style={{ minHeight: 45, minWidth: 200 }}>
            <Text variant="xLarge">Card number</Text>
            <CardNumberElement {...createOptions()} onChange={this.handleChange} />
          </label>
        </div>
        <Stack className="split-form" horizontal>
          <label style={{ minHeight: 45, minWidth: 140 }}>
            <Text variant="xLarge">Expiration date</Text>
            <CardExpiryElement {...createOptions()} onChange={this.handleChange} />
          </label>
          <label style={{ marginLeft: 40, minHeight: 45, minWidth: 80 }}>
            <Text variant="xLarge">CVC</Text>
            <CardCVCElement {...createOptions()} onChange={this.handleChange} />
          </label>
        </Stack>
        <div className="error" role="alert">
          {this.state.errorMessage}
        </div>
        <Stack styles={{ root: { marginTop: 10 } }}>
          {isHandling ? (
            <PrimaryButton disabled>
              <Spinner></Spinner>
            </PrimaryButton>
          ) : (
              <PrimaryButton type="submit" style={{ width: '100%' }}>
                {this.props.confirmMessage || 'Pay'}
              </PrimaryButton>
            )}
        </Stack>
      </form>
    );
  }
}

export default injectStripe(StripeCheckoutForm);
